import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDoctorAppointments } from '../slices/doctorSlice';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

// Styled Components

const ChatListContainer = styled.div`
  width: 100%;
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    border-right: none;
    border-bottom: 1px solid #ddd;
  }
`;

const LogoBox = styled.div`
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #ddd;

  .Medbridge-access-logo {
    width: 100px;
    height: auto;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      width: 80px;
    }
  }

  .logo h1 {
    font-size: 1.5rem;
    margin: 0;

    .title-we {
      color: #007bff;
    }

    .title-2 {
      display: flex;
      justify-content: center;

      span {
        margin-left: 5px;
      }
    }

    @media (max-width: 768px) {
      font-size: 1.2rem;

      .title-2 {
        flex-direction: column;

        span {
          margin: 0;
        }
      }
    }
  }
`;

const UserItem = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #eee;
  }

  @media (max-width: 768px) {
    padding: 10px 15px;
  }
`;

// Remove or repurpose the UserPhoto component
// const UserPhoto = styled.img`
//   width: 60px;
//   height: 60px;
//   border-radius: 50%;
//   margin-right: 15px;
//   object-fit: cover;
//   flex-shrink: 0;

//   @media (max-width: 768px) {
//     width: 50px;
//     height: 50px;
//     margin-right: 10px;
//   }
// `;

// New UserInitial Component
const UserInitial = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${(props) => props.bgColor || '#007bff'};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 15px;
  flex-shrink: 0;
  text-transform: uppercase;

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    font-size: 1.2rem;
    margin-right: 10px;
  }
`;

const UserInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const UserName = styled.div`
  font-weight: 500;
  text-align: left;
  margin-bottom: 5px;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const LastMessage = styled.div`
  font-size: 0.85rem;
  color: #666;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

// Commented out LastMessageTime if not needed
// const LastMessageTime = styled.div`
//   font-size: 0.75rem;
//   color: #666;
//   white-space: nowrap;
//   margin-left: 10px;
//   flex-shrink: 0;

//   @media (max-width: 768px) {
//     font-size: 0.65rem;
//     margin-left: 5px;
//   }
// `;

const NoAppointments = styled.div`
  padding: 20px;
  text-align: center;
  color: #666;
  font-size: 1rem;

  @media (max-width: 768px) {
    padding: 15px;
    font-size: 0.9rem;
  }
`;

// Utility function to generate a color based on the name
const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 60%, 60%)`;
  return color;
};

// DoctorChatList Component

const DoctorChatList = ({ setSelectedPatient }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { appointments, status, error, doctorData } = useSelector((state) => state.doctor);

  useEffect(() => {
    if (doctorData && doctorData.access_token) {
      dispatch(fetchDoctorAppointments());
    } else {
      console.log('Doctor not logged in, redirecting to login page.');
      // Optionally, redirect to login page or show a message
      // navigate('/login'); // Uncomment if you have a login route
    }
  }, [dispatch, doctorData, navigate]);

  if (status === 'loading') {
    return (
      <ChatListContainer>
        <NoAppointments>Loading...</NoAppointments>
      </ChatListContainer>
    );
  }

  if (status === 'failed') {
    return (
      <ChatListContainer>
        <NoAppointments>
          {error ? error.toString() : 'Failed to load appointments'}
        </NoAppointments>
      </ChatListContainer>
    );
  }

  return (
    <ChatListContainer>
      <h2 style={{ textAlign: 'left', padding: '20px', margin: 0 }}>Your Patients</h2>
      {Array.isArray(appointments) && appointments.length > 0 ? (
        appointments.map((appointment) => {
          const firstName = appointment.patient?.user?.first_name || 'U';
          const initial = firstName.charAt(0).toUpperCase();
          const bgColor = stringToColor(firstName);

          return (
            <UserItem
              key={appointment.id} // Assuming each appointment has a unique ID
              onClick={() => {
                setSelectedPatient(appointment.patient);
                navigate(`/doctors/chat/${appointment.patient.id}`);
              }}
            >
              <UserInitial bgColor={bgColor}>
                {initial}
              </UserInitial>
              <UserInfo>
                <UserName>
                  {appointment.patient?.user?.first_name || 'Unknown'}{' '}
                  {appointment.patient?.user?.last_name || ''}
                </UserName>
                <LastMessage>
                  {appointment.problem_description || 'No description available'}
                </LastMessage>
              </UserInfo>
              {/* Uncomment if you want to display the message time
              <LastMessageTime>
                {appointment.appointment_time
                  ? new Date(appointment.appointment_time).toLocaleString()
                  : 'Time not set'}
              </LastMessageTime>
              */}
            </UserItem>
          );
        })
      ) : (
        <NoAppointments>No appointments available</NoAppointments>
      )}
    </ChatListContainer>
  );
};

export default DoctorChatList;
