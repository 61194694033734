// src/Components/AppointmentDashboard.js

import React, { useState, useEffect, forwardRef } from "react";
import { ChevronDown, Clock, Edit, Search, Calendar } from "lucide-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../CSS/AdminDashboard.css";
import "../ScopedBootstrap.scss";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchAppointments,
  fetchDoctors,
  updateAppointment,
} from "../slices/adminSlice";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "../Components/AdminNavbar";
import {
  Form,
  Button,
  InputGroup,
  FormControl,
  Row,
  Col,
  ButtonGroup,
  Container,
  Card,
  Table,
} from "react-bootstrap";

// Reusable DoctorImage component
const DoctorImage = ({ profilePhoto, firstName, lastName }) => {
  const defaultImage = "/static/icons/medical-team.png";

  return (
    <img
      src={profilePhoto || defaultImage}
      alt={`Dr. ${firstName} ${lastName}`}
      className="doctor-profile-photo me-2"
      style={{
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        objectFit: "cover",
      }}
    />
  );
};

// Custom Date Input Component with forwardRef
const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
  <div
    className="d-flex align-items-center border rounded p-2 appointment-dashboard-editable-date-time"
    onClick={onClick}
    ref={ref}
    title="Click to edit date and time"
    tabIndex={0}
    onKeyPress={(e) => {
      if (e.key === "Enter") {
        onClick();
      }
    }}
    style={{ cursor: "pointer" }}
  >
    <Clock size={16} className="me-2" /> {value}
  </div>
));

const AppointmentDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Detect screen size
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Sidebar open/close state
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // Track expanded and editing appointments
  const [expandedAppointment, setExpandedAppointment] = useState(null);
  const [editingAppointment, setEditingAppointment] = useState(null);
  const [editedFields, setEditedFields] = useState({});

  // Filters
  const [statusFilter, setStatusFilter] = useState("All");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");

  // Get user's timezone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const appointments = useSelector((state) => state.admin.appointments);
  const appointmentStatus = useSelector(
    (state) => state.admin.appointmentStatus
  );
  const appointmentError = useSelector((state) => state.admin.appointmentError);
  const doctors = useSelector((state) => state.admin.doctors);
  const doctorStatus = useSelector((state) => state.admin.doctorStatus);
  const adminToken = useSelector((state) => state.admin.adminToken);

  useEffect(() => {
    if (!adminToken) {
      navigate("/admin/login");
    }
  }, [adminToken, navigate]);

  useEffect(() => {
    if (appointmentStatus === "idle") {
      dispatch(fetchAppointments());
    }
    if (doctorStatus === "idle") {
      dispatch(fetchDoctors());
    }
  }, [appointmentStatus, doctorStatus, dispatch]);

  const toggleExpand = (id) => {
    setExpandedAppointment(expandedAppointment === id ? null : id);
  };

  const handleEdit = (appointment) => {
    setEditingAppointment(appointment.id);
    setEditedFields({
      status: appointment.status,
      doctor: appointment.doctor ? appointment.doctor.id.toString() : "",
      appointment_time: appointment.appointment_time
        ? new Date(appointment.appointment_time)
        : null,
    });
  };

  const handleSave = (appointment) => {
    const data = {};

    if (editedFields.status !== appointment.status) {
      data.status = editedFields.status;
    }
    if (
      editedFields.doctor !== undefined &&
      editedFields.doctor !==
        (appointment.doctor ? appointment.doctor.id.toString() : "")
    ) {
      data.doctor =
        editedFields.doctor === "" ? null : parseInt(editedFields.doctor, 10);
    }

    if (editedFields.appointment_time !== appointment.appointment_time) {
      // Use toISOString() directly without slicing or appending timezone
      data.appointment_time = editedFields.appointment_time.toISOString(); // e.g., '2025-01-26T03:00:00.000Z'
    }

    // Optionally include user's timezone if backend needs it
    // data.time_zone = userTimeZone;

    if (Object.keys(data).length > 0) {
      dispatch(updateAppointment({ id: appointment.id, data }));
    }

    setEditingAppointment(null);
    setEditedFields({});
  };

  const handleCancel = () => {
    setEditingAppointment(null);
    setEditedFields({});
  };

  // Updated to exclude seconds
  const formatDateTime = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // Use 12-hour format; set to false for 24-hour
    });
  };
  

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatTime = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // Use 12-hour format; set to false for 24-hour
    });
  };

  // Filtering logic with null checks
  const filteredAppointments = appointments.filter((appointment) => {
    // Filter by status
    if (statusFilter !== "All" && appointment.status !== statusFilter) {
      return false;
    }
    // Filter by date range
    const appointmentDate = new Date(appointment.appointment_time);
    if (fromDate && appointmentDate < fromDate) {
      return false;
    }
    if (toDate && appointmentDate > toDate) {
      return false;
    }
    // Filter by search keyword
    if (searchKeyword) {
      const keyword = searchKeyword.toLowerCase();

      const patientFirstName = appointment.patient?.user?.first_name || "";
      const patientLastName = appointment.patient?.user?.last_name || "";
      const patientName = `${patientFirstName} ${patientLastName}`.toLowerCase();

      const doctorFirstName = appointment.doctor?.user?.first_name || "";
      const doctorLastName = appointment.doctor?.user?.last_name || "";
      const doctorName = `${doctorFirstName} ${doctorLastName}`.toLowerCase();

      const problem = (appointment.problem_heading || "").toLowerCase();

      return (
        patientName.includes(keyword) ||
        doctorName.includes(keyword) ||
        problem.includes(keyword)
      );
    }
    return true;
  });

  // RENDER: Card layout for mobile
  const renderMobileCards = () => {
    return filteredAppointments.length > 0 ? (
      filteredAppointments.map((appointment) => (
        <Card key={appointment.id} className="mb-3">
          <Card.Body>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h6 className="mb-0">ID: {appointment.id}</h6>
              {editingAppointment === appointment.id ? (
                <div className="d-flex">
                  <Button
                    variant="success"
                    size="sm"
                    className="me-2"
                    onClick={() => handleSave(appointment)}
                    style={{ fontSize: "0.75rem" }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={handleCancel}
                    style={{ fontSize: "0.75rem" }}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={() => handleEdit(appointment)}
                  style={{ padding: "4px 8px" }}
                >
                  <Edit size={16} />
                </Button>
              )}
            </div>

            {/* Patient */}
            <p style={{ fontSize: "0.85rem" }}>
              <strong>Patient:</strong>{" "}
              {appointment.patient?.user?.first_name}{" "}
              {appointment.patient?.user?.last_name}
            </p>

            {/* Doctor */}
            <div className="mb-2" style={{ fontSize: "0.85rem" }}>
              <strong>Doctor: </strong>
              {editingAppointment === appointment.id ? (
                <Form.Select
                  value={editedFields.doctor}
                  onChange={(e) =>
                    setEditedFields({
                      ...editedFields,
                      doctor: e.target.value,
                    })
                  }
                  style={{ fontSize: "0.85rem" }}
                >
                  <option value="">Select Doctor</option>
                  {doctors.map((doctor) => (
                    <option key={doctor.id} value={doctor.id}>
                      {doctor.user?.first_name} {doctor.user?.last_name} -{" "}
                      {doctor.specialization}
                    </option>
                  ))}
                </Form.Select>
              ) : appointment.doctor ? (
                <>
                  <DoctorImage
                    profilePhoto={appointment.doctor.profile_photo}
                    firstName={appointment.doctor.user?.first_name}
                    lastName={appointment.doctor.user?.last_name}
                  />
                  {`${appointment.doctor.user?.first_name || ""} ${
                    appointment.doctor.user?.last_name || ""
                  }`}
                </>
              ) : (
                "Not Assigned"
              )}
            </div>

            {/* Appointment Date/Time */}
            <p style={{ fontSize: "0.85rem" }}>
              <strong>Date &amp; Time: </strong>
              {editingAppointment === appointment.id ? (
                <DatePicker
                  selected={
                    editedFields.appointment_time
                      ? editedFields.appointment_time
                      : null
                  }
                  onChange={(date) =>
                    setEditedFields({
                      ...editedFields,
                      appointment_time: date,
                    })
                  }
                  showTimeSelect
                  dateFormat="MMMM d, yyyy h:mm aa" // Excludes seconds
                  customInput={<CustomDateInput />}
                  className="form-control"
                />
              ) : appointment.appointment_time ? (
                formatDateTime(appointment.appointment_time)
              ) : (
                "Not Scheduled"
              )}
            </p>

            {/* Status */}
            <p style={{ fontSize: "0.85rem" }}>
              <strong>Status:</strong>{" "}
              {editingAppointment === appointment.id ? (
                <Form.Select
                  value={editedFields.status || appointment.status}
                  onChange={(e) =>
                    setEditedFields({
                      ...editedFields,
                      status: e.target.value,
                    })
                  }
                  style={{ fontSize: "0.85rem", display: "inline-block" }}
                >
                  <option value="Pending">Pending</option>
                  <option value="Confirmed">Confirmed</option>
                  <option value="Completed">Completed</option>
                  <option value="Cancelled">Cancelled</option>
                </Form.Select>
              ) : (
                appointment.status
              )}
            </p>

            {/* Expand for more details */}
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => toggleExpand(appointment.id)}
            >
              Details <ChevronDown size={16} />
            </Button>

            {expandedAppointment === appointment.id && (
              <div className="mt-3" style={{ fontSize: "0.85rem" }}>
                <strong>Problem Heading:</strong>{" "}
                {appointment.problem_heading || "N/A"}
                <br />
                <strong>Problem Description:</strong>{" "}
                {appointment.problem_description || "N/A"}
                <br />
                {/* You could add patient/doctor details, etc. */}
              </div>
            )}
          </Card.Body>
        </Card>
      ))
    ) : (
      <Card className="mb-3">
        <Card.Body>
          <p style={{ fontSize: "0.85rem" }}>
            No appointments found matching the criteria.
          </p>
        </Card.Body>
      </Card>
    );
  };

  // RENDER: Table layout for desktop
  const renderDesktopTable = () => (
    <Card className="shadow-sm">
      <Card.Body className="p-0">
        {/* 
          .table-responsive is optional here just in case the 
          table gets too wide even on desktop
        */}
        <div className="table-responsive">
          <Table
            responsive
            className="mb-0 table"
            style={{
              fontSize: "0.85rem",
              borderCollapse: "separate",
              borderSpacing: "0 8px",
            }}
          >
            <thead
              style={{
                backgroundColor: "#f9f9f9",
                borderBottom: "0.5px solid rgba(222,226,230,0.7)",
              }}
            >
              <tr>
                <th style={{ width: "5%" }}>ID</th>
                <th style={{ width: "15%" }}>Patient</th>
                <th style={{ width: "15%" }}>Doctor</th>
                <th style={{ width: "20%" }}>Date &amp; Time</th>
                <th style={{ width: "20%" }}>Assign Doctor</th>
                <th style={{ width: "10%" }}>Status</th>
                <th style={{ width: "7%" }}>Actions</th>
                <th style={{ width: "8%" }}>Details</th>
              </tr>
            </thead>
            <tbody style={{ borderTop: "none" }}>
              {filteredAppointments.map((appointment) => (
                <React.Fragment key={appointment.id}>
                  <tr
                    className="align-middle"
                    style={{
                      border: "0.5px solid rgba(222,226,230,0.7)",
                      backgroundColor: "#fff",
                      borderRadius: "4px",
                    }}
                  >
                    <td style={{ padding: "12px 8px" }}>{appointment.id}</td>
                    <td style={{ padding: "12px 8px" }}>
                      {appointment.patient?.user?.first_name}{" "}
                      {appointment.patient?.user?.last_name}
                    </td>
                    <td style={{ padding: "12px 8px" }}>
                      <DoctorImage
                        profilePhoto={appointment.doctor?.profile_photo}
                        firstName={appointment.doctor?.user?.first_name}
                        lastName={appointment.doctor?.user?.last_name}
                      />
                      {appointment.doctor
                        ? `${appointment.doctor.user?.first_name || ""} ${
                            appointment.doctor.user?.last_name || ""
                          }`
                        : "Not Assigned"}
                    </td>
                    <td style={{ padding: "12px 8px" }}>
                      {editingAppointment === appointment.id ? (
                        <DatePicker
                          selected={
                            editedFields.appointment_time
                              ? editedFields.appointment_time
                              : null
                          }
                          onChange={(date) =>
                            setEditedFields({
                              ...editedFields,
                              appointment_time: date,
                            })
                          }
                          showTimeSelect
                          dateFormat="MMMM d, yyyy h:mm aa" // Excludes seconds
                          customInput={<CustomDateInput />}
                          className="form-control"
                        />
                      ) : appointment.appointment_time ? (
                        formatDateTime(appointment.appointment_time)
                      ) : (
                        "Not Scheduled"
                      )}
                    </td>
                    <td style={{ padding: "12px 8px" }}>
                      {editingAppointment === appointment.id ? (
                        <Form.Select
                          value={editedFields.doctor}
                          onChange={(e) =>
                            setEditedFields({
                              ...editedFields,
                              doctor: e.target.value,
                            })
                          }
                          style={{ fontSize: "0.85rem" }}
                        >
                          <option value="">Select Doctor</option>
                          {doctors.map((doctor) => (
                            <option key={doctor.id} value={doctor.id}>
                              {doctor.user?.first_name || ""}{" "}
                              {doctor.user?.last_name || ""} -{" "}
                              {doctor.specialization}
                            </option>
                          ))}
                        </Form.Select>
                      ) : (
                        <div className="d-flex align-items-center">
                          <DoctorImage
                            profilePhoto={appointment.doctor?.profile_photo}
                            firstName={appointment.doctor?.user?.first_name}
                            lastName={appointment.doctor?.user?.last_name}
                          />
                          {appointment.doctor
                            ? `${appointment.doctor.user?.first_name || ""} ${
                                appointment.doctor.user?.last_name || ""
                              }`
                            : "Not Assigned"}
                        </div>
                      )}
                    </td>
                    <td style={{ padding: "12px 8px" }}>
                      {editingAppointment === appointment.id ? (
                        <Form.Select
                          value={editedFields.status || appointment.status}
                          onChange={(e) =>
                            setEditedFields({
                              ...editedFields,
                              status: e.target.value,
                            })
                          }
                          style={{ fontSize: "0.85rem" }}
                        >
                          <option value="Pending">Pending</option>
                          <option value="Confirmed">Confirmed</option>
                          <option value="Completed">Completed</option>
                          <option value="Cancelled">Cancelled</option>
                        </Form.Select>
                      ) : (
                        <span>{appointment.status}</span>
                      )}
                    </td>
                    <td style={{ padding: "12px 8px" }}>
                      {editingAppointment === appointment.id ? (
                        <div className="d-flex">
                          <Button
                            variant="success"
                            size="sm"
                            className="me-2"
                            onClick={() => handleSave(appointment)}
                            style={{ fontSize: "0.75rem" }}
                          >
                            Save
                          </Button>
                          <Button
                            variant="secondary"
                            size="sm"
                            onClick={handleCancel}
                            style={{ fontSize: "0.75rem" }}
                          >
                            Cancel
                          </Button>
                        </div>
                      ) : (
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => handleEdit(appointment)}
                          style={{ padding: "4px 8px" }}
                        >
                          <Edit size={16} />
                        </Button>
                      )}
                    </td>
                    <td style={{ padding: "12px 8px" }}>
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        className={`appointment-dashboard-action-button ${
                          expandedAppointment === appointment.id ? "expanded" : ""
                        }`}
                        onClick={() => toggleExpand(appointment.id)}
                        aria-label="Toggle Details"
                        style={{ padding: "4px 8px" }}
                      >
                        <ChevronDown size={18} />
                      </Button>
                    </td>
                  </tr>
                  {expandedAppointment === appointment.id && (
                    <tr
                      className="bg-light"
                      style={{ textAlign: "left" }}
                    >
                      <td
                        colSpan={8}
                        style={{
                          borderBottom: "1px solid rgba(222,226,230,0.7)",
                          padding: "12px 8px",
                          backgroundColor: "#f8f9fa",
                        }}
                      >
                        <Container fluid className="py-3">
                          <Row>
                            <Col md={4} xs={12}>
                              <h5 style={{ fontSize: "1rem" }}>
                                Patient Information
                              </h5>
                              <p style={{ fontSize: "0.85rem" }}>
                                <strong>Name:</strong>{" "}
                                {appointment.patient?.user?.first_name}{" "}
                                {appointment.patient?.user?.last_name}
                              </p>
                              <p style={{ fontSize: "0.85rem" }}>
                                <strong>Email:</strong>{" "}
                                {appointment.patient?.user?.email}
                              </p>
                              <p style={{ fontSize: "0.85rem" }}>
                                <strong>Phone:</strong>{" "}
                                {appointment.patient?.phone}
                              </p>
                            </Col>
                            <Col md={4} xs={12}>
                              <h5 style={{ fontSize: "1rem" }}>
                                Doctor Information
                              </h5>
                              {appointment.doctor ? (
                                <>
                                  <p style={{ fontSize: "0.85rem" }}>
                                    <strong>Name:</strong>{" "}
                                    {
                                      appointment.doctor?.user
                                        ?.first_name
                                    }{" "}
                                    {
                                      appointment.doctor?.user
                                        ?.last_name
                                    }
                                  </p>
                                  <p style={{ fontSize: "0.85rem" }}>
                                    <strong>Specialization:</strong>{" "}
                                    {appointment.doctor?.specialization}
                                  </p>
                                </>
                              ) : (
                                <p style={{ fontSize: "0.85rem" }}>
                                  Doctor not assigned
                                </p>
                              )}
                            </Col>
                            <Col md={4} xs={12}>
                              <h5 style={{ fontSize: "1rem" }}>
                                Appointment Details
                              </h5>
                              <p style={{ fontSize: "0.85rem" }}>
                                <strong>Problem Heading:</strong>{" "}
                                {appointment.problem_heading || "N/A"}
                              </p>
                              <p style={{ fontSize: "0.85rem" }}>
                                <strong>Problem Description:</strong>{" "}
                                {appointment.problem_description || "N/A"}
                              </p>
                              <p style={{ fontSize: "0.85rem" }}>
                                <strong>Date:</strong>{" "}
                                {appointment.appointment_time
                                  ? formatDate(appointment.appointment_time)
                                  : "Not Scheduled"}
                              </p>
                              <p style={{ fontSize: "0.85rem" }}>
                                <strong>Time:</strong>{" "}
                                {appointment.appointment_time
                                  ? formatTime(appointment.appointment_time)
                                  : "Not Scheduled"}
                              </p>
                              <p style={{ fontSize: "0.85rem" }}>
                                <strong>Status:</strong>{" "}
                                {appointment.status}
                              </p>
                            </Col>
                          </Row>
                        </Container>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
              {filteredAppointments.length === 0 && (
                <tr>
                  <td
                    colSpan={8}
                    className="text-center p-3"
                    style={{ fontSize: "0.85rem" }}
                  >
                    No appointments found matching the criteria.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );

  return (
    <div className="bootstrap-scope">
      <div className="admin-dashboard-container">
        <AdminNavbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <div className="admin-dashboard-content container-fluid py-4">
          <div className="d-flex justify-content-between align-items-center mb-5">
            {/* Title */}
            <div style={{ textAlign: "left" }}>
              <h5
                className="mb-0"
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "600",
                }}
              >
                Appointments
              </h5>
              <p
                className="mb-0 text-muted total-count"
                style={{ fontSize: "0.8rem" }}
              >
                {appointments.length} appointments found
              </p>
            </div>
            {/* Search */}
            <InputGroup
              className="me-3"
              style={{
                border: "1px solid #ced4da",
                borderRadius: "4px",
                width: "250px",
              }}
            >
              <FormControl
                placeholder="Search..."
                aria-label="Search appointments"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                style={{ border: "none", fontSize: "0.85rem" }}
              />
              <Button
                variant="outline-secondary"
                className="bg-transparent border-0"
              >
                <Search size={16} />
              </Button>
            </InputGroup>
          </div>

          {/* Filter row */}
          <Row className="mb-2 align-items-center filters-row">
            <Col md={6} className="d-flex align-items-center">
              <ButtonGroup aria-label="Status Filter">
                {["All", "Pending", "Confirmed", "Cancelled"].map((status) => (
                  <Button
                    key={status}
                    variant="link"
                    className={`status-filter-button px-3 py-2 ${
                      statusFilter === status ? "active-status" : ""
                    }`}
                    style={{
                      textDecoration:
                        statusFilter === status ? "underline" : "none",
                      color: statusFilter === status ? "#000" : "#949393",
                      fontWeight: statusFilter === status ? "bold" : "normal",
                      fontSize: "0.85rem",
                    }}
                    onClick={() => setStatusFilter(status)}
                  >
                    {status}
                  </Button>
                ))}
              </ButtonGroup>
            </Col>

            <Col md={6} className="d-flex justify-content-end align-items-center">
              <Form className="d-flex" style={{ gap: "10px" }}>
                <div
                  className="d-flex align-items-center border rounded px-2"
                  style={{
                    borderColor: "#ced4da",
                    backgroundColor: "#f9f9f9",
                    width: "150px",
                  }}
                >
                  <Calendar size={16} className="me-2 text-muted" />
                  <DatePicker
                    selected={fromDate}
                    onChange={(date) => setFromDate(date)}
                    isClearable
                    placeholderText="From Date"
                    className="form-control border-0"
                    style={{ fontSize: "0.85rem" }}
                  />
                </div>
                <div
                  className="d-flex align-items-center border rounded px-2"
                  style={{
                    borderColor: "#ced4da",
                    backgroundColor: "#f9f9f9",
                    width: "150px",
                  }}
                >
                  <Calendar size={16} className="me-2 text-muted" />
                  <DatePicker
                    selected={toDate}
                    onChange={(date) => setToDate(date)}
                    isClearable
                    placeholderText="To Date"
                    className="form-control border-0"
                    style={{ fontSize: "0.85rem" }}
                  />
                </div>
              </Form>
            </Col>
          </Row>

          {appointmentStatus === "loading" && <p>Loading appointments...</p>}
          {appointmentStatus === "failed" && (
            <p>Error: {appointmentError}</p>
          )}

          {appointmentStatus === "succeeded" && (
            <>
              {/* 
                Conditionally render either the card layout 
                or the desktop table 
              */}
              {isMobile ? renderMobileCards() : renderDesktopTable()}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppointmentDashboard;
