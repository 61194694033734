import React, { useState, useEffect } from 'react';
import '../CSS/LanguageSelector.css';

const LanguageSelector = () => {
  const [isOpen, setIsOpen] = useState(false);
  
  // Optional: useEffect to confirm TranslateElement availability
  useEffect(() => {
    // Polling method to check if google translate is ready
    const intervalId = setInterval(() => {
      if (window.google && window.google.translate && window.google.translate.TranslateElement) {
        clearInterval(intervalId);
        // If needed, re-initialize here or do something else
      }
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  const toggleTranslateMenu = () => {
    setIsOpen(!isOpen);
    const translateElement = document.getElementById('google_translate_element');
    if (translateElement && window.google && window.google.translate) {
      // Only toggle display if we know translate is ready
      translateElement.style.display = isOpen ? 'none' : 'block';
    }
  };

  return (
    <>
      <button
        onClick={toggleTranslateMenu}
        className="language-button"
        aria-label="Translate this page"
      >
        <img src="/static/icons/translate.png" alt="Translate Icon" />
        Translate
      </button>
    </>
  );
};

export default LanguageSelector;
