// src/Components/DoctorInfoBox.jsx
import React from 'react';
import ReactDOM from 'react-dom';
import '../CSS/DoctorInfoBox.css'; // Ensure you create and style this CSS file accordingly

const DoctorInfoBox = ({ doctor, onClose, position }) => {
  if (!doctor) return null;

  return ReactDOM.createPortal(
    <div
      className="doctor-info-box"
      style={{ top: position.top, left: position.left }}
    >
      <button className="close-info-box" onClick={onClose}>
        &times;
      </button>
      <div className="doctor-details-box">
        <img
          src={doctor.profile_photo || "/static/icons/default_profile_photo.webp"}
          alt={`Dr. ${doctor.user?.first_name || "-"} ${doctor.user?.last_name || "-"}`}
          className="doctor-info-image"
        />
        <h3>
          Dr. {doctor.user?.first_name || "-"} {doctor.user?.last_name || "-"}
        </h3>
        <p>
          <strong>Degree:</strong> {doctor.degree || "-"}
        </p>
        <p>
          <strong>Specialization:</strong> {doctor.specialization || "-"}
        </p>
        <p>
          <strong>Branch:</strong> {doctor.branch || "-"}
        </p>
        <p>
          <strong>Country:</strong> {doctor.country || "-"}
        </p>
        <p>
          <strong>Email:</strong> {doctor.user?.email || "-"}
        </p>
        {/* Add more details as needed */}
      </div>
    </div>,
    document.body // Render the portal at the root of the DOM
  );
};

export default DoctorInfoBox;
