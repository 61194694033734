// AdminDoctorDashboard.jsx

import React, { useState, useEffect, Fragment } from 'react';
import { ChevronDown,Search  } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDoctors, adminLogout } from '../slices/adminSlice';
import { useNavigate } from 'react-router-dom';
import AdminNavbar from '../Components/AdminNavbar';
import {
  Form,
  Button,
  InputGroup,
  FormControl,
  Row,
  Col,
  ButtonGroup,
  Container,
  Card,
  Table,
} from 'react-bootstrap';

const AdminDoctorDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  
  const doctors = useSelector((state) => state.admin.doctors);
  const doctorStatus = useSelector((state) => state.admin.doctorStatus);
  const error = useSelector((state) => state.admin.doctorError);
  const adminToken = useSelector((state) => state.admin.adminToken);

  const [selectedBranch, setSelectedBranch] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedDoctor, setExpandedDoctor] = useState(null);

  useEffect(() => {
    if (!adminToken) {
      navigate('/admin/login');
    }
  }, [adminToken, navigate]);

  useEffect(() => {
    if (doctorStatus === 'idle') {
      dispatch(fetchDoctors());
    }
  }, [doctorStatus, dispatch]);

  const handleLogout = () => {
    dispatch(adminLogout());
    navigate('/admin/login');
  };

  const branches =
    doctors && doctors.length > 0
      ? Array.from(new Set(doctors.map((doctor) => doctor.branch)))
      : [];

  const filteredDoctors = doctors.filter((doctor) => {
    // Filter by branch
    if (selectedBranch && doctor.branch !== selectedBranch) {
      return false;
    }

    // Search filter
    if (searchTerm) {
      const search = searchTerm.toLowerCase();
      const fullName = `${doctor.user.first_name} ${doctor.user.last_name}`.toLowerCase();
      const email = doctor.user.email.toLowerCase();
      const phone = doctor.phone.toLowerCase();
      const specialization = doctor.specialization.toLowerCase();
      const degree = doctor.degree.toLowerCase();
      const country = doctor.country.toLowerCase();

      return (
        fullName.includes(search) ||
        email.includes(search) ||
        phone.includes(search) ||
        specialization.includes(search) ||
        degree.includes(search) ||
        country.includes(search)
      );
    }

    return true;
  });

  const sortedDoctors = [...filteredDoctors].sort((a, b) => {
    if (a.branch < b.branch) return -1;
    if (a.branch > b.branch) return 1;
    return 0;
  });

  const toggleExpand = (id) => {
    setExpandedDoctor(expandedDoctor === id ? null : id);
  };

  return (
    <div className="bootstrap-scope">
      <div className="admin-dashboard-container">
      <AdminNavbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="admin-dashboard-content container-fluid py-4">
          {/* Header Section */}
          <div
                      className="admin-header"
                      
                    >
                      <div className="logo-box d-flex align-items-center">
                  <img
                    src="/static/icons/logo.webp"
                    alt="Medbridge-access-logo"
                    className="Medbridge-access-logo me-2"
                  />
                  <div className="logo">
                    <h1 className="mb-0">
                      <span className="title-we">Med</span>
                      <div className="title-2">
                        <span>Bridge</span> <span>Access</span>
                      </div>
                    </h1>
                  </div>
                </div>
          
                {/* Right: Hamburger */}
                <button
                  className="navbar-hamburger-btn"
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                  aria-label="Open Sidebar"
                >
                  <i className="fa-solid fa-bars fa-lg"></i>
                </button>
                    </div>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div style={{textAlign:'left'}}>
              <h5 className="mb-0">Doctors</h5>
              <p className="mb-0 text-muted total-count" style={{ fontSize: '0.85rem' }}>
                Total {doctors.length} doctors
              </p>
            </div>

            {/* Search Bar */}
            <InputGroup
              className="me-3"
              style={{
                border: '1px solid rgba(222,226,230,0.7)',
                borderRadius: '4px',
                width: '250px',
              }}
            >
              <FormControl
                placeholder="Search..."
                aria-label="Search doctors"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ border: 'none', fontSize: '0.85rem' }}
              />
              <Button variant="outline-secondary" className="bg-transparent border-0">
              <Search size={16} />
              </Button>
            </InputGroup>
          </div>

          {/* Filter Section */}
          <Row className="mb-3 align-items-center">
            <Col md={6}></Col> {/* Empty column to align filter to the right */}
            <Col
              md={6}
              className="d-flex justify-content-end align-items-center"
            >
              <Form className="d-flex" style={{ gap: '10px', flexDirection: 'row' }}>
                <div
                  className="d-flex align-items-center border rounded px-2"
                  style={{
                    borderColor: 'rgba(222,226,230,0.7)',
                    backgroundColor: '#f9f9f9',
                    width: '200px',
                  }}
                >
                  <Form.Label htmlFor="branch-filter" className="me-2 mb-0">
                 
                  </Form.Label>
                  <Form.Select
                    id="branch-filter"
                    value={selectedBranch}
                    onChange={(e) => setSelectedBranch(e.target.value)}
                    style={{ border: 'none', fontSize: '0.85rem', backgroundColor: '#f9f9f9' }}
                  >
                    <option value="">All Branches</option>
                    {branches.map((branch) => (
                      <option key={branch} value={branch}>
                        {branch}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Form>
            </Col>
          </Row>

          {/* Doctors Table */}
          {doctorStatus === 'loading' && <p>Loading doctors...</p>}
          {doctorStatus === 'failed' && <p>Error: {error}</p>}
          {doctorStatus === 'succeeded' && (
            <Card className="shadow-sm">
              <Card.Body className="p-0">
                <Table
                  responsive
                  className="mb-0"
                  style={{
                    fontSize: '0.85rem',
                    borderCollapse: 'separate',
                    borderSpacing: '0 8px',
                  }}
                >
                  <thead
                    style={{
                      backgroundColor: '#f9f9f9',
                      borderBottom: '0.5px solid rgba(222,226,230,0.7)',
                    }}
                  >
                    <tr>
                      <th style={{ width: '3%' }}>ID</th>
                      <th style={{ width: '22%' }}>Name</th>
                      <th style={{ width: '10%' }}>Specialization</th>
                      <th style={{ width: '15%' }}>Email</th>
                      <th style={{ width: '10%' }}>Branch</th>
                      <th style={{ width: '10%' }}>Country</th>
                      <th style={{ width: '10%' }}>Degree</th>
                      <th style={{ width: '10%' }}>Phone</th>
                      <th style={{ width: '10%' }}>Experience</th>
                      <th style={{ width: '5%' }}>Details</th>
                    </tr>
                  </thead>
                  <tbody style={{ borderTop: 'none' }}>
                    {sortedDoctors.map((doctor) => (
                      <Fragment key={doctor.user.id}>
                        <tr
                          className="align-middle"
                          style={{
                            border: '0.5px solid rgba(222,226,230,0.7)',
                            backgroundColor: '#fff',
                            borderRadius: '4px',
                          }}
                        >
                          <td style={{ padding: '12px 8px' }}>{doctor.user.id}</td>
                        
                          <td style={{ padding: '12px 8px',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'start',
                            gap:'5px'

                           }}>
                          <img
                              src={doctor.profile_photo}
                              alt="Profile"
                              className="doctor-profile-photo"
                              style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                            />
                            {doctor.user.first_name} {doctor.user.last_name}
                          </td>
                          <td style={{ padding: '12px 8px' }}>{doctor.specialization}</td>
                          <td style={{ padding: '12px 8px' }}>{doctor.user.email}</td>
                          <td style={{ padding: '12px 8px' }}>{doctor.branch}</td>
                          <td style={{ padding: '12px 8px' }}>{doctor.country}</td>
                          <td style={{ padding: '12px 8px' }}>{doctor.degree}</td>
                          <td style={{ padding: '12px 8px' }}>{doctor.phone}</td>
                          <td style={{ padding: '12px 8px' }}>{doctor.years_of_experience}</td>
                          <td style={{ padding: '12px 8px' }}>
                            <Button
                              variant="outline-secondary"
                              size="sm"
                              className={`appointment-dashboard-action-button ${
                                expandedDoctor === doctor.user.id ? 'expanded' : ''
                              }`}
                              onClick={() => toggleExpand(doctor.user.id)}
                              aria-label="Toggle Details"
                              style={{ padding: '4px 8px' }}
                            >
                              <ChevronDown size={18} />
                            </Button>
                          </td>
                        </tr>
                        {expandedDoctor === doctor.user.id && (
                          <tr className="bg-light" style={{ textAlign: 'left' }}>
                            <td
                              colSpan={11}
                              style={{
                                borderBottom: '1px solid rgba(222,226,230,0.7)',
                                padding: '12px 8px',
                                backgroundColor: '#f8f9fa',
                              }}
                            >
                              <Container fluid className="py-3">
                                <Row>
                                  <Col md={4}>
                                    <h5 style={{ fontSize: '1rem' }}>Doctor Information</h5>
                                    <p style={{ fontSize: '0.85rem' }}>
                                      <strong>Name:</strong> {doctor.user.first_name}{' '}
                                      {doctor.user.last_name}
                                    </p>
                                    <p style={{ fontSize: '0.85rem' }}>
                                      <strong>Email:</strong> {doctor.user.email}
                                    </p>
                                    <p style={{ fontSize: '0.85rem' }}>
                                      <strong>Phone:</strong> {doctor.phone}
                                    </p>
                                  </Col>
                                  <Col md={4}>
                                    <h5 style={{ fontSize: '1rem' }}>Professional Details</h5>
                                    <p style={{ fontSize: '0.85rem' }}>
                                      <strong>Specialization:</strong> {doctor.specialization}
                                    </p>
                                    <p style={{ fontSize: '0.85rem' }}>
                                      <strong>Degree:</strong> {doctor.degree}
                                    </p>
                                    <p style={{ fontSize: '0.85rem' }}>
                                      <strong>Years of Experience:</strong> {doctor.years_of_experience}
                                    </p>
                                  </Col>
                                  <Col md={4}>
                                    <h5 style={{ fontSize: '1rem' }}>Additional Details</h5>
                                    <p style={{ fontSize: '0.85rem' }}>
                                      <strong>Branch:</strong> {doctor.branch}
                                    </p>
                                    <p style={{ fontSize: '0.85rem' }}>
                                      <strong>Country:</strong> {doctor.country}
                                    </p>
                                    {/* Add more details as needed */}
                                  </Col>
                                </Row>
                              </Container>
                            </td>
                          </tr>
                        )}
                      </Fragment>
                    ))}
                    {sortedDoctors.length === 0 && (
                      <tr>
                        <td
                          colSpan={11}
                          className="text-center p-3"
                          style={{ fontSize: '0.85rem' }}
                        >
                          No doctors found matching the criteria.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminDoctorDashboard;
