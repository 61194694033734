import React, { useState, useEffect } from "react";
import DoctorSidebar from "../Components/DoctorSidebar";
import MobileHeader from "../Components/MobileHeader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../CSS/DoctorDetails.css";

const DoctorProfileUpdate = () => {
  const doctorData = useSelector((state) => state.doctor.doctorData);
  const doctor = doctorData?.doctor;
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Initialize formData state
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    qualification: "",
    specialty: "",
    country: "",
    experience: "",
  });

  useEffect(() => {
    if (!doctorData) {
      navigate("/doctors/login");
    } else {
      // Populate formData with doctor's data
      setFormData({
        firstName: doctor?.user?.first_name || "",
        lastName: doctor?.user?.last_name || "",
        phone: doctor?.phone || "",
        qualification: doctor?.degree || "",
        specialty: doctor?.specialization || "",
        country: doctor?.country || "",
        experience: doctor?.years_of_experience || "",
      });
    }
  }, [doctorData, navigate, doctor]);

  if (!doctorData) {
    return null; // or a loading spinner
  }

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Updated profile data:", formData);
    // Dispatch an action or make an API call to update the doctor's profile
  };

  return (
    <div className="doctor-dashboard">
      <div className="doctor-dashboard-overlay">
        <MobileHeader toggleSidebar={toggleSidebar} />
        <div className="dashboard-box">
          <DoctorSidebar
            isOpen={isSidebarOpen}
            onClose={() => setIsSidebarOpen(false)}
          />
          <div
            className={`sidebar-overlay ${isSidebarOpen ? "active" : ""}`}
            onClick={() => setIsSidebarOpen(false)}
          ></div>

          <div className="doctor-profile">
            {/* Profile Card */}
            <div className="banner-image">
              <img src="/static/banner/dash-slick-3.jpg" alt="Doctor" />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="doctor-profile-card-box">
                <h2>Your Profile</h2>
                <p>View and edit your personal details</p>
                <div className="doctor-profile-box">
                  <div className="doctor-profile-card">
                    <div className="doctor-profile-image">
                      <img
                        src={
                          doctor?.profile_photo || "/api/placeholder/120/120"
                        }
                        alt="Doctor profile"
                      />
                    </div>
                    <button>Update Image</button>
                  </div>

                  {/* Update Form */}
                  <div className="doctor-profile-form">
                    <div className="doctor-form-group-box">
                      <div className="doctor-form-group">
                        <label>First Name</label>
                        <input
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="doctor-form-group">
                        <label>Last Name</label>
                        <input
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="doctor-form-group">
                        <label>Phone</label>
                        <input
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="doctor-form-group">
                        <label>Qualification</label>
                        <input
                          name="qualification"
                          value={formData.qualification}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="doctor-form-group">
                        <label>Specialty</label>
                        <input
                          name="specialty"
                          value={formData.specialty}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="doctor-form-group">
                        <label>Country</label>
                        <input
                          name="country"
                          value={formData.country}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="doctor-form-group">
                        <label>Experience (years)</label>
                        <input
                          name="experience"
                          type="number"
                          value={formData.experience}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <button type="submit" className="doctor-update-btn">
                      Update Profile
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorProfileUpdate;
