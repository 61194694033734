// src/Components/Dashboard.jsx
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import UserSidebar from "../Components/UserSidebar";
import MobileHeader from "../Components/MobileHeader";
import { Navigate, useNavigate } from "react-router-dom";
import "../CSS/UserDashboard.css";
import AppointmentModal from "../Components/AppointmentModal";
import QuoteModal from "../Components/QuoteModal";
import { fetchAppointments } from "../slices/userSlice";
import "../CSS/VitalModal.css";
import VitalsModal from "../Components/VitalModal";
import PatientHeader from "../Components/PatientHeader";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import DoctorInfoBox from "../Components/DoctorInfoBox"; // Import the new component
import Loader from "../Components/Loader"; // Ensure you have a Loader component

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Selectors from Redux store
  const userState = useSelector((state) => state.user.userData);
  const appointments = useSelector((state) => state.user.appointments);
  const fetchAppointmentsStatus = useSelector((state) => state.user.fetchAppointmentsStatus);
  const fetchAppointmentsError = useSelector((state) => state.user.fetchAppointmentsError);

  const vitals = userState?.patient?.vitals || [];
  const latestVitals = vitals[0] || {};

  const [isEditable, setIsEditable] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isVitalsModalOpen, setIsVitalsModalOpen] = useState(false);
  const [currentAppointmentIndex, setCurrentAppointmentIndex] = useState(0);

  // New state to manage 'Read more' in medical history
  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  // State and logic for "Your Doctors" section
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [isInfoBoxOpen, setIsInfoBoxOpen] = useState(false);
  const [infoBoxPosition, setInfoBoxPosition] = useState({ top: 0, left: 0 });

  // Extract unique doctors from appointments
  const uniqueDoctors = useMemo(() => {
    if (!appointments) {
      return [];
    }
    const doctorMap = {};
    appointments.forEach((appointment) => {
      const doctor = appointment.doctor;
      if (doctor && doctor.user && doctor.user.id && !doctorMap[doctor.user.id]) {
        doctorMap[doctor.user.id] = doctor;
      }
    });
    return Object.values(doctorMap);
  }, [appointments]);

  // Initialize selectedDoctor to the first doctor on initial load
  useEffect(() => {
    if (uniqueDoctors.length > 0 && !selectedDoctor) {
      setSelectedDoctor(uniqueDoctors[0]);
      setIsInfoBoxOpen(false); // Info box is hidden initially
    }
  }, [uniqueDoctors, selectedDoctor]);

  // Ref for the doctor info box (if needed for additional functionality)
  const infoBoxRef = useRef();

  // Effect to fetch appointments
  useEffect(() => {
    if (userState && userState.patient) {
      dispatch(fetchAppointments());
    }
  }, [dispatch, userState]);

  // Destructure patient data
  const {
    user: { username = "", email = "", first_name = "", last_name = "" } = {},
    country = "",
    dob = "",
    gender = "",
    phone = "",
    medical_histories = [],
    medical_reports = [],
  } = userState?.patient || {};

  // **Extract only the first name**
  const firstNameOnly = first_name ? first_name.split(" ")[0] : "";

  const today = new Date();
  const day = today.getDate();
  const month = today.toLocaleString("default", { month: "long" });

  const weight = latestVitals.weight || "N/A";
  const height = latestVitals.height || "N/A";
  const bmi = latestVitals.bmi || "N/A";

  // Extended metrics array with descriptions
  const metrics = [
    {
      title: "Blood Pressure",
      value: latestVitals.blood_pressure ? `${latestVitals.blood_pressure}` : "N/A",
      icon: (
        <img
          src="/static/banner/bp.jpg"
          alt="Blood Pressure"
          className="blood-pressure"
        />
      ),
      description:
        "Your heart is pumping strong! Keep monitoring to stay healthy.",
    },
    {
      title: "Pulse Rate",
      value: latestVitals.pulse_rate ? `${latestVitals.pulse_rate} bpm` : "N/A",
      icon: (
        <img
          src="/static/banner/pulse.jpg"
          alt="Pulse Rate"
          className="pulse"
        />
      ),
      description: "Your pulse is on point! Keep that heart happy.",
    },
    {
      title: "Oxygen Level",
      value: latestVitals.oxygen_level ? `${latestVitals.oxygen_level}%` : "N/A",
      icon: (
        <img
          src="/static/banner/oxygen.jpg"
          alt="Oxygen Level"
          className="oxygen"
        />
      ),
      description: "Breathing easy! Your oxygen levels are looking great.",
    },
    {
      title: "Weight",
      value: `${weight} kg`,
      icon: (
        <img src="/static/banner/weight.jpg" alt="Weight" className="weight" />
      ),
      description: "You're maintaining a healthy weight. Keep it up!",
    },
    {
      title: "Height",
      value: `${height} cm`,
      icon: (
        <img src="/static/banner/height.jpg" alt="Height" className="height" />
      ),
      description: "Standing tall! Your height is just perfect.",
    },
    // You can add more metrics here if needed
  ];

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const mapCategoryName = (category) => {
    const categoryMap = {
      registration: "Registration",
      appointment: "Appointment",
      diagnosis: "Diagnosis",
      // Add more categories as needed
    };
    return categoryMap[category] || category || "-";
  };

  const defaultProfilePhoto = "/static/icons/default_profile_photo.webp";

  const handleAppointmentClick = (appointment) => {
    navigate("/chat", { state: { selectedUser: appointment.doctor } });
  };

  const handleNextAppointment = () => {
    setCurrentAppointmentIndex((prevIndex) =>
      (prevIndex + 1) % (appointments.length || 1)
    );
  };

  const handlePreviousAppointment = () => {
    setCurrentAppointmentIndex((prevIndex) =>
      prevIndex === 0 ? (appointments.length || 1) - 1 : prevIndex - 1
    );
  };

  // Function to toggle 'Read more' for descriptions
  const toggleReadMore = (index) => {
    setExpandedDescriptions((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // Function to get border color based on category
  const getBorderColor = (category) => {
    const categoryColors = {
      registration: "#4CAF50", // Green
      appointment: "#2196F3", // Blue
      diagnosis: "#f44336", // Red
      // Add more categories and colors as needed
    };
    return categoryColors[category] || "#000"; // Default to black
  };

  // Function to handle doctor clicks and position the info box
  const handleDoctorClick = (doctor, event) => {
    const rect = event.target.getBoundingClientRect();
    setInfoBoxPosition({
      top: rect.top + window.scrollY,
      left: rect.right + 10 + window.scrollX, // 10px offset to the right
    });

    if (selectedDoctor?.user.id === doctor.user.id && isInfoBoxOpen) {
      setIsInfoBoxOpen(false); // Toggle off if the same doctor is clicked
    } else {
      setSelectedDoctor(doctor);
      setIsInfoBoxOpen(true);
    }
  };

  // Conditional rendering after all Hooks are declared
  if (!userState || !userState.patient) {
    return <Navigate to="/bookappointment" />;
  }

  return (
    <div className="dashboard">
      <div className="dashboard-overlay">
        <MobileHeader toggleSidebar={toggleSidebar} />
        <div className="dashboard-box">
          <UserSidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
          <div
            className={`sidebar-overlay ${isSidebarOpen ? "active" : ""}`}
            onClick={() => setIsSidebarOpen(false)}
          ></div>

          <div className="dashboard-section">
            <PatientHeader />
            <div className="dashboard-section-box">
              <div className="dashboard-left">
                <div className="dashboard-left-box">
                  <div className="dashboard-left-box-header">
                    <h4>Connecting hearts healing mind</h4>
                    <h1 className="username">
                      Hello, <strong>{firstNameOnly ? firstNameOnly : username}</strong>
                    </h1>
                    <p>
                      Book an appointment & Take the first step toward a healthier, happier you today!
                    </p>
                  </div>

                  {/* "Your Doctors" Section */}
                  <div className="your-doctors-section">
                    <h2>Your Doctors</h2>
                    {fetchAppointmentsStatus === 'loading' ? (
                      // Loader while fetching appointments
                      <Loader />
                    ) : uniqueDoctors.length > 0 ? (
                      <div className="doctors-row">
                        {uniqueDoctors.map((doctor) => (
                          <div className="doctor-wrapper" key={doctor.user.id}>
                            <img
                              src={
                                doctor.profile_photo ||
                                "/static/icons/default_profile_photo.webp"
                              }
                              alt={`Dr. ${doctor.user?.first_name || "-"} ${
                                doctor.user?.last_name || "-"
                              }`}
                              className={`doctor-thumbnail ${
                                selectedDoctor?.user.id === doctor.user.id
                                  ? "active"
                                  : "inactive"
                              }`}
                              onClick={(e) => handleDoctorClick(doctor, e)}
                            />
                            <span>
                              <p>
                                Dr. {doctor.user?.first_name || "-"} {doctor.user?.last_name || "-"}
                              </p>
                            </span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      // Empty state for "Your Doctors" if no doctors are found
                      <p>No doctors found.</p>
                    )}
                    {/* Render the DoctorInfoBox using Portal */}
                    {isInfoBoxOpen && selectedDoctor && (
                      <DoctorInfoBox
                        doctor={selectedDoctor}
                        onClose={() => setIsInfoBoxOpen(false)}
                        position={infoBoxPosition}
                      />
                    )}
                  </div>
                  {/* End of "Your Doctors" Section */}
                </div>

                {/* Existing Appointment Cards */}
                <div className="appointment-cards">
                  <h2>Your Appointments</h2>
                  {fetchAppointmentsStatus === 'loading' ? (
                    // Loader while fetching appointments
                    <Loader />
                  ) : appointments && appointments.length > 0 ? (
                    <div className="appointment-card-slider">
                      <div
                        key={appointments[currentAppointmentIndex].id}
                        className="appointment-card"
                        onClick={() =>
                          handleAppointmentClick(
                            appointments[currentAppointmentIndex]
                          )
                        }
                      >
                        <div className="appointment-card-header">
                          <h3>
                            {appointments[currentAppointmentIndex].problem_description}
                          </h3>
                        </div>
                        <div className="datetime-info">
                          <div className="datetime-pair">
                            <span className="datetime-value">
                              {appointments[currentAppointmentIndex].appointment_time
                                ? (() => {
                                    const date = new Date(
                                      appointments[currentAppointmentIndex].appointment_time
                                    );
                                    const month = date.toLocaleString("en-US", {
                                      month: "short",
                                    }); // e.g., "Aug"
                                    const day = getOrdinalSuffix(date.getDate()); // e.g., "28th"
                                    const year = date.getFullYear(); // e.g., "2024"
                                    return `${month} ${day}, ${year}`;
                                  })()
                                : "-"}
                            </span>
                          </div>
                          <div className="datetime-pair">
                            <span className="datetime-value">
                              {appointments[currentAppointmentIndex].appointment_time
                                ? (() => {
                                    const time = new Date(
                                      appointments[currentAppointmentIndex].appointment_time
                                    ).toLocaleTimeString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true, // Ensure 12-hour format
                                    });
                                    // Ensure AM/PM is uppercase (optional, as 'en-US' typically provides uppercase)
                                    return time.toUpperCase();
                                  })()
                                : "-"}
                            </span>
                          </div>
                        </div>

                        <div className="doctor-info">
                          <img
                            src={
                              appointments[currentAppointmentIndex].doctor &&
                              appointments[currentAppointmentIndex].doctor.profile_photo
                                ? appointments[currentAppointmentIndex].doctor.profile_photo
                                : defaultProfilePhoto
                            }
                            alt={`Dr. ${
                              appointments[currentAppointmentIndex].doctor &&
                              appointments[currentAppointmentIndex].doctor.user
                                ? `${appointments[currentAppointmentIndex].doctor.user.first_name || "-"
                                  } ${appointments[currentAppointmentIndex].doctor.user.last_name || "-"}`
                                : "Doctor Unassigned"
                            }`}
                            className="doctor-image"
                          />
                          <div className="doctor-details">
                            <h2>
                              {appointments[currentAppointmentIndex].doctor &&
                              appointments[currentAppointmentIndex].doctor.user
                                ? `Dr. ${
                                    appointments[currentAppointmentIndex].doctor.user.first_name ||
                                    "-"
                                  } ${
                                    appointments[currentAppointmentIndex].doctor.user.last_name ||
                                    "-"
                                  }`
                                : "Doctor Unassigned"}
                            </h2>
                            <p className="speciality">
                              {appointments[currentAppointmentIndex].doctor
                                ? `${appointments[currentAppointmentIndex].doctor.degree || "-"
                                  }, ${appointments[currentAppointmentIndex].doctor.country || "-"
                                  }`
                                : "-"}
                            </p>
                            <p className="branch">
                              {appointments[currentAppointmentIndex].doctor
                                ? `${appointments[currentAppointmentIndex].doctor.branch || "-"
                                  }, ${
                                    appointments[currentAppointmentIndex].doctor.specialization ||
                                    "-"
                                  }`
                                : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="doctor-info-2"></div>

                        <div className="appointment-actions">
                          <span>
                            {appointments[currentAppointmentIndex].status || "-"}
                          </span>
                          <div className="appointment-actions-buttons">
                            <button
                              className="arrow-button prev"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent triggering the card click
                                handlePreviousAppointment();
                              }}
                              disabled={appointments.length <= 1}
                            >
                              <IoIosArrowBack />
                            </button>
                            <button
                              className="arrow-button next"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent triggering the card click
                                handleNextAppointment();
                              }}
                              disabled={appointments.length <= 1}
                            >
                              <IoIosArrowForward />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // Updated Empty State Design for Appointments
                    <div className="doctors-empty-state">
                      <img
                        src="/static/banner/no-appointment.webp" // Replace with your preferred image path
                        alt="No Doctors"
                        className="empty-state-image"
                      />
                      <h3>No Doctors Found</h3>
                      <p>You currently have no associated doctors.</p>
                      <button
                        className="book-now-button"
                        onClick={() => navigate("/bookappointment")}
                      >
                        Book Now
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="dashboard-middle">
                <div className="health-metrics-container">
                  <div className="health-metrics-header">
                    <h2>Your Vitals</h2>
                    <button
                      className="health-metrics-see-all"
                      onClick={() => setIsVitalsModalOpen(true)}
                    >
                      Update
                    </button>
                  </div>

                  <div className="health-metrics-grid">
                    {metrics.map((metric, index) => (
                      <div key={index} className="health-metric">
                        <h4>{metric.title} Insights</h4>
                        <p>{metric.description}</p>
                        <div className="health-metrics-card">
                          <div className="health-metrics-info">
                            <span className="health-metrics-title">
                              {metric.title}
                            </span>
                            <span className="health-metrics-value">
                              {metric.value}
                            </span>
                          </div>
                          <div className="health-metrics-icon">
                            {metric.icon}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="dashboard-right">
                <div className="medical-history">
                  <div className="history-header">
                    <h2>Medical History</h2>
                    <div className="history-actions">
                      {/* Add actions if needed */}
                    </div>
                  </div>
                  <div className="history-content">
                    {medical_histories.length > 0 ? (
                      medical_histories.map((item, index) => (
                        <div className="history-item" key={index}>
                          <div className="history-timeline">
                            <div className="history-date">
                              {formatDate(item.created_at)}
                            </div>
                          </div>
                          <div
                            className="history-details"
                            style={{
                              borderLeft: `4px solid ${getBorderColor(
                                item.category
                              )}`,
                            }}
                          >
                            <h4 className="history-title">
                              {mapCategoryName(item.category)}
                            </h4>
                            {item.description && (
                              <p className="history-description">
                                {expandedDescriptions[index] ||
                                item.description.length <= 50
                                  ? item.description
                                  : `${item.description.slice(0, 50)}...`}
                                {item.description.length > 50 && (
                                  <button
                                    className="read-more-button"
                                    onClick={() => toggleReadMore(index)}
                                  >
                                    {expandedDescriptions[index]
                                      ? "Show less"
                                      : "Read more"}
                                  </button>
                                )}
                              </p>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <h4 className="not-found-message">
                        No medical history found.
                      </h4>
                    )}
                  </div>
                </div>
              </div>

              {/* Existing Modals */}
              <QuoteModal
                isOpen={isQuoteModalOpen}
                onClose={() => setIsQuoteModalOpen(false)}
              />

              <AppointmentModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
              />
              <VitalsModal
                isOpen={isVitalsModalOpen}
                onClose={() => setIsVitalsModalOpen(false)}
              />
            </div>
          </div>
        </div>
        {/* Optional: Add an overlay to darken the background when DoctorInfoBox is open */}
        {isInfoBoxOpen && (
          <div
            className="info-box-overlay"
            onClick={() => setIsInfoBoxOpen(false)}
          ></div>
        )}
      </div>
    </div>
  );
};

// Helper function for ordinal suffix (can remain outside the component)
const getOrdinalSuffix = (day) => {
  if (day > 3 && day < 21) return `${day}th`;
  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
};

export default Dashboard;
