// src/Components/Notifications.js

import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast, ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  clearErrors,
  markMessageAsNotNew, // Now correctly exported and imported
  setSelectedUser,
} from '../slices/notificationSlice'; // Adjust the path as necessary
import { useNavigate } from 'react-router-dom';
import { generateRoomName } from '../utils'; // Ensure generateRoomName is correctly implemented

const Notifications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Selectors to retrieve necessary state from Redux store
  const errors = useSelector((state) => state.notifications.errors);
  const chatMessages = useSelector((state) => state.notifications.chatMessages);
  const currentRoom = useSelector((state) => state.notifications.currentRoom);
  const users = useSelector((state) => state.notifications.users);
  const currentUserId = useSelector((state) => {
    if (state.user?.userData?.patient?.user?.id) {
      return state.user.userData.patient.user.id;
    } else if (state.doctor?.doctorData?.doctor?.user?.id) {
      return state.doctor.doctorData.doctor.user.id;
    }
    return null;
  });

  // Determine if the current user is a doctor
  const isDoctor = useSelector((state) => !!state.doctor.doctorData?.doctor);

  // Refs to track notified messages and prevent duplicate toasts
  const notifiedMessages = useRef(new Set());

  // Ref for the audio element
  const audioRef = useRef(null);

  /**
   * Initialize the audio element once when the component mounts
   */
  useEffect(() => {
    // Initialize audio
    audioRef.current = new Audio('/static/audio/notification.mp3'); // Adjust the path as necessary
    // Optionally, preload the audio
    audioRef.current.preload = 'auto';

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  /**
   * Function to navigate to the chat with the selected user
   */
  const navigateToChat = (user) => {
    if (currentUserId && user.user.id) {
      if (isDoctor) {
        navigate(`/doctors/chat/${user.user.id}`, { state: { selectedUser: user } });
      } else {
        navigate('/chat', { state: { selectedUser: user } });
      }
    } else {
      console.error('Unknown user role or missing user ID.');
    }
  };

  /**
   * Handle Error Notifications
   */
  useEffect(() => {
    if (errors.length > 0) {
      errors.forEach((error) => {
        // **Filter out specific error messages**
        const unwantedErrors = [
          "WebSocket encountered an error",
          "Not subscribed to this room",
        ];

        // Check if the error message starts with any unwanted error
        const isUnwanted = unwantedErrors.some((unwanted) =>
          error.message.startsWith(unwanted)
        );

        if (isUnwanted) {
          // Optionally log them for debugging without notifying the user
          console.warn("Filtered out error message:", error.message);
          return; // Do not display this error
        }

        // Customize specific error messages if needed
        let errorMessage = error.message;
        if (
          error.message === 'Either you or the receiver is already in another call'
        ) {
          errorMessage = 'The user is currently busy on another call.';
        }

        // Display the error toast
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      });

      // Clear errors after processing
      dispatch(clearErrors());
    }
  }, [errors, dispatch]);

  /**
   * Handle Room-Specific Message Notifications
   */
  useEffect(() => {
    // Iterate through all chat messages across all rooms
    Object.entries(chatMessages).forEach(([roomName, roomMessages]) => {
      roomMessages.forEach((message) => {
        // Skip messages sent by the current user
        if (message.isCurrentUser) return;

        // Skip messages from the current active room
        if (currentRoom === roomName) return;

        // Skip already notified messages
        if (notifiedMessages.current.has(message.id)) return;

        // Only notify if the message is new
        if (!message.isNew) return;

        // Retrieve sender's full name from the message data
        const senderFullName = message.senderFullName || 'Unknown User';

        // Play notification sound
        if (audioRef.current) {
          audioRef.current.currentTime = 0; // Reset to start
          const playPromise = audioRef.current.play();
          if (playPromise !== undefined) {
            playPromise.catch((error) => {
              console.error('Error playing notification sound:', error);
              // Optionally notify the user or take other actions
            });
          }
        }

        // Display the notification toast with redirection logic
        toast.info(
          `New message from ${senderFullName}: ${message.content}`,
          {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Bounce,
            // Add onClick handler for redirection
            onClick: () => {
              // Fetch the user details from centralized users
              const senderUserId = message.sender; // Ensure this matches the incoming message property
              let user = users[senderUserId];

              // If user is not found, create a temporary user object
              if (!user) {
                console.warn('User details not found for sender:', senderUserId);
                user = {
                  user: {
                    id: senderUserId,
                    fullName: message.senderFullName || 'Unknown User',
                  },
                  // Add any other necessary properties here
                };
              }

              dispatch(setSelectedUser(user));
              navigateToChat(user);
            },
          }
        );

        // Mark the message as notified to prevent duplicate toasts
        notifiedMessages.current.add(message.id);

        // Reset the isNew flag in the Redux store
        dispatch(markMessageAsNotNew({ roomName, messageId: message.id }));
      });
    });
  }, [
    chatMessages,
    currentRoom,
    dispatch,
    navigate,
    users,
    currentUserId,
    isDoctor, // Include isDoctor as a dependency
  ]);

  return <ToastContainer />;
};

export default Notifications;
