// src/pages/DoctorChatApp.js

import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import PatientProfile from '../Components/PatientProfile';
import ChatWindow from '../Components/ChatWindow';
import { fetchPatientDetails, clearPatientDetails } from '../slices/patientSlice';
import { setCurrentRoom, clearCurrentRoom } from '../slices/notificationSlice';
import { FiArrowLeft } from 'react-icons/fi'; // For mobile back button
import { FaHeartbeat } from 'react-icons/fa'; // For toggle buttons
import { generateRoomName } from '../utils'; // Centralized room naming utility
import webSocketManager from '../services/WebSocketManager'; // Ensure correct import path
import AddDiagnosisModal from '../Components/AddDiagnosisModal'; // Ensure you have this component
import MobileHeader from '../Components/MobileHeader';
import DoctorSidebar from '../Components/DoctorSidebar';

// Styled Components

const ChatAppContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  /* Adjust cursor during resize */
  .gutter {
    background-color: #ddd;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .gutter.gutter-horizontal {
    cursor: ew-resize;
    width: 5px;
  }
`;

const PatientProfileContainer = styled.div`
  width: ${({ width }) => width}px;
  min-width: 300px;
  max-width: 1000px;
  border-right: 1px solid #ddd;
  overflow-y: auto;
  position: relative;
  transition: width 0.2s ease;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ddd;
    display: ${({ show }) => (show ? 'block' : 'none')};
  }
`;

const Resizer = styled.div`
  width: 5px;
  cursor: ew-resize;
  background-color: #ddd;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ChatWindowContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 10px;
`;

const ToggleButtonContainerStyled = styled.div`
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const ToggleButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  font-size: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }
`;

/* 
  Make sure this button is fixed to bottom-right, 
  has a high z-index, and only shows on mobile (max-width: 768px).
*/
const StartChatButton = styled.button`
  
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 15px 25px;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  z-index: 1000; /* Ensures it appears above other elements */

  /* Show on mobile devices */
  @media (max-width: 768px) {
    display: block;
  }

  /* Hide on desktop devices */
  @media (min-width: 769px) {
    display: none;
  }
`;

const ContentPlaceholder = styled.div`
  padding: 20px;
  text-align: center;
  color: #666;
`;

// Main Component

const DoctorChatApp = () => {
  const location = useLocation();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // State Hooks
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isChatStarted, setIsChatStarted] = useState(!isMobile); // Show chat by default on desktop
  const [profileWidth, setProfileWidth] = useState(500); // Default width for PatientProfile
  const [isModalOpen, setIsModalOpen] = useState(false); // State for AddDiagnosisModal

  // Redux Selectors
  const doctorData = useSelector((state) => state.doctor.doctorData);
  const { details: patient, loading, error } = useSelector((state) => state.patient);

  // Selected User State
  const [selectedUser, setSelectedUser] = useState(() => {
    return location.state?.selectedUser || null;
  });

  useEffect(() => {
    console.log('Location State in DoctorChatApp:', location.state);
  }, [location.state]);
  
  // Refs for Resizer
  const resizerRef = useRef(null);
  const isResizing = useRef(false);
  const lastDownX = useRef(0);

  // Flatten the doctor data to match the expected structure
  const currentUser = useMemo(() => {
    if (doctorData && doctorData.doctor) {
      return {
        ...doctorData.doctor,
        user_type: 'doctor',
        access_token: doctorData.access_token,
        refresh_token: doctorData.refresh_token,
      };
    }
    return null;
  }, [doctorData]);

  // Ensure the patient has a user_type
  const patientWithUserType = useMemo(() => {
    if (patient) {
      return { ...patient, user_type: 'patient' };
    }
    return null;
  }, [patient]);

  // Generate room name for chat
  const roomName = useMemo(() => {
    if (patientWithUserType && currentUser && currentUser.user) {
      return generateRoomName(currentUser.user.id, patientWithUserType.user.id);
    }
    return null;
  }, [currentUser, patientWithUserType]);

  // Fetch patient details on mount
  useEffect(() => {
    if (selectedUser && currentUser && currentUser.user) {
      const room = generateRoomName(currentUser.user.id, selectedUser.user.id);
      dispatch(setCurrentRoom(room));
      setIsChatStarted(true); // Start chat if a user is selected
    } else if (patientId) {
      dispatch(fetchPatientDetails(patientId));
    }

    return () => {
      dispatch(clearPatientDetails());
    };
  }, [selectedUser, patientId, dispatch, currentUser]);

  // Set currentRoom when patient data is available
  useEffect(() => {
    if (patientWithUserType && roomName) {
      dispatch(setCurrentRoom(roomName)); // Set the currentRoom in Redux

      return () => {
        dispatch(clearCurrentRoom()); // Clear currentRoom when component unmounts or selectedPatient changes
      };
    }
  }, [patientWithUserType, roomName, dispatch]);

  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      setIsChatStarted(!mobile); // Automatically show chat on desktop
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Resizer Event Handlers
  const handleMouseMove = useCallback(
    (e) => {
      if (!isResizing.current) return;
      const dx = e.clientX - lastDownX.current;
      const newWidth = profileWidth + dx;

      // Set minimum and maximum widths
      if (newWidth > 200 && newWidth < 600) {
        setProfileWidth(newWidth);
        lastDownX.current = e.clientX;
      }
    },
    [profileWidth]
  );

  const handleMouseUp = useCallback(() => {
    if (isResizing.current) {
      isResizing.current = false;
      document.body.style.cursor = 'default';
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  const onMouseDown = useCallback((e) => {
    isResizing.current = true;
    lastDownX.current = e.clientX;
    document.body.style.cursor = 'ew-resize';
  }, []);

  // Handlers for initiating and ending chat
  const handleStartChat = useCallback(() => {
    setIsChatStarted(true);
  }, []);

  const handleEndChat = useCallback(() => {
    setIsChatStarted(false);
  }, []);

  const navigateToChat = useCallback(() => {
    setIsChatStarted(true);
  }, []);

  // Handlers for Add Diagnosis Modal
  const openDiagnosisModal = () => {
    setIsModalOpen(true);
  };

  const closeDiagnosisModal = () => {
    setIsModalOpen(false);
  };

  // Determine what to render based on state
  const isAuthenticated = doctorData && currentUser;
  const isLoading = loading;
  const hasError = error;
  const hasPatient = patient && patient.user;

  // Extract the latest appointment with the current doctor
  const latestAppointment = useMemo(() => {
    if (
      patientWithUserType &&
      patientWithUserType.appointments &&
      currentUser &&
      currentUser.user
    ) {
      // Filter appointments for the current doctor
      const doctorAppointments = patientWithUserType.appointments.filter(
        (appointment) =>
          appointment.doctor &&
          appointment.doctor.user &&
          appointment.doctor.user.id === currentUser.user.id
      );

      if (doctorAppointments.length === 0) return null;

      // Sort appointments by appointment_time in descending order
      const sortedAppointments = doctorAppointments.sort(
        (a, b) => new Date(b.appointment_time) - new Date(a.appointment_time)
      );

      // Return the latest appointment
      return sortedAppointments[0];
    }
    return null;
  }, [patientWithUserType, currentUser]);

  return (
    <div className='doctor-dashboard'>
      <div className='doctor-dashboard-overlay'>
        {/* 
          Only show MobileHeader if:
          - We are on desktop (not isMobile), OR
          - We are on mobile but haven't started chat (i.e., !isChatStarted)
        */}
        {(!isMobile || !isChatStarted) && <MobileHeader toggleSidebar={toggleSidebar} />}

        <div className='dashboard-box'>
          <DoctorSidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
          <div
            className={`sidebar-overlay ${isSidebarOpen ? 'active' : ''}`}
            onClick={() => setIsSidebarOpen(false)}
          ></div>

          <ChatAppContainer>
            {!isAuthenticated || isLoading || hasError || !hasPatient ? (
              <ContentPlaceholder>
                {!isAuthenticated
                  ? 'Please log in to view your chats.'
                  : isLoading
                  ? 'Loading patient details...'
                  : hasError
                  ? `Error loading patient details: ${hasError.message}`
                  : 'Loading data...'}
              </ContentPlaceholder>
            ) : (
              <>
                {/* Desktop Layout with Resizable PatientProfile */}
                {!isMobile ? (
                  <>
                    <PatientProfileContainer width={profileWidth}>
                      <PatientProfile
                        selectedUser={patientWithUserType}
                        onStartChat={handleStartChat}
                        // Pass only the latest appointment
                        appointment={latestAppointment}
                        openAddDiagnosisModal={openDiagnosisModal}
                      />
                      <Resizer onMouseDown={onMouseDown} />
                    </PatientProfileContainer>

                    <ChatWindowContainer>
                      <ChatWindow
                        selectedUser={patientWithUserType}
                        roomName={roomName}
                        currentUser={currentUser}
                        onBack={handleEndChat}
                      />
                    </ChatWindowContainer>
                  </>
                ) : (
                  /* Mobile Layout */
                  <>
                    {/* Patient Profile Section */}
                    {!isChatStarted && (
                      <PatientProfileContainer show={!isChatStarted}>
                        <PatientProfile
                          selectedUser={patientWithUserType}
                          onStartChat={handleStartChat}
                          appointment={latestAppointment}
                          openAddDiagnosisModal={openDiagnosisModal}
                        />
                      </PatientProfileContainer>
                    )}

                    {/* Chat Window Section */}
                    {isChatStarted && (
                      <ChatWindowContainer>
                        <ChatWindow
                          selectedUser={patientWithUserType}
                          roomName={roomName}
                          currentUser={currentUser}
                          onBack={handleEndChat}
                        />
                      </ChatWindowContainer>
                    )}

                    {/* Start Chat Button for Mobile - pinned bottom-right */}
                    {!isChatStarted && isMobile && (
                      <StartChatButton onClick={handleStartChat}>
                        Start Chat
                      </StartChatButton>
                    )}
                  </>
                )}
              </>
            )}
          </ChatAppContainer>
        </div>
      </div>
    </div>
  );
};

export default DoctorChatApp;
