// AdminPatientDashboard.jsx

import React, { useState, useEffect, Fragment } from 'react';
import { ChevronDown, Search } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPatients, adminLogout } from '../slices/adminSlice';
import { useNavigate } from 'react-router-dom';
import AdminNavbar from '../Components/AdminNavbar';
import {
  Form,
  Button,
  InputGroup,
  FormControl,
  Row,
  Col,
  Container,
  Card,
  Table,
  ButtonGroup,
} from 'react-bootstrap';

const AdminPatientDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false);
  
  const patients = useSelector((state) => state.admin.patients);
  const patientStatus = useSelector((state) => state.admin.patientStatus);
  const error = useSelector((state) => state.admin.patientError);
  const adminToken = useSelector((state) => state.admin.adminToken);

  const [searchTerm, setSearchTerm] = useState('');
  const [expandedPatient, setExpandedPatient] = useState(null);
  const [filters, setFilters] = useState({
    country: 'All',
    gender: 'All',
  });

  useEffect(() => {
    if (!adminToken) {
      navigate('/admin/login');
    }
  }, [adminToken, navigate]);

  useEffect(() => {
    if (patientStatus === 'idle') {
      dispatch(fetchPatients());
    }
  }, [patientStatus, dispatch]);

  const handleLogout = () => {
    dispatch(adminLogout());
    navigate('/admin/login');
  };

  const toggleExpand = (id) => {
    setExpandedPatient(expandedPatient === id ? null : id);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const filteredPatients = patients.filter((patient) => {
    const fullName = `${patient.user.first_name} ${patient.user.last_name}`.toLowerCase();
    const email = patient.user.email.toLowerCase();
    const phone = (patient.phone || '').toLowerCase();
    const search = searchTerm.toLowerCase();

    const matchesSearch =
      fullName.includes(search) ||
      email.includes(search) ||
      phone.includes(search);

    const matchesCountry =
      filters.country === 'All' || patient.country === filters.country;

    const matchesGender =
      filters.gender === 'All' || patient.gender === filters.gender;

    return matchesSearch && matchesCountry && matchesGender;
  });

  // Extract unique countries and genders for filter options
  const uniqueCountries = [...new Set(patients.map((p) => p.country))];
  const uniqueGenders = ['All', ...new Set(patients.map((p) => p.gender))];

  return (
    <div className="bootstrap-scope">
      <div className="admin-dashboard-container">
      <AdminNavbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="admin-dashboard-content container-fluid py-4">
          {/* Header Section */}
          <div
            className="admin-header"
            
          >
            <div className="logo-box d-flex align-items-center">
        <img
          src="/static/icons/logo.webp"
          alt="Medbridge-access-logo"
          className="Medbridge-access-logo me-2"
        />
        <div className="logo">
          <h1 className="mb-0">
            <span className="title-we">Med</span>
            <div className="title-2">
              <span>Bridge</span> <span>Access</span>
            </div>
          </h1>
        </div>
      </div>

      {/* Right: Hamburger */}
      <button
        className="navbar-hamburger-btn"
        onClick={() => setSidebarOpen(!sidebarOpen)}
        aria-label="Open Sidebar"
      >
        <i className="fa-solid fa-bars fa-lg"></i>
      </button>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-4">
          
            <div style={{ textAlign: 'left' }}>
              <h5 className="mb-0">Patients</h5>
              <p className="mb-0 text-muted total-count" style={{ fontSize: '0.85rem' }}>
                Total {patients.length} patients signed up
              </p>
            </div>

            {/* Search Bar */}
            <InputGroup
              className="me-3"
              style={{
                border: '1px solid rgba(222,226,230,0.7)',
                borderRadius: '4px',
                width: '250px',
              }}
            >
              <FormControl
                placeholder="Search..."
                aria-label="Search patients"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ border: 'none', fontSize: '0.85rem' }}
              />
              <Button variant="outline-secondary" className="bg-transparent border-0">
              <Search size={16} />
              </Button>
            </InputGroup>
          </div>

          {/* Filter Section */}
          <Row className="mb-4">
            <Col md={12} className="d-flex justify-content-end">
              <Form className="d-flex" style={{ gap: '10px' }}>
                {/* Country Filter */}
                <Form.Group controlId="filterCountry"   style={{
                    borderColor: 'rgba(222,226,230,0.7)',
                    backgroundColor: '#f9f9f9',
                    width: '200px',
                  }}>
                  <Form.Label className="visually-hidden">Filter by Country</Form.Label>
                  <Form.Select
                    name="country"
                    value={filters.country}
                    onChange={handleFilterChange}
                    style={{ fontSize: '0.85rem' }}
                  >
                    {/* Add 'Select Country' as the first option */}
                    <option value="All">Select Country</option>
                    {uniqueCountries.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

               
              </Form>
            </Col>
          </Row>

          {/* Patients Table */}
          {patientStatus === 'loading' && <p>Loading patients...</p>}
          {patientStatus === 'failed' && <p>Error: {error}</p>}
          {patientStatus === 'succeeded' && (
            <Card className="shadow-sm">
              <Card.Body className="p-0">
                <Table
                  responsive
                  className="mb-0"
                  style={{
                    fontSize: '0.85rem',
                    borderCollapse: 'separate',
                    borderSpacing: '0 8px',
                  }}
                >
                  <thead
                    style={{
                      backgroundColor: '#f9f9f9',
                      borderBottom: '0.5px solid rgba(222,226,230,0.7)',
                    }}
                  >
                    <tr>
                      <th style={{ width: '5%' }}>ID</th>
                      <th style={{ width: '18%' }}>Name</th>
                      <th style={{ width: '20%' }}>Email</th>
                      <th style={{ width: '15%' }}>Phone</th>
                      <th style={{ width: '15%' }}>Country</th>
                      <th style={{ width: '10%' }}>DOB</th>
                      <th style={{ width: '10%' }}>Gender</th>
                      <th style={{ width: '5%' }}>Details</th>
                    </tr>
                  </thead>
                  <tbody style={{ borderTop: 'none' }}>
                    {filteredPatients.map((patient) => (
                      <Fragment key={patient.id}>
                        <tr
                          className="align-middle"
                          style={{
                            border: '0.5px solid rgba(222,226,230,0.7)',
                            backgroundColor: '#fff',
                            borderRadius: '4px',
                          }}
                        >
                          <td style={{ padding: '12px 8px' }}>{patient.id}</td>
                          <td style={{ padding: '12px 8px' }}>
                            {patient.user.first_name} {patient.user.last_name}
                          </td>
                          <td style={{ padding: '12px 8px' }}>{patient.user.email}</td>
                          <td style={{ padding: '12px 8px' }}>{patient.phone}</td>
                          <td style={{ padding: '12px 8px' }}>{patient.country}</td>
                          <td style={{ padding: '12px 8px' }}>{patient.dob}</td>
                          <td style={{ padding: '12px 8px' }}>{patient.gender}</td>
                          <td style={{ padding: '12px 8px' }}>
                            <Button
                              variant="outline-secondary"
                              size="sm"
                              className={`appointment-dashboard-action-button ${
                                expandedPatient === patient.id ? 'expanded' : ''
                              }`}
                              onClick={() => toggleExpand(patient.id)}
                              aria-label="Toggle Details"
                              style={{ padding: '4px 8px' }}
                            >
                              <ChevronDown size={18} />
                            </Button>
                          </td>
                        </tr>
                        {expandedPatient === patient.id && (
                          <tr className="bg-light" style={{ textAlign: 'left' }}>
                            <td
                              colSpan={8}
                              style={{
                                borderBottom: '1px solid rgba(222,226,230,0.7)',
                                padding: '12px 8px',
                                backgroundColor: '#f8f9fa',
                              }}
                            >
                              <Container fluid className="py-3">
                                <Row>
                                  <Col md={4}>
                                    <h5 style={{ fontSize: '1rem' }}>Patient Information</h5>
                                    <p style={{ fontSize: '0.85rem' }}>
                                      <strong>Name:</strong> {patient.user.first_name}{' '}
                                      {patient.user.last_name}
                                    </p>
                                    <p style={{ fontSize: '0.85rem' }}>
                                      <strong>Email:</strong> {patient.user.email}
                                    </p>
                                    <p style={{ fontSize: '0.85rem' }}>
                                      <strong>Phone:</strong> {patient.phone}
                                    </p>
                                  </Col>
                                  <Col md={4}>
                                    <h5 style={{ fontSize: '1rem' }}>Additional Details</h5>
                                    <p style={{ fontSize: '0.85rem' }}>
                                      <strong>Country:</strong> {patient.country}
                                    </p>
                                    <p style={{ fontSize: '0.85rem' }}>
                                      <strong>Date of Birth:</strong> {patient.dob}
                                    </p>
                                    <p style={{ fontSize: '0.85rem' }}>
                                      <strong>Gender:</strong> {patient.gender}
                                    </p>
                                  </Col>
                                  <Col md={4}>
                                    <h5 style={{ fontSize: '1rem' }}>Account Details</h5>
                                    <p style={{ fontSize: '0.85rem' }}>
                                      <strong>Registered On:</strong> {patient.registered_on}
                                    </p>
                                    {/* Add more details as needed */}
                                  </Col>
                                </Row>
                              </Container>
                            </td>
                          </tr>
                        )}
                      </Fragment>
                    ))}
                    {filteredPatients.length === 0 && (
                      <tr>
                        <td
                          colSpan={8}
                          className="text-center p-3"
                          style={{ fontSize: '0.85rem' }}
                        >
                          No patients found matching the criteria.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPatientDashboard;
