// src/slices/notificationSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid'; // Install uuid package if not already


// Initial state for the notification slice
const initialState = {
  chatMessages: {},       // Keyed by roomName, holds arrays of messages
  callNotifications: [],  // Array of call notifications
  signalingMessages: {},  // Keyed by roomName, holds arrays of signaling messages
  userStatuses: {},       // Keyed by userId, holds user status information
  errors: [],             // Array of error objects
  selectedUser: null,     // Currently selected user for chat/call
  currentRoom: null,      // Current active chat room name
  users: {},              // Centralized user details, keyed by userId

  currentCall: null,      // The call currently being handled
  isInCall: false,        // Whether the user is currently in a call
  activeRoomName: '',     // The room name associated with the current call
  callType: null,         // 'audio' or 'video'
  callQueue: [],          // Queue of incoming call notifications
  outgoingCall: null,     // Details of an outgoing call, if any
  isInitiator: false,     // Whether we're the one who started the call
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    // ------------------------------
    // Chat Message Actions
    // ------------------------------
    receiveMessage: (state, action) => {
      const {
        roomName,
        id,
        sender,
        senderFullName,
        content,
        files,
        timestamp,
        isCurrentUser,
        isNew,
      } = action.payload;

      if (!state.chatMessages[roomName]) {
        state.chatMessages[roomName] = [];
      }

      // Ensure 'id' is a string before calling 'startsWith'
      if (typeof id !== 'string') {
        console.warn(
          `receiveMessage: Expected 'id' to be a string, but received type '${typeof id}'. ID value:`,
          id
        );
        // Optionally convert 'id' to a string or handle the message differently
        // For now, skip processing this message
        return;
      }

      // Handle "temp-" ID replacement
      if (id.startsWith('temp-')) {
        const tempIndex = state.chatMessages[roomName].findIndex(
          (msg) => msg.id === id
        );
        if (tempIndex !== -1) {
          state.chatMessages[roomName][tempIndex] = {
            id,
            sender,
            senderFullName,
            content,
            files,
            timestamp,
            isCurrentUser,
            isNew,
          };
          return;
        }
      }

      // Otherwise, just push the new message
      state.chatMessages[roomName].push({
        id,
        sender,
        senderFullName,
        content,
        files,
        timestamp,
        isCurrentUser,
        isNew,
      });

      // Store user details if not already present
      if (sender && !state.users[sender]) {
        state.users[sender] = {
          id: sender,
          fullName: senderFullName || 'Unknown User',
        };
      }
    },

    prependMessages: (state, action) => {
      const { roomName, messages } = action.payload;
      if (!state.chatMessages[roomName]) {
        state.chatMessages[roomName] = [];
      }
      state.chatMessages[roomName] = [
        ...messages,
        ...state.chatMessages[roomName],
      ];
    },

    // ------------------------------
    // Call Notification Actions
    // ------------------------------
   // In your notificationSlice.js

   initiateOutgoingCall: (state, action) => {
    const { toUserId, callType, roomName } = action.payload;
    // Prevent initiating a call if already in a call
    if (state.isInCall) {
      console.warn('Already in a call. Ignoring initiateOutgoingCall.');
      return;
    }
    state.outgoingCall = { toUserId, callType, roomName };
    state.isInCall = true;
    state.isInitiator = true;
    state.activeRoomName = roomName;
    state.callType = callType;
  },
  

    /**
     * End a call and clear all relevant states/notifications.
     * If you pass a `roomName` in the payload, we also remove any
     * callNotifications associated with that room.
     *
     * Payload shape (optional):
     * {
     *   roomName: string
     * }
     */
    endCall: (state, action) => {
      const roomName = action.payload?.roomName;

      // Remove call notifications for the given room
      if (roomName) {
        state.callNotifications = state.callNotifications.filter(
          (call) => call.roomName !== roomName
        );
      }

      // Reset all call-related state
      state.currentCall = null;
      state.outgoingCall = null;
      state.isInCall = false;
      state.activeRoomName = '';
      state.callType = null;
      state.isInitiator = false;
      state.callQueue = [];
    },
    clearSignalingMessages: (state, action) => {
      const roomName = action.payload?.roomName;
      if (roomName) {
        state.signalingMessages[roomName] = [];
      } else {
        state.signalingMessages = {};
      }
    },
    

    receiveCallNotification: (state, action) => {
      state.callNotifications.push(action.payload);
      state.callQueue.push(action.payload);
    },

    // ------------------------------
    // Signaling Message Actions
    // ------------------------------
    receiveSignalingMessage: (state, action) => {
      const { signalType, fromId, roomName, offer, answer, candidate } = action.payload;
      if (!state.signalingMessages[roomName]) {
        state.signalingMessages[roomName] = [];
      }
      state.signalingMessages[roomName].push({
        id: uuidv4(), // Assign a unique ID
        signalType,
        fromId,
        roomName,
        offer,
        answer,
        candidate,
        timestamp: new Date().toISOString(),
      });
    },
    
    

    // ------------------------------
    // User Status Actions
    // ------------------------------
    updateUserStatus: (state, action) => {
      const { userId, userFullName, status } = action.payload;
      state.userStatuses[userId] = {
        userFullName,
        status,
      };

      if (userId && !state.users[userId]) {
        state.users[userId] = {
          id: userId,
          fullName: userFullName || 'Unknown User',
        };
      }
    },

    // ------------------------------
    // Message Status Actions
    // ------------------------------
    updateMessageStatus: (state, action) => {
      const { message_id, status } = action.payload;
      Object.keys(state.chatMessages).forEach((roomName) => {
        const messages = state.chatMessages[roomName];
        const message = messages.find((msg) => msg.id === message_id);
        if (message) {
          message.status = status;
        }
      });
    },

    // ------------------------------
    // Error Handling
    // ------------------------------
    receiveError: (state, action) => {
      state.errors.push(action.payload);
    },

    // ------------------------------
    // Selected User Actions
    // ------------------------------
    setSelectedUser: (state, action) => {
      // Expect nested user shape: { user: { id, first_name, last_name, ... } }
      state.selectedUser = action.payload;
    },

    clearSelectedUser: (state) => {
      state.selectedUser = null;
    },

    // ------------------------------
    // Current Room Actions
    // ------------------------------
    setCurrentRoom: (state, action) => {
      state.currentRoom = action.payload;
    },

    clearCurrentRoom: (state) => {
      state.currentRoom = null;
    },

    // ------------------------------
    // Users Management
    // ------------------------------
    addOrUpdateUser: (state, action) => {
      const { userId, fullName, userType, entityId } = action.payload;
      if (userId) {
        state.users[userId] = {
          id: userId,
          fullName: fullName || state.users[userId]?.fullName || 'Unknown User',
          userType, // 'doctor' or 'patient'
          ...(userType === 'doctor'
            ? { doctorId: entityId }
            : { patientId: entityId }),
        };
      }
    },

    addOrUpdateUsers: (state, action) => {
      const users = action.payload; // Array of user objects
      users.forEach(({ userId, fullName, role, roleId }) => {
        if (userId) {
          state.users[userId] = {
            id: userId,
            fullName: fullName || state.users[userId]?.fullName || 'Unknown User',
            role: role || state.users[userId]?.role || 'unknown',
            ...(role === 'doctor' && { doctorId: roleId }),
            ...(role === 'patient' && { patientId: roleId }),
          };
        }
      });
    },

    // ------------------------------
    // Call Accepted (IMPORTANT)
    // ------------------------------
    receiveCallAccepted: (state, action) => {
      const { fromUserId, fromFullName, roomName } = action.payload;
     
      /**
       * If we are the CALLER (i.e., we initiated an outgoing call), then
       * we expect fromUserId === outgoingCall.toUserId. 
       */
      if (state.outgoingCall) {
        if (state.outgoingCall.toUserId === fromUserId) {
          // This means the user we called is accepting
          state.isInCall = true;
          state.activeRoomName = roomName;
          state.callType = state.outgoingCall.callType;
          state.isInitiator = true; // Caller is initiator

          // If selectedUser not set or mismatched, update it
          if (
            !state.selectedUser ||
            !state.selectedUser.user ||
            state.selectedUser.user.id !== fromUserId
          ) {
            // Here we do a simple split for first/last name if you'd like:
            const [firstName, ...rest] = (fromFullName || 'Unknown User').split(' ');
            const lastName = rest.join(' ');

            state.selectedUser = {
              user: {
                id: fromUserId,
                first_name: firstName || 'Unknown',
                last_name: lastName || '',
              },
            };
          }
        } else {
          console.warn('Received call acceptance from unexpected user');
        }
      } else {
        /**
         * We are the CALLEE. 
         * There's no `outgoingCall`, so the "fromUserId" is the caller. 
         * If you want to update isInCall for the callee, do it here:
         */
        state.isInCall = true;
        state.activeRoomName = roomName;
        // Optionally set callType or isInitiator = false, etc.
        // Possibly also set selectedUser if needed:
        if (!state.selectedUser) {
          const [firstName, ...rest] = (fromFullName || 'Unknown User').split(' ');
          const lastName = rest.join(' ');

          state.selectedUser = {
            user: {
              id: fromUserId,
              first_name: firstName || 'Unknown',
              last_name: lastName || '',
            },
          };
        }
      }
    },

    // ------------------------------
    // Message Notification Actions
    // ------------------------------
    markMessageAsNotNew: (state, action) => {
      const { roomName, messageId } = action.payload;
      const roomMessages = state.chatMessages[roomName];
      if (roomMessages) {
        const message = roomMessages.find((msg) => msg.id === messageId);
        if (message) {
          message.isNew = false;
        }
      }
    },

    // ------------------------------
    // Clear Actions
    // ------------------------------
    clearChatMessages: (state, action) => {
      const { roomName } = action.payload;
      if (roomName) {
        state.chatMessages[roomName] = [];
      } else {
        state.chatMessages = {};
      }
    },

    clearCallNotifications: (state) => {
      state.callNotifications = [];
    },

   

    clearErrors: (state) => {
      state.errors = [];
    },

    // ------------------------------
    // Incoming Call Queue Actions
    // ------------------------------
    enqueueIncomingCall: (state, action) => {
      state.callQueue.push(action.payload);
    },

    dequeueIncomingCall: (state) => {
      state.currentCall = state.callQueue.shift() || null;
      if (state.currentCall) {
        state.activeRoomName = state.currentCall.roomName;
        state.callType = state.currentCall.callType;
      }
    },

    clearIncomingCalls: (state) => {
      state.callNotifications = [];
      state.callQueue = [];
    },

    acceptIncomingCall: (state) => {
      if (state.currentCall) {
        state.isInCall = true;
        state.isInitiator = false; // The callee is not the initiator
      }
    },

    declineIncomingCall: (state) => {
      if (state.currentCall) {
        state.currentCall = null;
        state.isInCall = false;
        state.activeRoomName = '';
        state.callType = null;
      }
    },

    handleSignalingMessage: (state, action) => {
      state.signalingMessages.push(action.payload);
    },
  },
});

//
// Export the actions
//
export const {
  // Chat
  receiveMessage,
  prependMessages,

  // Calls
  initiateOutgoingCall,
  receiveCallNotification,
  endCall,

  // Signaling
  receiveSignalingMessage,

  // User Status
  updateUserStatus,

  // Message Status
  updateMessageStatus,

  // Errors
  receiveError,
  clearErrors,

  // Selected User
  setSelectedUser,
  clearSelectedUser,

  // Current Room
  setCurrentRoom,
  clearCurrentRoom,

  // Users
  addOrUpdateUser,
  addOrUpdateUsers,

  // Message Notifications
  markMessageAsNotNew,

  // Clearing utilities
  clearChatMessages,
  clearCallNotifications,
  clearSignalingMessages,
 
  // Accepting calls
  receiveCallAccepted,
  enqueueIncomingCall,
  dequeueIncomingCall,
  clearIncomingCalls,
  acceptIncomingCall,
  declineIncomingCall,
  handleSignalingMessage,
} = notificationSlice.actions;

//
// Export the reducer
//
export default notificationSlice.reducer;
