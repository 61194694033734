// VideoCallManager.js
import React, { useCallback } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  endCall,
} from "./slices/notificationSlice";
import webSocketManager from "./services/WebSocketManager";

import VideoCall from "./Components/VideoCall";

function VideoCallManager() {
  const dispatch = useDispatch();

  const {
    isInCall,
    activeRoomName,
    selectedUser,
    callType,
    isInitiator,
    currentUser,
  } = useSelector(
    (state) => ({
      isInCall: state.notifications.isInCall,
      activeRoomName: state.notifications.activeRoomName,
      selectedUser: state.notifications.selectedUser,
      callType: state.notifications.callType,
      isInitiator: state.notifications.isInitiator,
      currentUser: state.user.userData || state.doctor.doctorData,
    }),
    shallowEqual
  );

  const handleEndCall = useCallback(() => {
    // Notify remote side
    if (selectedUser?.user?.id && activeRoomName) {
      webSocketManager.endCall(selectedUser.user.id, activeRoomName);
    }
    // Clear everything in Redux
    dispatch(endCall({ roomName: activeRoomName }));
  }, [activeRoomName, selectedUser, dispatch]);

  // Only render <VideoCall> if we are currently in a call
  if (!isInCall || !activeRoomName || !selectedUser || !currentUser) {
    return null;
  }

  return (
    <VideoCall
      selectedUser={selectedUser}
      currentUser={currentUser}
      roomName={activeRoomName}
      callType={callType}
      isInitiator={isInitiator}
      handleEndCall={handleEndCall}
    />
  );
}

export default VideoCallManager;
