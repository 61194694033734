// src/slices/notificationSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid'; // Install uuid if needed: npm install uuid

const initialState = {
  chatMessages: {}, // Keyed by roomName -> array of messages
  callNotifications: [],
  signalingMessages: {}, 
  userStatuses: {},
  errors: [],
  selectedUser: null,
  currentRoom: null,
  users: {},

  currentCall: null,
  isInCall: false,
  activeRoomName: '',
  callType: null,
  callQueue: [],
  outgoingCall: null,
  isInitiator: false,
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {

    // ----------------------------------------------------------------
    // NEW ACTION: addOptimisticMessage
    // ----------------------------------------------------------------
    /**
     * Adds a new message to the store with a "temp-" ID. 
     * This message is from the current user, so set isCurrentUser = true.
     * The `receiveMessage` reducer will later replace/merge this
     * when the server echoes back the real message with a permanent ID.
     */
    addOptimisticMessage: {
      reducer: (state, action) => {
        const {
          roomName,
          id,             // temp-<UUID>
          sender,
          senderFullName,
          content,
          files,
          timestamp,
          isCurrentUser,
        } = action.payload;

        if (!state.chatMessages[roomName]) {
          state.chatMessages[roomName] = [];
        }
        state.chatMessages[roomName].push({
          id,
          sender,
          senderFullName,
          content,
          files,
          timestamp,
          isCurrentUser,
        });
      },
      prepare: (roomName, senderId, senderFullName, content, files) => {
        const tempId = `temp-${uuidv4()}`;
        return {
          payload: {
            roomName,
            id: tempId,
            sender: senderId,
            senderFullName: senderFullName || 'Me',
            content: content || '',
            files: files || [],
            timestamp: new Date().toISOString(),
            isCurrentUser: true,
          },
        };
      },
    },

    // ----------------------------------------------------------------
    // Existing chat message actions
    // ----------------------------------------------------------------
    receiveMessage: (state, action) => {
      const {
        roomName,
        id,
        sender,
        senderFullName,
        content,
        files,
        timestamp,
        isCurrentUser,
        isNew,
      } = action.payload;

      if (!state.chatMessages[roomName]) {
        state.chatMessages[roomName] = [];
      }

      // Safety check on ID
      if (typeof id !== 'string') {
        console.warn(
          `receiveMessage: Expected 'id' to be a string, but received type '${typeof id}'`,
          id
        );
        return;
      }

      // If server's echo ID is the "temp-" ID we used, replace the local message
      // with the server-confirmed details
      if (id.startsWith('temp-')) {
        const tempIndex = state.chatMessages[roomName].findIndex(
          (msg) => msg.id === id
        );
        if (tempIndex !== -1) {
          state.chatMessages[roomName][tempIndex] = {
            id,
            sender,
            senderFullName,
            content,
            files,
            timestamp,
            isCurrentUser,
            isNew,
          };
          return;
        }
      }

      // Otherwise, just push the new message to the array
      state.chatMessages[roomName].push({
        id,
        sender,
        senderFullName,
        content,
        files,
        timestamp,
        isCurrentUser,
        isNew,
      });

      // Store user details if not already present
      if (sender && !state.users[sender]) {
        state.users[sender] = {
          id: sender,
          fullName: senderFullName || 'Unknown User',
        };
      }
    },

    prependMessages: (state, action) => {
      const { roomName, messages } = action.payload;
      if (!state.chatMessages[roomName]) {
        state.chatMessages[roomName] = [];
      }
      state.chatMessages[roomName] = [...messages, ...state.chatMessages[roomName]];
    },

    // ----------------------------------------------------------------
    // Call actions
    // ----------------------------------------------------------------
    initiateOutgoingCall: (state, action) => {
      const { toUserId, callType, roomName } = action.payload;
      // Prevent initiating a call if already in a call
      if (state.isInCall) {
        console.warn('Already in a call. Ignoring initiateOutgoingCall.');
        return;
      }
      state.outgoingCall = { toUserId, callType, roomName };
      state.isInCall = true;
      state.isInitiator = true;
      state.activeRoomName = roomName;
      state.callType = callType;
    },

    endCall: (state, action) => {
      const roomName = action.payload?.roomName;
      if (roomName) {
        state.callNotifications = state.callNotifications.filter(
          (call) => call.roomName !== roomName
        );
      }
      state.currentCall = null;
      state.outgoingCall = null;
      state.isInCall = false;
      state.activeRoomName = '';
      state.callType = null;
      state.isInitiator = false;
      state.callQueue = [];
    },

    receiveCallNotification: (state, action) => {
      state.callNotifications.push(action.payload);
      state.callQueue.push(action.payload);
    },

    // ----------------------------------------------------------------
    // Signaling 
    // ----------------------------------------------------------------
    receiveSignalingMessage: (state, action) => {
      const { signalType, fromId, roomName, offer, answer, candidate } = action.payload;
      if (!state.signalingMessages[roomName]) {
        state.signalingMessages[roomName] = [];
      }
      state.signalingMessages[roomName].push({
        id: uuidv4(),
        signalType,
        fromId,
        roomName,
        offer,
        answer,
        candidate,
        timestamp: new Date().toISOString(),
      });
    },

    clearSignalingMessages: (state, action) => {
      const roomName = action.payload?.roomName;
      if (roomName) {
        state.signalingMessages[roomName] = [];
      } else {
        state.signalingMessages = {};
      }
    },

    // ----------------------------------------------------------------
    // User Status
    // ----------------------------------------------------------------
    updateUserStatus: (state, action) => {
      const { userId, userFullName, status } = action.payload;
      state.userStatuses[userId] = {
        userFullName,
        status,
      };
      if (userId && !state.users[userId]) {
        state.users[userId] = {
          id: userId,
          fullName: userFullName || 'Unknown User',
        };
      }
    },

    // ----------------------------------------------------------------
    // Message Status 
    // ----------------------------------------------------------------
    updateMessageStatus: (state, action) => {
      const { message_id, status } = action.payload;
      Object.keys(state.chatMessages).forEach((roomName) => {
        const messages = state.chatMessages[roomName];
        const message = messages.find((msg) => msg.id === message_id);
        if (message) {
          message.status = status;
        }
      });
    },

    // ----------------------------------------------------------------
    // Errors
    // ----------------------------------------------------------------
    receiveError: (state, action) => {
      state.errors.push(action.payload);
    },
    clearErrors: (state) => {
      state.errors = [];
    },

    // ----------------------------------------------------------------
    // Selected User
    // ----------------------------------------------------------------
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    clearSelectedUser: (state) => {
      state.selectedUser = null;
    },

    // ----------------------------------------------------------------
    // Current Room
    // ----------------------------------------------------------------
    setCurrentRoom: (state, action) => {
      state.currentRoom = action.payload;
    },
    clearCurrentRoom: (state) => {
      state.currentRoom = null;
    },

    // ----------------------------------------------------------------
    // Users
    // ----------------------------------------------------------------
    addOrUpdateUser: (state, action) => {
      const { userId, fullName, userType, entityId } = action.payload;
      if (userId) {
        state.users[userId] = {
          id: userId,
          fullName: fullName || state.users[userId]?.fullName || 'Unknown User',
          userType,
          ...(userType === 'doctor'
            ? { doctorId: entityId }
            : { patientId: entityId }),
        };
      }
    },

    addOrUpdateUsers: (state, action) => {
      const users = action.payload; // Array of user objects
      users.forEach(({ userId, fullName, role, roleId }) => {
        if (userId) {
          state.users[userId] = {
            id: userId,
            fullName: fullName || state.users[userId]?.fullName || 'Unknown User',
            role: role || state.users[userId]?.role || 'unknown',
            ...(role === 'doctor' && { doctorId: roleId }),
            ...(role === 'patient' && { patientId: roleId }),
          };
        }
      });
    },

    // ----------------------------------------------------------------
    // Call Accept/Decline
    // ----------------------------------------------------------------
    receiveCallAccepted: (state, action) => {
      const { fromUserId, fromFullName, roomName } = action.payload;
      // If we are the caller
      if (state.outgoingCall) {
        if (state.outgoingCall.toUserId === fromUserId) {
          state.isInCall = true;
          state.activeRoomName = roomName;
          state.callType = state.outgoingCall.callType;
          state.isInitiator = true;

          if (
            !state.selectedUser ||
            !state.selectedUser.user ||
            state.selectedUser.user.id !== fromUserId
          ) {
            const [firstName, ...rest] = (fromFullName || 'Unknown User').split(' ');
            const lastName = rest.join(' ');
            state.selectedUser = {
              user: {
                id: fromUserId,
                first_name: firstName || 'Unknown',
                last_name: lastName || '',
              },
            };
          }
        } else {
          console.warn('Received call acceptance from unexpected user');
        }
      } else {
        // We are the callee
        state.isInCall = true;
        state.activeRoomName = roomName;
        // Possibly set selectedUser if needed
        if (!state.selectedUser) {
          const [firstName, ...rest] = (fromFullName || 'Unknown User').split(' ');
          const lastName = rest.join(' ');
          state.selectedUser = {
            user: {
              id: fromUserId,
              first_name: firstName || 'Unknown',
              last_name: lastName || '',
            },
          };
        }
      }
    },

    // ----------------------------------------------------------------
    // Message Notification
    // ----------------------------------------------------------------
    markMessageAsNotNew: (state, action) => {
      const { roomName, messageId } = action.payload;
      const roomMessages = state.chatMessages[roomName];
      if (roomMessages) {
        const message = roomMessages.find((msg) => msg.id === messageId);
        if (message) {
          message.isNew = false;
        }
      }
    },

    // ----------------------------------------------------------------
    // Clear Chat
    // ----------------------------------------------------------------
    clearChatMessages: (state, action) => {
      const { roomName } = action.payload;
      if (roomName) {
        state.chatMessages[roomName] = [];
      } else {
        state.chatMessages = {};
      }
    },

    clearCallNotifications: (state) => {
      state.callNotifications = [];
    },

    // ----------------------------------------------------------------
    // Incoming Call Queue
    // ----------------------------------------------------------------
    enqueueIncomingCall: (state, action) => {
      state.callQueue.push(action.payload);
    },

    dequeueIncomingCall: (state) => {
      state.currentCall = state.callQueue.shift() || null;
      if (state.currentCall) {
        state.activeRoomName = state.currentCall.roomName;
        state.callType = state.currentCall.callType;
      }
    },

    clearIncomingCalls: (state) => {
      state.callNotifications = [];
      state.callQueue = [];
    },

    acceptIncomingCall: (state) => {
      if (state.currentCall) {
        state.isInCall = true;
        state.isInitiator = false; // The callee is not the initiator
      }
    },

    declineIncomingCall: (state) => {
      if (state.currentCall) {
        state.currentCall = null;
        state.isInCall = false;
        state.activeRoomName = '';
        state.callType = null;
      }
    },

    handleSignalingMessage: (state, action) => {
      state.signalingMessages.push(action.payload);
    },
  },
});

export const {
  // NEW ACTION
  addOptimisticMessage,

  // Chat
  receiveMessage,
  prependMessages,

  // Calls
  initiateOutgoingCall,
  receiveCallNotification,
  endCall,

  // Signaling
  receiveSignalingMessage,
  clearSignalingMessages,

  // Status
  updateUserStatus,
  updateMessageStatus,

  // Errors
  receiveError,
  clearErrors,

  // Selected User
  setSelectedUser,
  clearSelectedUser,

  // Rooms
  setCurrentRoom,
  clearCurrentRoom,

  // User Management
  addOrUpdateUser,
  addOrUpdateUsers,

  // Message Notifications
  markMessageAsNotNew,

  // Clears
  clearChatMessages,
  clearCallNotifications,

  // Call Accept
  receiveCallAccepted,

  // Call Queue
  enqueueIncomingCall,
  dequeueIncomingCall,
  clearIncomingCalls,
  acceptIncomingCall,
  declineIncomingCall,
  handleSignalingMessage,
} = notificationSlice.actions;

export default notificationSlice.reducer;
