import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPatientDetails } from "../slices/patientSlice";
import { fetchDoctorAppointments } from "../slices/doctorSlice"; // Existing import
import { useNavigate } from "react-router-dom";
import { FilePenLine, Trash2, ChevronRight, Clock, Calendar } from "lucide-react";
import "../CSS/Timeline.css";

// Define status options with display labels and corresponding backend values
const statusOptions = [
  { label: "All", value: "ALL" },          // "ALL" indicates no filtering
  { label: "Pending", value: "Confirmed" },  // Adjust these values based on your backend
  { label: "Completed", value: "Completed" },
  { label: "Cancelled", value: "cancelled" },
];

// Helper function to calculate age from DOB
const calculateAge = (dob) => {
  if (!dob) return "N/A";

  try {
    const birthDate = new Date(dob);
    const today = new Date();

    if (isNaN(birthDate.getTime())) {
      return "N/A";
    }

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  } catch (error) {
    console.error("Error calculating age:", error);
    return "N/A";
  }
};

// Helper function to format date as "8 December 2024"
const formatDate = (dateString) => {
  if (!dateString) return "N/A";

  try {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return "N/A";
    }

    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    return date.toLocaleDateString("en-US", options); // "8 December 2024"
  } catch (error) {
    console.error("Error formatting date:", error);
    return "N/A";
  }
};

// Helper function to format time as a duration, e.g., "4:30-5:00 PM"
const formatDuration = (timeString) => {
  if (!timeString) return "N/A";

  try {
    const startDate = new Date(timeString);

    if (isNaN(startDate.getTime())) {
      return "N/A";
    }

    const endDate = new Date(startDate.getTime() + 30 * 60000); // Add 30 minutes

    const options = {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    const startStr = startDate.toLocaleTimeString("en-US", options);
    const endStr = endDate.toLocaleTimeString("en-US", options);

    // Extract the period (AM/PM) from the start time
    const period = startStr.split(" ")[1];

    // Remove the period from endStr if it exists
    const endTime = endStr.replace(/ AM| PM/, "");

    return `${startStr.split(" ")[0]}-${endTime} ${period}`;
  } catch (error) {
    console.error("Error formatting duration:", error);
    return "N/A";
  }
};

// Enhanced truncateText function to ensure total length including '...' is 50
const truncateText = (text, maxLength) => {
  if (!text) return "N/A";
  const ellipsis = "...";
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength - ellipsis.length)}${ellipsis}`;
  }
  return text;
};

const TimelineItem = ({ appointment, onPatientClick, index }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const age = calculateAge(appointment.patient?.dob);
  const formattedDate = formatDate(appointment.appointment_time);
  const duration = formatDuration(appointment.appointment_time);

  const colorClasses = [
    "border-purple",
    "border-orange",
    "border-blue",
    "border-green",
  ];
  const colorClass = colorClasses[index % colorClasses.length];

  // Determine text color based on border color for better contrast
  const textColorClass = `text-${colorClass.split('-')[1]}`;

  // Get the first letter of the patient's first name
  const getInitial = () => {
    const firstName = appointment.patient?.user?.first_name || "";
    return firstName.charAt(0).toUpperCase();
  };

  return (
    <div className={`timeline-card ${colorClass}`}>
      <div className="timeline-card-main" onClick={() => setIsExpanded(!isExpanded)}>
        {/* Left Section */}
        <div className="timeline-card-left">
          <div className="problem-description-doctor">
            {truncateText(appointment.problem_description, 50)}
          </div>
          <div className={`status ${colorClass.split('-')[1]}`}>
            {/* Status Dot */}
            <span className="status-dot"></span>
            {appointment.status || "Pending"}
          </div>
        </div>

        {/* Right Section */}
        <div className="date-time">
          <div className="date">
            <Calendar size={18} className="icon" />
            {formattedDate}
          </div>
          <div className="time">
            <Clock size={18} className="icon" />
            {duration}
          </div>
        </div>
        <div className="patient-info-big-box">
        <div className="patient-info">
          {/* Avatar Circle with Initial */}
          <div className={`avatar ${colorClass.split('-')[1]}`}>
            {getInitial()}
          </div>
          <div className="patient-info-box">
            <div className="patient-name">
              {appointment.patient?.user?.first_name || "N/A"}{" "}
              {appointment.patient?.user?.last_name || ""}
            </div>
            <span className="gender">{appointment.patient?.gender || "N/A"}</span>
          </div>
          
        </div>
        <i
            className="fa-solid fa-arrow-right-long fa-2x"
            onClick={(e) => {
              e.stopPropagation();
              onPatientClick(appointment.patient?.id);
            }}
          ></i>
          </div>
      </div>

      {/* Expanded Details */}
      {isExpanded && (
        <div className="timeline-card-details">
          <div className="timeline-card-row">
            <span className="label">Age:</span>
            <span className="value">{age}</span>
          </div>
          <div className="timeline-card-row">
            <span className="label">Country:</span>
            <span className="value">{appointment.patient?.country || "N/A"}</span>
          </div>
          <div className="timeline-card-row">
            <span className="label">Gender:</span>
            <span className="value">{appointment.patient?.gender || "N/A"}</span>
          </div>
          <div className="timeline-card-actions">
            <button
              className="action-button edit-button"
              onClick={(e) => {
                e.stopPropagation();
                onPatientClick(appointment.patient?.id);
              }}
              aria-label="Edit Appointment"
            >
              <FilePenLine size={16} />
              <span>Edit</span>
            </button>
            <button
              className="action-button delete-button"
              onClick={(e) => {
                e.stopPropagation();
                // Add delete functionality here
              }}
              aria-label="Delete Appointment"
            >
              <Trash2 size={16} />
              <span>Delete</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const Timeline = ({ selectedDate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appointments = useSelector((state) => state.doctor.appointments);
  const status = useSelector((state) => state.doctor.status);
  const error = useSelector((state) => state.doctor.error);

  // State for the selected filter
  const [filter, setFilter] = useState("ALL"); // Initialize with "ALL"

  useEffect(() => {
    // Fetch all appointments for the selected date
    dispatch(fetchDoctorAppointments(selectedDate));
  }, [selectedDate, dispatch]);

  const handlePatientClick = (patientId) => {
    dispatch(fetchPatientDetails(patientId)).then(() => {
      navigate(`/doctors/chat/${patientId}`);
    });
  };

  // Handle filter button click
  const handleFilterClick = (statusValue) => {
    setFilter(statusValue);
  };

  // Filter appointments based on the selected filter
  const filteredAppointments = appointments.filter((appointment) => {
    if (filter === "ALL") return true; // Show all appointments
    return appointment.status.toLowerCase() === filter.toLowerCase(); // Adjust if backend values are case-sensitive
  });

  return (
    <div className="timeline-container">
      <div className="timeline-header">
        <h2>Latest Patients Data</h2>
      </div>

      {/* Filter Section */}
      <div className="timeline-filter">
        {statusOptions.map((statusOption) => (
          <button
            key={statusOption.value}
            className={`filter-button ${
              filter === statusOption.value ? "active" : ""
            }`}
            onClick={() => handleFilterClick(statusOption.value)}
            aria-pressed={filter === statusOption.value}
          >
            {statusOption.label}
          </button>
        ))}
      </div>

      <div className="timeline-box">
        {status === "loading" ? (
          <div className="timeline-loading">Loading appointments...</div>
        ) : status === "failed" ? (
          <div className="timeline-error">
            Error: {typeof error === "string" ? error : error.detail || "An error occurred"}
          </div>
        ) : filteredAppointments.length === 0 ? (
          <div className="timeline-empty">
            <div className="doctors-empty-state">
                        <img
                          src="/static/banner/no-appointment.webp" // Replace with your preferred image path
                          alt="No Doctors"
                          className="empty-state-image"
                        />
                        <h3>No appointments</h3>
                        <p>{filter !== "ALL"
              ? `with status "${statusOptions.find(opt => opt.value === filter)?.label}" `
              : ""}
            scheduled for this date..</p>
                      
                      </div>
            
          </div>
        ) : (
          filteredAppointments.map((appointment, index) => (
            <TimelineItem
              key={appointment.id}
              appointment={appointment}
              onPatientClick={handlePatientClick}
              index={index}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default Timeline;
