// src/slices/userSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { addOrUpdateUser } from './notificationSlice'; // Import the action

// Async Thunks

// Send OTP during patient creation
export const sendOtp = createAsyncThunk(
  'user/sendOtp',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/create_patient/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Resend OTP
export const resendOtp = createAsyncThunk(
  'user/resendOtp',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/resend_otp/', { email });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Verify OTP
export const verifyOtp = createAsyncThunk(
  'user/verifyOtp',
  async ({ email, otp }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/verify_otp/', { email, otp });
      localStorage.setItem('userData', JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// User Login
export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/patient_login/', credentials);
      localStorage.setItem('userData', JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Fetch Appointments
export const fetchAppointments = createAsyncThunk(
  'user/fetchAppointments',
  async (_, { getState, rejectWithValue, dispatch }) => { // Destructure dispatch
    let userData = getState().user.userData;

    if (!userData) {
      const storedUserData = localStorage.getItem('userData');
      if (storedUserData) {
        userData = JSON.parse(storedUserData);
      }
    }

    if (!userData || !userData.access_token) {
      return rejectWithValue('User is not authenticated');
    }

    try {
      const response = await axios.get('/api/get_patient_appointments/', {
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      });

      const appointments = response.data;

      // Extract doctor information from appointments and dispatch addOrUpdateUser
      appointments.forEach((appointment) => {
        const doctor = appointment.doctor;
        if (doctor && doctor.user) {
          const { id: userId, first_name, last_name } = doctor.user;
          const fullName = `${first_name} ${last_name}`;
          const doctorId = doctor.id;

          dispatch(addOrUpdateUser({
            userId,
            fullName,
            userType: 'doctor',
            entityId: doctorId,
          }));
        }
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Request Password Reset
export const requestPasswordReset = createAsyncThunk(
  'user/requestPasswordReset',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/password_reset_request/', { email });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Verify Reset OTP
export const verifyResetOtp = createAsyncThunk(
  'user/verifyResetOtp',
  async ({ email, reset_token }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/password_reset_confirm/', { email, reset_token });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Reset Password
export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async ({ email, reset_token, new_password }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/password_reset_confirm/', {
        email,
        reset_token,
        new_password,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Update Patient Information
export const updatePatient = createAsyncThunk(
  'user/updatePatient',
  async (patientData, { rejectWithValue, getState }) => {
    const state = getState();
    const userData = state.user.userData;

    if (!userData || !userData.access_token) {
      return rejectWithValue('User is not authenticated');
    }

    try {
      const response = await axios.put('/api/update_patient/', patientData, {
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Add Vitals
export const addVitals = createAsyncThunk(
  'user/addVitals',
  async (vitalsData, { getState, rejectWithValue }) => {
    const state = getState();
    const userData = state.user.userData;

    if (!userData || !userData.access_token) {
      return rejectWithValue('User is not authenticated');
    }

    try {
      const response = await axios.post('/api/vitals/', vitalsData, {
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data; 
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Upload Medical Report
export const uploadMedicalReport = createAsyncThunk(
  'user/uploadMedicalReport',
  async ({ patient_id, fileData }, { rejectWithValue, getState }) => {
    const state = getState();
    const userData = state.user.userData;

    if (!userData || !userData.access_token) {
      return rejectWithValue('User is not authenticated');
    }

    try {
      // `fileData` should be FormData with { file: ... }
      const response = await axios.post(`/api/patients/${patient_id}/upload_medical_report/`, fileData, {
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data; // The response should contain the newly uploaded medical report
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Delete Medical Report
export const deleteMedicalReport = createAsyncThunk(
  'user/deleteMedicalReport',
  async ({ patient_id, report_id }, { rejectWithValue, getState }) => {
    const state = getState();
    const userData = state.user.userData;

    if (!userData || !userData.access_token) {
      return rejectWithValue('User is not authenticated');
    }

    try {
      await axios.delete(`/api/patients/${patient_id}/delete_medical_report/${report_id}/`, {
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      });
      return { report_id };
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Fetch Diagnoses
export const fetchDiagnoses = createAsyncThunk(
  'user/fetchDiagnoses',
  async (_, { getState, rejectWithValue }) => {
    let userData = getState().user.userData;

    if (!userData) {
      const storedUserData = localStorage.getItem('userData');
      if (storedUserData) {
        userData = JSON.parse(storedUserData);
      }
    }

    if (!userData || !userData.access_token) {
      return rejectWithValue('User is not authenticated');
    }

    try {
      const response = await axios.get('/api/get_patient_diagnoses/', {
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      });

      console.log(response.data)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: JSON.parse(localStorage.getItem('userData')) || null,
    sendOtpStatus: 'idle',
    sendOtpError: null,
    resendOtpStatus: 'idle',
    resendOtpError: null,
    verifyOtpStatus: 'idle',
    verifyOtpError: null,
    loginUserStatus: 'idle',
    loginUserError: null,
    fetchAppointmentsStatus: 'idle',
    fetchAppointmentsError: null,
    requestPasswordResetStatus: 'idle',
    requestPasswordResetError: null,
    verifyResetOtpStatus: 'idle',
    verifyResetOtpError: null,
    resetPasswordStatus: 'idle',
    resetPasswordError: null,
    updatePatientStatus: 'idle',
    updatePatientError: null,
    addVitalsStatus: 'idle',
    addVitalsError: null,
    uploadMedicalReportStatus: 'idle',
    uploadMedicalReportError: null,
    deleteMedicalReportStatus: 'idle',
    deleteMedicalReportError: null,
    appointments: [],
    fetchDiagnosesStatus: 'idle',
    fetchDiagnosesError: null,
    diagnoses: [],
  },
  reducers: {
    logout: (state) => {
      state.userData = null;
      localStorage.removeItem('userData');
      // Reset all statuses and errors
      state.sendOtpStatus = 'idle';
      state.sendOtpError = null;
      state.resendOtpStatus = 'idle';
      state.resendOtpError = null;
      state.verifyOtpStatus = 'idle';
      state.verifyOtpError = null;
      state.loginUserStatus = 'idle';
      state.loginUserError = null;
      state.fetchAppointmentsStatus = 'idle';
      state.fetchAppointmentsError = null;
      state.requestPasswordResetStatus = 'idle';
      state.requestPasswordResetError = null;
      state.verifyResetOtpStatus = 'idle';
      state.verifyResetOtpError = null;
      state.resetPasswordStatus = 'idle';
      state.resetPasswordError = null;
      state.updatePatientStatus = 'idle';
      state.updatePatientError = null;
      state.addVitalsStatus = 'idle';
      state.addVitalsError = null;
      state.uploadMedicalReportStatus = 'idle';
      state.uploadMedicalReportError = null;
      state.deleteMedicalReportStatus = 'idle';
      state.deleteMedicalReportError = null;
      state.appointments = [];
      state.fetchDiagnosesStatus = 'idle';
      state.fetchDiagnosesError = null;
      state.diagnoses = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Send OTP
      .addCase(sendOtp.pending, (state) => {
        state.sendOtpStatus = 'loading';
        state.sendOtpError = null;
      })
      .addCase(sendOtp.fulfilled, (state) => {
        state.sendOtpStatus = 'succeeded';
        state.sendOtpError = null;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.sendOtpStatus = 'failed';
        state.sendOtpError = action.payload;
      })

      // Resend OTP
      .addCase(resendOtp.pending, (state) => {
        state.resendOtpStatus = 'loading';
        state.resendOtpError = null;
      })
      .addCase(resendOtp.fulfilled, (state) => {
        state.resendOtpStatus = 'succeeded';
        state.resendOtpError = null;
      })
      .addCase(resendOtp.rejected, (state, action) => {
        state.resendOtpStatus = 'failed';
        state.resendOtpError = action.payload;
      })

      // Verify OTP
      .addCase(verifyOtp.pending, (state) => {
        state.verifyOtpStatus = 'loading';
        state.verifyOtpError = null;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.verifyOtpStatus = 'succeeded';
        state.userData = action.payload;
        state.verifyOtpError = null;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.verifyOtpStatus = 'failed';
        state.verifyOtpError = action.payload;
      })

      // Login User
      .addCase(loginUser.pending, (state) => {
        state.loginUserStatus = 'loading';
        state.loginUserError = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loginUserStatus = 'succeeded';
        state.userData = action.payload;
        state.loginUserError = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loginUserStatus = 'failed';
        state.loginUserError = action.payload;
      })

      // Fetch Appointments
      .addCase(fetchAppointments.pending, (state) => {
        state.fetchAppointmentsStatus = 'loading';
        state.fetchAppointmentsError = null;
      })
      .addCase(fetchAppointments.fulfilled, (state, action) => {
        state.fetchAppointmentsStatus = 'succeeded';
        state.appointments = action.payload;
      })
      .addCase(fetchAppointments.rejected, (state, action) => {
        state.fetchAppointmentsStatus = 'failed';
        state.fetchAppointmentsError = action.payload;
      })

      // Request Password Reset
      .addCase(requestPasswordReset.pending, (state) => {
        state.requestPasswordResetStatus = 'loading';
        state.requestPasswordResetError = null;
      })
      .addCase(requestPasswordReset.fulfilled, (state) => {
        state.requestPasswordResetStatus = 'succeeded';
      })
      .addCase(requestPasswordReset.rejected, (state, action) => {
        state.requestPasswordResetStatus = 'failed';
        state.requestPasswordResetError = action.payload;
      })

      // Verify Reset OTP
      .addCase(verifyResetOtp.pending, (state) => {
        state.verifyResetOtpStatus = 'loading';
        state.verifyResetOtpError = null;
      })
      .addCase(verifyResetOtp.fulfilled, (state, action) => {
        state.verifyResetOtpStatus = 'succeeded';
      })
      .addCase(verifyResetOtp.rejected, (state, action) => {
        state.verifyResetOtpStatus = 'failed';
        state.verifyResetOtpError = action.payload;
      })

      // Reset Password
      .addCase(resetPassword.pending, (state) => {
        state.resetPasswordStatus = 'loading';
        state.resetPasswordError = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.resetPasswordStatus = 'succeeded';
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.resetPasswordStatus = 'failed';
        state.resetPasswordError = action.payload;
      })

      // Update Patient
      .addCase(updatePatient.pending, (state) => {
        state.updatePatientStatus = 'loading';
        state.updatePatientError = null;
      })
      .addCase(updatePatient.fulfilled, (state, action) => {
        state.updatePatientStatus = 'succeeded';
        state.updatePatientError = null;
        // Update userData.patient
        if (state.userData && action.payload.patient) {
          state.userData.patient = action.payload.patient;
          localStorage.setItem('userData', JSON.stringify(state.userData));
        }
      })
      .addCase(updatePatient.rejected, (state, action) => {
        state.updatePatientStatus = 'failed';
        state.updatePatientError = action.payload;
      })

      // Add Vitals
      .addCase(addVitals.pending, (state) => {
        state.addVitalsStatus = 'loading';
        state.addVitalsError = null;
      })
      .addCase(addVitals.fulfilled, (state, action) => {
        state.addVitalsStatus = 'succeeded';
        if (state.userData && state.userData.patient) {
          state.userData.patient.vitals = [
            action.payload,
            ...(state.userData.patient.vitals || []),
          ];
          localStorage.setItem('userData', JSON.stringify(state.userData));
        }
      })
      .addCase(addVitals.rejected, (state, action) => {
        state.addVitalsStatus = 'failed';
        state.addVitalsError = action.payload;
      })

      // Upload Medical Report
      .addCase(uploadMedicalReport.pending, (state) => {
        state.uploadMedicalReportStatus = 'loading';
        state.uploadMedicalReportError = null;
      })
      .addCase(uploadMedicalReport.fulfilled, (state, action) => {
        state.uploadMedicalReportStatus = 'succeeded';
        if (state.userData && state.userData.patient) {
          state.userData.patient.medical_reports = [
            ...(state.userData.patient.medical_reports || []),
            action.payload,
          ];
          localStorage.setItem('userData', JSON.stringify(state.userData));
        }
      })
      .addCase(uploadMedicalReport.rejected, (state, action) => {
        state.uploadMedicalReportStatus = 'failed';
        state.uploadMedicalReportError = action.payload;
      })

      // Delete Medical Report
      .addCase(deleteMedicalReport.pending, (state) => {
        state.deleteMedicalReportStatus = 'loading';
        state.deleteMedicalReportError = null;
      })
      .addCase(deleteMedicalReport.fulfilled, (state, action) => {
        state.deleteMedicalReportStatus = 'succeeded';
        if (state.userData && state.userData.patient && state.userData.patient.medical_reports) {
          state.userData.patient.medical_reports = state.userData.patient.medical_reports.filter(
            (report) => report.id !== action.payload.report_id
          );
          localStorage.setItem('userData', JSON.stringify(state.userData));
        }
      })
      .addCase(deleteMedicalReport.rejected, (state, action) => {
        state.deleteMedicalReportStatus = 'failed';
        state.deleteMedicalReportError = action.payload;
      })
      .addCase(fetchDiagnoses.pending, (state) => {
        state.fetchDiagnosesStatus = 'loading';
        state.fetchDiagnosesError = null;
      })
      .addCase(fetchDiagnoses.fulfilled, (state, action) => {
        state.fetchDiagnosesStatus = 'succeeded';
        state.diagnoses = action.payload;
      })
      .addCase(fetchDiagnoses.rejected, (state, action) => {
        state.fetchDiagnosesStatus = 'failed';
        state.fetchDiagnosesError = action.payload;
      });
  },
});

export const { logout } = userSlice.actions;
export default userSlice.reducer;
