// patientSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Existing fetchPatientDetails action
export const fetchPatientDetails = createAsyncThunk(
  'patient/fetchPatientDetails',
  async (patientId, { getState, rejectWithValue }) => {
    try {
      const { doctorData } = getState().doctor;
      const access_token = doctorData?.access_token;    
      console.log(access_token)
      if (!access_token) {
        return rejectWithValue({ message: 'Access token is missing' });
      }
           
      const response = await axios.get(`/api/doctor/patient/${patientId}/`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else if (error.message) {
        return rejectWithValue({ message: error.message });
      } else {
        return rejectWithValue({ message: 'An unknown error occurred' });
      }
    }
  }
);

// New addDiagnosis action
export const addDiagnosis = createAsyncThunk(
  'patient/addDiagnosis',
  async ({ patientId, diagnosisData }, { getState, rejectWithValue }) => {
    try {
      const { doctorData } = getState().doctor;
      const access_token = doctorData?.access_token;

      if (!access_token) {
        return rejectWithValue({ message: 'Access token is missing' });
      }

      const formData = new FormData();
      formData.append('patient_id', patientId);
      formData.append('diagnosis_description', diagnosisData.diagnosis_description);
      formData.append('appointment_id', diagnosisData.appointment_id);
      formData.append('appointment_time', diagnosisData.appointment_time);
      if (diagnosisData.prescription) {
        formData.append('prescription', diagnosisData.prescription);
      }
      if (diagnosisData.digital_prescription) {
        formData.append('digital_prescription', diagnosisData.digital_prescription);
      }

      const response = await axios.post('/api/diagnosis/add/', formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data.diagnosis; // Assuming the API returns the diagnosis under 'diagnosis'
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else if (error.message) {
        return rejectWithValue({ message: error.message });
      } else {
        return rejectWithValue({ message: 'An unknown error occurred' });
      }
    }
  }
);

const patientSlice = createSlice({
  name: 'patient',
  initialState: {
    details: null,
    loading: false,
    error: null,
    addDiagnosisStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    addDiagnosisError: null,
  },
  reducers: {
    clearPatientDetails: (state) => {
      state.details = null;
      state.loading = false;
      state.error = null;
    },
    clearAddDiagnosisStatus: (state) => {
      state.addDiagnosisStatus = 'idle';
      state.addDiagnosisError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Existing cases for fetchPatientDetails
      .addCase(fetchPatientDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPatientDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
      })
      .addCase(fetchPatientDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || { message: 'Failed to fetch patient details' };
      })
      // New cases for addDiagnosis
      .addCase(addDiagnosis.pending, (state) => {
        state.addDiagnosisStatus = 'loading';
        state.addDiagnosisError = null;
      })
      .addCase(addDiagnosis.fulfilled, (state, action) => {
        state.addDiagnosisStatus = 'succeeded';
        // Optionally, you can update the patient details with the new diagnosis
        if (state.details) {
          state.details.diagnoses.push(action.payload);
        }
      })
      .addCase(addDiagnosis.rejected, (state, action) => {
        state.addDiagnosisStatus = 'failed';
        state.addDiagnosisError = action.payload || { message: 'Failed to add diagnosis' };
      });
  },
});

export const { clearPatientDetails, clearAddDiagnosisStatus } = patientSlice.actions;
export default patientSlice.reducer;
