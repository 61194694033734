// ChatApp.js

import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ChatList from '../Components/ChatList';
import ChatWindow from '../Components/ChatWindow';
import { useLocation, useNavigate } from 'react-router-dom';
import { generateRoomName } from '../utils'; // Import utility function
import webSocketManager from '../services/WebSocketManager';
import { setCurrentRoom, clearCurrentRoom } from '../slices/notificationSlice'; // Import actions
import UserSidebar from '../Components/UserSidebar';
import MobileHeader from '../Components/MobileHeader';


const ChatAppContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
  width:100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ChatAppInner = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;


const ChatListContainer = styled.div`
  width: 30%;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  
  /* Hide default scrollbar and add custom scrollbar for WebKit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* Background of the scrollbar track */
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2); /* Color of the scrollbar thumb */
    border-radius: 4px; /* Rounded corners */
    border: 2px solid transparent; /* Optional: Adds space around thumb */
    background-clip: padding-box; /* Ensures the border is not overlapping the thumb */
  }

  /* For Firefox */
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent; /* thumb and track colors */

  /* Optional: Hide scrollbar when not hovering */
  /* Uncomment the following lines if you want the scrollbar to appear only on hover
  &::-webkit-scrollbar {
    display: none;
  }

  &:hover::-webkit-scrollbar {
    display: block;
  }
  */

  @media (max-width: 768px) {
    width: 100%;
    display: ${({ isMobileView }) => (isMobileView ? 'block' : 'none')};
  }
`;

const ChatWindowContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    display: ${({ isMobileView }) => (isMobileView ? 'block' : 'none')};
  }
`;

const ChatPlaceholder = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
  font-size: 1.2rem;
`;

const ChatApp = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // **Retrieve userData from Redux store or localStorage**
  const userDataFromRedux = useSelector((state) => state.user.userData);

  const [userData, setUserData] = useState(() => {
    return userDataFromRedux || JSON.parse(localStorage.getItem('userData')) || null;
  });

  // **Responsive Design State**
  const [isMobileView, setIsMobileView] = useState(false);

  // **Retrieve the selected user and call info from localStorage or location state**
  const [selectedUser, setSelectedUser] = useState(() => {
    const storedUser = localStorage.getItem('selectedUser');
    if (storedUser) {
      try {
        return JSON.parse(storedUser);
      } catch (error) {
        console.error('Error parsing storedUser:', error);
        return null;
      }
    } else if (location.state && location.state.selectedUserId) {
      // Initialize with userId; actual user object will be set later
      return { user: { id: location.state.selectedUserId } };
    } else {
      return null;
    }
  });

  const [callType, setCallType] = useState(() => {
    return (
      (location.state && location.state.callType) ||
      localStorage.getItem('callType') ||
      null
    );
  });

  const [roomName, setRoomName] = useState(() => {
    return (
      (location.state && location.state.roomName) ||
      localStorage.getItem('roomName') ||
      null
    );
  });

  // **Ensure currentUser has the expected structure (patient)**
  const currentUser = useMemo(() => {
    if (userData && userData.patient) {
      return {
        ...userData.patient,
        user_type: 'patient',
      };
    } else {
      return null;
    }
  }, [userData]);

  // **Determine current user's ID (patient)**
  const currentUserId = currentUser?.user?.id || null;

  // **Access Appointments from Redux or other state management**
  const appointments = useSelector((state) => state.user.appointments); // Adjust according to your state

  // **Update userData when Redux store changes**
  useEffect(() => {
    if (!userDataFromRedux) {
      const storedUserData = JSON.parse(localStorage.getItem('userData'));
      if (storedUserData) {
        setUserData(storedUserData);
      }
    } else {
      setUserData(userDataFromRedux);
    }
  }, [userDataFromRedux]);

  // **Update selectedUser and call info state if location.state changes**
  useEffect(() => {
    console.log('Location State in ChatApp:', location.state);

    if (location.state && location.state.callType) {
      setCallType(location.state.callType);
      localStorage.setItem('callType', location.state.callType);
    }

    if (location.state && location.state.roomName) {
      setRoomName(location.state.roomName);
      localStorage.setItem('roomName', location.state.roomName);
    }

    // Handle selectedUserId from navigation (e.g., after accepting a call)
    if (location.state && location.state.selectedUserId) {
      const userId = location.state.selectedUserId;
      // Find the doctor in appointments with this userId
      const appointment = appointments.find(
        (appt) => appt.doctor?.user?.id === userId
      );
      if (appointment && appointment.doctor) {
        setSelectedUser(appointment.doctor);
        localStorage.setItem('selectedUser', JSON.stringify(appointment.doctor));
      } else {
        console.error(`Doctor with ID ${userId} not found in appointments.`);
        // Optionally, handle fetching the doctor from the server or show an error message
      }
    }
  }, [location.state, appointments]);

  // **Ensure selectedUser is properly set from localStorage or location.state**
  useEffect(() => {
    if (!selectedUser && location.state && location.state.selectedUserId) {
      const userId = location.state.selectedUserId;
      const appointment = appointments.find(
        (appt) => appt.doctor?.user?.id === userId
      );
      if (appointment && appointment.doctor) {
        setSelectedUser(appointment.doctor);
        localStorage.setItem('selectedUser', JSON.stringify(appointment.doctor));
      }
    }
  }, [selectedUser, location.state, appointments]);

  // **Responsive Design Handling**
  useEffect(() => {
    const checkMobile = () => {
      if (window.innerWidth <= 768) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // **Set currentRoom when selectedUser or roomName changes**
  useEffect(() => {
    if (selectedUser && roomName) {
      dispatch(setCurrentRoom(roomName)); // Set the currentRoom in Redux

      return () => {
        dispatch(clearCurrentRoom()); // Clear currentRoom when component unmounts or selectedUser changes
      };
    }
  }, [selectedUser, roomName, dispatch]);

  // **Handle user status subscription**
  useEffect(() => {
    webSocketManager.subscribeToUserStatus();
  }, []);

  // **Helper Function to Generate Room Name**
  const generateRoom = (user1Id, user2Id) => {
    return generateRoomName(user1Id, user2Id); // Use centralized utility
  };

  // **Handle cases where user is not logged in**
  if (!currentUser) {
    return <div>Please log in to view your chats.</div>;
  }

  // **Handler for closing the chat window**
  const handleCloseChat = () => {
    setSelectedUser(null);
    localStorage.removeItem('selectedUser');
    setCallType(null);
    localStorage.removeItem('callType');
    setRoomName(null);
    localStorage.removeItem('roomName');
  };

  const showChatList = isMobileView ? !selectedUser : true;
  const showChatWindow = isMobileView ? selectedUser : true;

  const handleSetSelectedUser = (user) => {
    setSelectedUser(user);
    if (user) {
      localStorage.setItem('selectedUser', JSON.stringify(user));
    } else {
      localStorage.removeItem('selectedUser');
    }
  };

  // **Determine if MobileHeader should be shown**
  const shouldShowMobileHeader = isMobileView && !selectedUser;

  return (
    <div className="dashboard">
      <div className="dashboard-overlay">
        {/* Conditionally render MobileHeader */}
        {shouldShowMobileHeader && <MobileHeader toggleSidebar={toggleSidebar} />}
        <div className="dashboard-box">
          <UserSidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
          <div
            className={`sidebar-overlay ${isSidebarOpen ? 'active' : ''}`}
            onClick={() => setIsSidebarOpen(false)}
          ></div>
          <ChatAppContainer>
            <ChatAppInner>
              {/* Chat List Section */}
              {showChatList && (
                <ChatListContainer isMobileView={isMobileView}>
                  <ChatList setSelectedUser={handleSetSelectedUser} />
                </ChatListContainer>
              )}

              {/* Chat Window Section */}
              {showChatWindow && selectedUser && (
                <ChatWindowContainer isMobileView={isMobileView}>
                  <ChatWindow
                    selectedUser={selectedUser}
                    roomName={
                      roomName || generateRoom(currentUserId, selectedUser.user.id)
                    }
                    currentUser={currentUser}
                    onBack={handleCloseChat} // Using onBack prop
                    callType={callType} // Pass callType to initiate video call if needed
                  />
                </ChatWindowContainer>
              )}

              {/* Placeholder when no chat is selected */}
              {!selectedUser && !isMobileView && (
                <ChatPlaceholder>
                  Select a chat to start messaging.
                </ChatPlaceholder>
              )}
            </ChatAppInner>
          </ChatAppContainer>
        </div>
      </div>
    </div>
  );
};

export default ChatApp;