// GlobalCallNotification.js (Nested structure example)

import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  dequeueIncomingCall,
  acceptIncomingCall,
  declineIncomingCall,
  setSelectedUser,
  endCall,
  receiveCallAccepted,
} from "./slices/notificationSlice";
import webSocketManager from "./services/WebSocketManager";
import Modal from "react-modal";
import "./CSS/GlobalCallNotification.css";
import { useNavigate } from "react-router-dom";
import VideoCall from "./Components/VideoCall";

Modal.setAppElement("#root");

// Utility function to split a single fullName into { firstName, lastName }
function parseFullName(fullName = "Unknown User") {
  const parts = fullName.trim().split(" ");
  if (parts.length === 1) {
    // Only one word, assume it's the first name
    return { firstName: parts[0], lastName: "" };
  }
  // More than one word; the first is "firstName", the rest form "lastName"
  const firstName = parts[0];
  const lastName = parts.slice(1).join(" ");
  return { firstName, lastName };
}

const GlobalCallNotification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    currentCall,
    isInCall,
    activeRoomName,
    callType,
    callQueue,
    isInitiator,
    outgoingCall,
    selectedUser,
  } = useSelector((state) => ({
    currentCall: state.notifications.currentCall,
    isInCall: state.notifications.isInCall,
    activeRoomName: state.notifications.activeRoomName,
    callType: state.notifications.callType,
    callQueue: state.notifications.callQueue,
    isInitiator: state.notifications.isInitiator,
    outgoingCall: state.notifications.outgoingCall,
    selectedUser: state.notifications.selectedUser,
  }));

  // Ringtone
  const ringtoneRef = useRef(null);
  useEffect(() => {
    ringtoneRef.current = new Audio("/static/audio/ringtone.mp3");
    ringtoneRef.current.loop = true;
    return () => {
      if (ringtoneRef.current) {
        ringtoneRef.current.pause();
        ringtoneRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (currentCall && !isInCall) {
      // We have an incoming call, user not in call => ring
      if (ringtoneRef.current) {
        ringtoneRef.current.play().catch((error) => {
          console.error("Ringtone play error:", error);
        });
      }
    } else {
      // Stop the ringtone
      if (ringtoneRef.current) {
        ringtoneRef.current.pause();
        ringtoneRef.current.currentTime = 0;
      }
    }
  }, [currentCall, isInCall]);

  // Automatically dequeue next incoming call
  useEffect(() => {
    if (!currentCall && callQueue.length > 0 && !isInCall) {
      dispatch(dequeueIncomingCall());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callQueue, currentCall, isInCall]);

  // Accept Call
  const handleAccept = () => {
    if (currentCall) {
      webSocketManager.acceptCall(currentCall.fromId, currentCall.roomName);
      dispatch(acceptIncomingCall());

      // Split "fromFullName" into first and last
      const { firstName, lastName } = parseFullName(
        currentCall.fromFullName || "Unknown User"
      );

      // Use nested structure in selectedUser
      dispatch(
        setSelectedUser({
          user: {
            id: currentCall.fromId,
            first_name: firstName,
            last_name: lastName,
          },
        })
      );

      // Stop the ringtone
      if (ringtoneRef.current) {
        ringtoneRef.current.pause();
        ringtoneRef.current.currentTime = 0;
      }

      // Dispatch receiveCallAccepted to update Redux state for VideoCall
      dispatch(
        receiveCallAccepted({
          fromUserId: currentCall.fromId,
          fromFullName: currentCall.fromFullName || "Unknown User",
          roomName: currentCall.roomName,
        })
      );

      // Optional navigation
      // navigate(`/video-call/${currentCall.roomName}`);
    }
  };

  // Decline Call
  const handleDecline = () => {
    if (currentCall) {
      // Notify the caller via WebSocket
      webSocketManager.endCall(currentCall.fromId, currentCall.roomName);
      dispatch(declineIncomingCall());

      // Stop ringtone
      if (ringtoneRef.current) {
        ringtoneRef.current.pause();
        ringtoneRef.current.currentTime = 0;
      }
    }
  };

  // End Call internally
  const handleEndCallInternal = () => {
    // Notify the other party via WebSocket
    if (selectedUser?.user?.id && activeRoomName) {
      webSocketManager.endCall(selectedUser.user.id, activeRoomName);
    }

    // Dispatch endCall action
    dispatch(endCall({ roomName: activeRoomName }));

    // Reset selected user
    dispatch(setSelectedUser(null));
  };

  // Retrieve "currentUser" from Redux
  const currentUser = useSelector((state) => {
    if (state.user.userData?.patient) {
      return state.user.userData;
    }
    if (state.doctor.doctorData?.doctor) {
      return state.doctor.doctorData;
    }
    return null;
  });

  // Render
  return (
    <>
      {/* 
        Show modal if we have a currentCall
        AND we are NOT yet in a call => incoming scenario
      */}
      <Modal
        isOpen={!!(currentCall && !isInCall)}
        onRequestClose={handleDecline}
        contentLabel="Incoming Call"
        className="call-modal"
        overlayClassName="call-modal-overlay"
        shouldCloseOnOverlayClick={false}
      >
        {currentCall && !isInCall && (
          <div className="call-modal-content">
            <h2>
              Incoming {currentCall.callType === "video" ? "Video" : "Audio"}{" "}
              Call
            </h2>
            <p>From: {currentCall.fromFullName || "Unknown User"}</p>
            <div className="call-modal-buttons">
              <button
                className="accept-button"
                onClick={handleAccept}
                aria-label="Accept Call"
              >
                Accept
              </button>
              <button
                className="decline-button"
                onClick={handleDecline}
                aria-label="Decline Call"
              >
                Decline
              </button>
            </div>
          </div>
        )}
      </Modal>

     
    </>
  );
};

export default GlobalCallNotification;
