import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAppointments } from '../slices/userSlice';
import styled from 'styled-components';

// Existing Styled-Components
const ChatListContainer = styled.div`
  width: 90%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  height: 100vh;
    padding:30px 0;


  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    border-right: none;
    border-bottom: 1px solid #ddd;
  }
`;

const LogoBox = styled.div`
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #ddd;

  .Medbridge-access-logo {
    width: 100px;
    height: auto;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      width: 80px;
    }
  }

  .logo h1 {
    font-size: 1.5rem;
    margin: 0;

    .title-we {
      color: #007bff;
    }

    .title-2 {
      display: flex;
      justify-content: center;

      span {
        margin-left: 5px;
      }
    }

    @media (max-width: 768px) {
      font-size: 1.2rem;

      .title-2 {
        flex-direction: column;

        span {
          margin: 0;
        }
      }
    }
  }
`;

const UserItem = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #eee;
  }

  @media (max-width: 768px) {
    padding: 10px 15px;
  }
`;

const UserPhoto = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
`;

const UserInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const UserName = styled.div`
  font-weight: 500;
  text-align: left;
  margin-bottom: 5px;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const LastMessage = styled.div`
  font-size: 0.85rem;
  color: #666;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

const LastMessageTime = styled.div`
  font-size: 0.75rem;
  color: #666;
  white-space: nowrap;
  margin-left: 10px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    font-size: 0.65rem;
    margin-left: 5px;
  }
`;

const NoAppointments = styled.div`
  padding: 20px;
  text-align: center;
  color: #666;
  font-size: 1rem;

  @media (max-width: 768px) {
    padding: 15px;
    font-size: 0.9rem;
  }
`;

// New Styled-Components for Appointment Cards
const AppointmentCardsContainer = styled.div`
  padding: 20px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const AppointmentCard = styled.div`
  background-color: #fff3;
  border: none;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 15px;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const DoctorInfo = styled.div`
  display: flex;
  align-items: center;
`;

const DoctorImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;

  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
`;

const DoctorDetails = styled.div`
  text-align:left;
  h2 {
    font-size: 1.2rem;
    margin: 0 0 5px 0;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  .speciality {
    color: #666;
    font-size: 0.9rem;
    margin:0px;

    @media (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
`;

const DoctorInfoSecondary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items:center;

  
`;

const DatetimeInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .datetime-pair {
    display: flex;
    flex-direction: column;

    .datetime-key {
      font-weight: bold;
      color: #333;
      font-size: 0.85rem;

      @media (max-width: 768px) {
        font-size: 0.75rem;
      }
    }

    .datetime-value {
      color: #555;
      font-size: 0.85rem;

      @media (max-width: 768px) {
        font-size: 0.75rem;
      }
    }
  }
`;

const AppointmentActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: #007bff;
    font-weight: 500;
    font-size: 0.9rem;

    @media (max-width: 768px) {
      font-size: 0.8rem;
    }
  }

  .appointment-actions-buttons {
    display: flex;
    gap: 10px;

    button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 5px;

      img {
        width: 20px;
        height: 20px;

        @media (max-width: 768px) {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
`;

// ChatList Component
const ChatList = ({ setSelectedUser }) => {
  const dispatch = useDispatch();
  const { appointments, status, error, userData } = useSelector((state) => state.user);

  useEffect(() => {
    if (userData && userData.access_token) {  // Adjusted the token check
      dispatch(fetchAppointments());
    } else {
      console.log('User not logged in, redirecting to login page.');
      // Optionally, redirect to login page or show a message
    }
  }, [dispatch, userData]);

  const handleAppointmentClick = (appointment) => {
    // Define what should happen when an appointment card is clicked
    // For example, set the selected user or navigate to appointment details
    setSelectedUser(appointment.doctor);
  };

  if (status === 'loading') {
    return (
      <ChatListContainer>
        <NoAppointments>Loading...</NoAppointments>
      </ChatListContainer>
    );
  }

  if (status === 'failed') {
    return (
      <ChatListContainer>
        <NoAppointments>{error ? error.toString() : 'Failed to load appointments'}</NoAppointments>
      </ChatListContainer>
    );
  }

  return (
    <ChatListContainer>
      <h2 style={{
        textAlign:'left'
      }}>Your Doctors</h2>
      <AppointmentCardsContainer>
        {appointments && appointments.length > 0 ? (
          appointments.map((appointment) => (
            <AppointmentCard key={appointment.id} onClick={() => handleAppointmentClick(appointment)}>
              <DoctorInfo>
                <DoctorImage
                  src={appointment.doctor?.profile_photo  }
                  alt={`Dr. ${appointment.doctor?.user?.first_name || 'Unknown'}'s photo`}
                />
                <DoctorDetails>
                  <h2>
                    {appointment.doctor?.user
                      ? `Dr. ${appointment.doctor.user.first_name} ${appointment.doctor.user.last_name}`
                      : 'Doctor Unassigned'}
                  </h2>
                  <p className="speciality">
                    {appointment.doctor
                      ? `${appointment.doctor.degree || '-'}`
                      : '-'}
                  </p>
                </DoctorDetails>
              </DoctorInfo>
              <p className="branch"
              style={{
                textAlign:'left',
                fontWeight:'600',
              
              }}
              >
                  {appointment.doctor?.branch || '-'}
                </p>
              <DoctorInfoSecondary>
                
                <p className="specialization"
                style={{
                  margin:0
                }}
                >
                  {appointment.doctor?.specialization || '-'}
                </p>
                <p
                style={{
                  margin:0
                }}
                >{appointment.doctor?.country || '-'}</p>
              </DoctorInfoSecondary>
              
            </AppointmentCard>
          ))
        ) : (
          <NoAppointments>No appointments available</NoAppointments>
        )}
      </AppointmentCardsContainer>
    </ChatListContainer>
  );
};

export default ChatList;
