// AskOurExperts.jsx
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useInView } from 'react-intersection-observer';
import '../CSS/AskOurExperts.css';

const AskOurExperts = () => {
  const { ref, inView } = useInView({
    threshold: 0.2, // Trigger when 10% of the component is visible
    triggerOnce: true, // Only trigger once
  });

  return (
    <div
      ref={ref}
      className={`ask-experts-container ${inView ? 'animate' : ''}`}
    >
      <div className="ask-experts-image">
        <img 
          src="/static/banner/ask.jpg" 
          alt="Expert consultant" 
          className="ask-experts-photo"
        />
      </div>
      <div className="ask-experts-content">
        <h2 className="ask-experts-title">Thinking about surgery?</h2>
        <p className="ask-experts-description">
          Why Not Get an Unbiased Second Opinion from Leading Specialists and Discover Affordable, High-Quality Medical Care to Ensure the Best Outcome for Your Health
        </p>
        <div className='btn-section'>
       <Link to="/bookappointment"  >  <button className="ask-experts-button">Ask Now</button></Link>
        </div>
      </div>
    </div>
  );
};

export default AskOurExperts;
