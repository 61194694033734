
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import DoctorChatList from '../Components/DoctorChatList';
import PatientProfile from '../Components/PatientProfile';
import ChatWindow from '../Components/ChatWindow';
import { fetchPatientDetails, clearPatientDetails } from '../slices/patientSlice';
import { setCurrentRoom, clearCurrentRoom } from '../slices/notificationSlice';
import { FiArrowLeft } from 'react-icons/fi'; // For mobile back button
import { FaHeartbeat } from 'react-icons/fa'; // For toggle buttons
import { generateRoomName } from '../utils'; // Centralized room naming utility
import MobileHeader from '../Components/MobileHeader';
import DoctorSidebar from '../Components/DoctorSidebar';

const ChatAppContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
  

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ChatListContainer = styled.div`
  border-right: 1px solid #ddd;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ddd;
    display: ${({ show }) => (show ? 'block' : 'none')};
  }
`;

const PatientProfileContainer = styled.div`
  width: 100%;
  border-right: 1px solid #ddd;
  overflow-y: auto;
  background-color: #fff;

  @media (max-width: 768px) {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ddd;
    display: ${({ show }) => (show ? 'block' : 'none')};
  }
`;

const ChatWindowContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;
  background-color: #e9ebee;

  @media (max-width: 768px) {
    width: 100%;
    display: ${({ show }) => (show ? 'block' : 'none')};
  }
`;



const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 10px;
`;

const ToggleButtonContainer = styled.div`
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const ToggleButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  font-size: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }
`;

const StartChatButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 15px 25px;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #27ae60;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const ContentPlaceholder = styled.div`
  padding: 20px;
  text-align: center;
  color: #666;
`;

const DoctorChatPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [isChatStarted, setIsChatStarted] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

 
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  const doctorData = useSelector((state) => state.doctor.doctorData);
  const { details: patientDetails, loading, error } = useSelector((state) => state.patient);

  useEffect(() => {
    // Update isMobile state on window resize
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch patient details when a patient is selected
  useEffect(() => {
    if (selectedPatient) {
      dispatch(fetchPatientDetails(selectedPatient.id));
    } else {
      dispatch(clearPatientDetails());
    }
  }, [dispatch, selectedPatient]);

  // Flatten the doctor data to match the expected structure
  const currentUser = doctorData?.doctor
    ? {
        ...doctorData.doctor,
        user_type: 'doctor',
        access_token: doctorData.access_token,
        refresh_token: doctorData.refresh_token,
      }
    : null;

  // Generate room name for chat
  const roomName = selectedPatient
    ? generateRoomName(doctorData.doctor.user.id, selectedPatient.user.id)
    : null;

  // Set currentRoom when patient data is available
  useEffect(() => {
    if (selectedPatient && roomName) {
      dispatch(setCurrentRoom(roomName)); // Set the currentRoom in Redux

      // No need to subscribe/unsubscribe here as WebSocketInitializer handles all room subscriptions

      return () => {
        dispatch(clearCurrentRoom()); // Clear currentRoom when component unmounts or selectedPatient changes
      };
    }
  }, [selectedPatient, roomName, dispatch]);

  // Ensure doctor is logged in
  if (!doctorData) {
    return <ContentPlaceholder>Please log in to view your chats.</ContentPlaceholder>;
  }

  // Handlers for initiating and ending chat
  const handleStartChat = () => {
    setIsChatStarted(true);
  };

  const handleEndChat = () => {
    setIsChatStarted(false);
  };

  // Handlers for mobile navigation
  const navigateToChatList = () => {
    setIsChatStarted(false);
    setSelectedPatient(null);
  };

  return (
    <div className='doctor-dashboard'>
      <div className='doctor-dashboard-overlay'>
        <MobileHeader toggleSidebar={toggleSidebar} />
        <div className='dashboard-box'>
          <DoctorSidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
          <div
            className={`sidebar-overlay ${isSidebarOpen ? 'active' : ''}`}
            onClick={() => setIsSidebarOpen(false)}
          ></div>

    <ChatAppContainer>
      <ChatListContainer show={!selectedPatient || (!isChatStarted && isMobile)}>
        <DoctorChatList setSelectedPatient={setSelectedPatient} />
      </ChatListContainer>

      {selectedPatient && !isChatStarted && (
        <PatientProfileContainer show={selectedPatient && !isChatStarted}>
          
          {loading ? (
            <ContentPlaceholder>Loading patient details...</ContentPlaceholder>
          ) : error ? (
            <ContentPlaceholder>Error loading patient details: {error.message}</ContentPlaceholder>
          ) : (
            <PatientProfile
              selectedUser={{ ...patientDetails, user_type: 'patient' }}
              onStartChat={handleStartChat}
            />
          )}
        </PatientProfileContainer>
      )}

      {/* Chat Window Section */}
      {selectedPatient && isChatStarted && (
        <ChatWindowContainer show={isChatStarted}>
        
          <ChatWindow
            selectedUser={{ ...patientDetails, user_type: 'patient' }}
            roomName={roomName}
            currentUser={currentUser}
            onBack={handleEndChat} // Using onBack prop
          />
        </ChatWindowContainer>
      )}

      {/* Start Chat Button for Desktop */}
      {!isChatStarted && !isMobile && (
        <StartChatButton onClick={handleStartChat}>Start Chat</StartChatButton>
      )}

      {/* Toggle Buttons for Mobile View */}
      {isMobile && !isChatStarted && (
        <ToggleButtonContainer>
          <ToggleButton onClick={handleStartChat} aria-label="Start Chat">
            <FaHeartbeat />
          </ToggleButton>
        </ToggleButtonContainer>
      )}
    </ChatAppContainer>
    </div>
    </div>
    </div>
  );
};

export default DoctorChatPage;
