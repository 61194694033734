import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../slices/userSlice';
import '../CSS/DoctorSidebar.css';

const UserSidebar = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate('/bookappointment');
  };

  // Function to determine class names for NavLink
  const getNavLinkClass = ({ isActive }) => 
    isActive ? 'nav-item nav-side-active' : 'nav-item';

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button className="close-sidebar" onClick={onClose}>&times;</button>
      <div className='logo-box'>
        <img src='/static/icons/logo.webp' alt='Medbridge-access-logo' className="Medbridge-access-logo" />
      </div>

      <nav className="nav-items">
  <NavLink to="/userdashboard" className={getNavLinkClass} onClick={onClose}>
    <i className="fa-solid fa-house"></i> 
    <span>Home</span>
  </NavLink>
  <NavLink to="/appointmentpage" className={getNavLinkClass} onClick={onClose}>
    <i className="fa-solid fa-calendar"></i>
    <span>Appointments</span>
  </NavLink>
  <NavLink to="/userfilemanager" className={getNavLinkClass} onClick={onClose}>
    <i className="fa-solid fa-file"></i>
    <span>Files</span>
  </NavLink>
  <NavLink to="/personal-details" className={getNavLinkClass} onClick={onClose}>
    <i className="fa-solid fa-user"></i>
    <span>Profile</span>
  </NavLink>
  <NavLink to="/chat" className={getNavLinkClass} onClick={onClose}>
    <i className="fa-solid fa-message"></i>
    <span>Chat</span>
  </NavLink>
  <button onClick={handleLogout} className="nav-item logout-button">
    <i className="fa-solid fa-arrow-right-from-bracket"></i>
    <span>Logout</span>
  </button>
</nav>

    </div>
  );
};

export default UserSidebar;
