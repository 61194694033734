import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserSidebar from "../Components/UserSidebar";
import MobileHeader from "../Components/MobileHeader";
import PatientHeader from "../Components/PatientHeader";
import {
  deleteMedicalReport,
  uploadMedicalReport,
  fetchDiagnoses,
} from "../slices/userSlice";
import "../CSS/UserFileManager.css";

const UserFileManager = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);

  const uploadStatus = useSelector(
    (state) => state.user.uploadMedicalReportStatus
  );
  const uploadError = useSelector(
    (state) => state.user.uploadMedicalReportError
  );

  const diagnoses = useSelector((state) => state.user.diagnoses);
  const fetchDiagnosesStatus = useSelector(
    (state) => state.user.fetchDiagnosesStatus
  );
  const fetchDiagnosesError = useSelector(
    (state) => state.user.fetchDiagnosesError
  );

  const medicalReports = useSelector(
    (state) => state.user.userData?.patient?.medical_reports || []
  );

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);
  const [uploadMessage, setUploadMessage] = useState(null);
  const [showReportsMenu, setShowReportsMenu] = useState(false);

  // For showing full diagnosis description
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [fullDescription, setFullDescription] = useState("");

  // For toggling card expansion on small screens
  const [expandedDiagnosisId, setExpandedDiagnosisId] = useState(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleDeleteIconClick = (reportId) => {
    setReportToDelete(reportId);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirmation = () => {
    if (reportToDelete && userData?.patient?.id) {
      dispatch(
        deleteMedicalReport({
          patient_id: userData.patient.id,
          report_id: reportToDelete,
        })
      );
    }
    setReportToDelete(null);
    setShowDeleteModal(false);
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    if (file && userData?.patient?.id) {
      const formData = new FormData();
      formData.append("file", file);

      dispatch(
        uploadMedicalReport({
          patient_id: userData.patient.id,
          fileData: formData,
        })
      );
    }
  };

  useEffect(() => {
    if (uploadStatus === "loading") {
      setUploadMessage({ type: "info", text: "Uploading file..." });
    } else if (uploadStatus === "succeeded") {
      setUploadMessage({
        type: "success",
        text: "File uploaded successfully!",
      });
    } else if (uploadStatus === "failed") {
      setUploadMessage({
        type: "error",
        text: `Upload failed: ${uploadError}`,
      });
    }

    if (uploadStatus === "succeeded" || uploadStatus === "failed") {
      const timer = setTimeout(() => {
        setUploadMessage(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [uploadStatus, uploadError]);

  useEffect(() => {
    if (userData?.patient) {
      dispatch(fetchDiagnoses());
    }
  }, [dispatch, userData]);

  const colors = [
    "ufm-report-icon-blue",
    "ufm-report-icon-green",
    "ufm-report-icon-orange",
  ];

  const formatDateTime = (dateString) => {
    if (!dateString) return { formattedDate: "N/A", formattedTime: "N/A" };
    const dateObj = new Date(dateString);
    const formattedDate = dateObj.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    const formattedTime = dateObj.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return { formattedDate, formattedTime };
  };

  const openDescriptionModal = (fullText) => {
    setFullDescription(fullText);
    setShowDescriptionModal(true);
  };

  const toggleDiagnosisCard = (id) => {
    setExpandedDiagnosisId(expandedDiagnosisId === id ? null : id);
  };

  // Debugging: Log diagnoses with missing doctor/user info
  useEffect(() => {
    if (diagnoses) {
      diagnoses.forEach((diagnosis) => {
        if (!diagnosis.doctor || !diagnosis.doctor.user) {
          console.warn(
            `Diagnosis ID ${diagnosis.id} is missing doctor/user information.`
          );
        }
      });
    }
  }, [diagnoses]);

  return (
    <div className="dashboard">
      <div className="dashboard-overlay">
        <MobileHeader toggleSidebar={toggleSidebar} />
        <div className="dashboard-box">
          <UserSidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
          <div
            className={`sidebar-overlay ${isSidebarOpen ? "active" : ""}`}
            onClick={() => setIsSidebarOpen(false)}
          ></div>

          <div className="ufm-content">
            <PatientHeader />

            {/* Upload Section */}
            <div className="ufm-upload-section">
              <div className="ufm-upload-dropzone">
                <input
                  type="file"
                  onChange={handleUpload}
                  id="ufm-file-input"
                  style={{ display: "none" }}
                />
                <label htmlFor="ufm-file-input" className="ufm-upload-label">
                  <div className="ufm-upload-icon">
                    <img src="/static/icons/upload-file.webp" alt="Upload" />
                  </div>
                  <p className="ufm-upload-title">
                    Click to upload or drag and drop
                  </p>
                  <p className="ufm-max-size">Maximum file size 50 MB</p>
                </label>
              </div>

              {uploadMessage && (
                <div className={`ufm-upload-message ${uploadMessage.type}`}>
                  {uploadMessage.text}
                </div>
              )}
            </div>

            {/* My Reports Section */}
            <div className="ufm-section ufm-my-reports-section">
              <div className="ufm-my-reports-header">
                <h2 className="ufm-section-title">My Reports</h2>
                <div className="ufm-my-reports-options">
                  <button
                    className="ufm-options-button"
                    onClick={() => setShowReportsMenu(!showReportsMenu)}
                  >
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </button>
                  {showReportsMenu && (
                    <div className="ufm-options-menu">
                      <div
                        className="ufm-options-menu-item"
                        onClick={() => {
                          setIsDeleteMode(!isDeleteMode);
                          setShowReportsMenu(false);
                        }}
                      >
                        {isDeleteMode ? "Cancel Delete" : "Delete Reports"}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="ufm-my-reports-grid">
                {medicalReports.length > 0 ? (
                  medicalReports.map((report, index) => {
                    const fileName = report.file.split("/").pop();
                    const iconColorClass = colors[index % colors.length];
                    const { formattedDate, formattedTime } = formatDateTime(
                      report.uploaded_at
                    );

                    return (
                      <a
                        href={report.file}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ufm-report-item"
                        key={report.id}
                      >
                        <div className={`ufm-report-icon ${iconColorClass}`}>
                          <i className="fa-regular fa-file-lines fa-2x"></i>
                        </div>
                        <div className="ufm-report-details">
                          <p className="ufm-report-name">{fileName}</p>
                          <p className="ufm-report-date">
                            Uploaded: {formattedDate}, {formattedTime}
                          </p>
                        </div>
                        {isDeleteMode && (
                          <button
                            className="ufm-report-delete-icon"
                            onClick={(e) => {
                              e.preventDefault();
                              handleDeleteIconClick(report.id);
                            }}
                          >
                            <i className="fa-solid fa-trash"></i>
                          </button>
                        )}
                      </a>
                    );
                  })
                ) : (
                  <p>No medical reports found.</p>
                )}
              </div>
            </div>

            {/* Diagnoses Section */}
            <div className="ufm-section ufm-diagnoses-section">
              <h2 className="ufm-section-title">Diagnoses</h2>

              {fetchDiagnosesStatus === "loading" && (
                <p>Loading diagnoses...</p>
              )}
              {fetchDiagnosesStatus === "failed" && (
                <p>Error loading diagnoses: {fetchDiagnosesError}</p>
              )}

              {fetchDiagnosesStatus === "succeeded" && (
                <>
                  {diagnoses.length > 0 ? (
                    <>
                      {/* Desktop Table View */}
                      <div className="ufm-diagnoses-table-container">
                        <table className="ufm-diagnoses-table">
                          <thead>
                            <tr>
                              <th>Doctor</th>
                              <th>Diagnosis Description</th>
                              <th>Appointment Date</th>
                              <th>Appointment Time</th>
                              <th>Digital Prescription</th>
                              <th>Prescription</th>
                            </tr>
                          </thead>
                          <tbody>
                            {diagnoses.map((diagnosis) => {
                              const { formattedDate, formattedTime } =
                                formatDateTime(diagnosis.appointment_time);
                              const description =
                                diagnosis.diagnosis_description || "N/A";
                              const truncatedDesc =
                                description.length > 50
                                  ? description.slice(0, 50) + "..."
                                  : description;

                              return (
                                <tr key={diagnosis.id}>
                                  <td className="ufm-doctor-cell">
                                    {diagnosis.doctor?.user ? (
                                      <>
                                        <img
                                          src={diagnosis.doctor.profile_photo}
                                          alt={`${diagnosis.doctor.user.first_name} ${diagnosis.doctor.user.last_name}`}
                                          className="ufm-doctor-photo"
                                        />
                                        <div className="ufm-doctor-info">
                                          <span className="ufm-doctor-name">
                                            {diagnosis.doctor.user
                                              ?.first_name || "First Name"}{" "}
                                            {diagnosis.doctor.user?.last_name ||
                                              "Last Name"}
                                          </span>
                                          <span className="ufm-doctor-specialization">
                                            {diagnosis.doctor.specialization ||
                                              "Specialization"}
                                          </span>
                                        </div>
                                      </>
                                    ) : (
                                      <span className="ufm-doctor-name">
                                        Doctor Information Unavailable
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {description.length > 50 ? (
                                      <span
                                        className="ufm-description-link"
                                        onClick={() =>
                                          openDescriptionModal(description)
                                        }
                                      >
                                        {truncatedDesc}
                                      </span>
                                    ) : (
                                      description
                                    )}
                                  </td>
                                  <td>{formattedDate}</td>
                                  <td>{formattedTime}</td>
                                  <td>
                                    {diagnosis.digital_prescription ? (
                                      <a
                                        href={diagnosis.digital_prescription}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="ufm-link"
                                      >
                                        View
                                      </a>
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                  <td>
                                    {diagnosis.prescription ? (
                                      <a
                                        href={diagnosis.prescription}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="ufm-link"
                                      >
                                        View
                                      </a>
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>

                      {/* Mobile Card View */}
                      <div className="ufm-diagnosis-cards-container">
                        {diagnoses.map((diagnosis) => {
                          const { formattedDate, formattedTime } =
                            formatDateTime(diagnosis.appointment_time);
                          const description =
                            diagnosis.diagnosis_description || "N/A";
                          const truncatedDesc =
                            description.length > 50
                              ? description.slice(0, 50) + "..."
                              : description;

                          const isExpanded =
                            expandedDiagnosisId === diagnosis.id;

                          return (
                            <div
                              className="ufm-diagnosis-card"
                              key={diagnosis.id}
                            >
                              <div className="ufm-diagnosis-card-header">
                                <div className="ufm-doctor-cell">
                                  {diagnosis.doctor?.user ? (
                                    <>
                                      <img
                                        src={diagnosis.doctor.profile_photo}
                                        alt={`${diagnosis.doctor.user.first_name} ${diagnosis.doctor.user.last_name}`}
                                        className="ufm-doctor-photo"
                                      />
                                      <div className="ufm-doctor-info">
                                        <span className="ufm-doctor-name">
                                          {diagnosis.doctor.user?.first_name ||
                                            "First Name"}{" "}
                                          {diagnosis.doctor.user?.last_name ||
                                            "Last Name"}
                                        </span>
                                        <span className="ufm-doctor-specialization">
                                          {diagnosis.doctor.specialization ||
                                            "Specialization"}
                                        </span>
                                      </div>
                                    </>
                                  ) : (
                                    <span className="ufm-doctor-name">
                                      Doctor Information Unavailable
                                    </span>
                                  )}
                                </div>
                                <button
                                  className={`ufm-arrow-button ${
                                    isExpanded ? "expanded" : ""
                                  }`}
                                  onClick={() =>
                                    toggleDiagnosisCard(diagnosis.id)
                                  }
                                >
                                  <i className="fa-solid fa-chevron-down"></i>
                                </button>
                              </div>
                              {isExpanded && (
                                <div className="ufm-diagnosis-card-details">
                                  <div className="ufm-diagnosis-card-row">
                                    <strong>Diagnosis:</strong>{" "}
                                    {description.length > 50 ? (
                                      <span
                                        className="ufm-description-link"
                                        onClick={() =>
                                          openDescriptionModal(description)
                                        }
                                      >
                                        {truncatedDesc}
                                      </span>
                                    ) : (
                                      description
                                    )}
                                  </div>
                                  <div className="ufm-diagnosis-card-row">
                                    <strong>Appointment Date:</strong>{" "}
                                    {formattedDate}
                                  </div>
                                  <div className="ufm-diagnosis-card-row">
                                    <strong>Appointment Time:</strong>{" "}
                                    {formattedTime}
                                  </div>
                                  <div className="ufm-diagnosis-card-row">
                                    <strong>Digital Prescription:</strong>{" "}
                                    {diagnosis.digital_prescription ? (
                                      <a
                                        href={diagnosis.digital_prescription}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="ufm-link"
                                      >
                                        View
                                      </a>
                                    ) : (
                                      "N/A"
                                    )}
                                  </div>
                                  <div className="ufm-diagnosis-card-row">
                                    <strong>Prescription:</strong>{" "}
                                    {diagnosis.prescription ? (
                                      <a
                                        href={diagnosis.prescription}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="ufm-link"
                                      >
                                        View
                                      </a>
                                    ) : (
                                      "N/A"
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <p>No diagnoses data found.</p>
                  )}
                </>
              )}
            </div>

            {/* Delete Confirmation Modal */}
            {showDeleteModal && (
              <div className="ufm-delete-modal-overlay">
                <div className="ufm-delete-modal-content">
                  <h4>Confirm Delete</h4>
                  <p>Are you sure you want to delete this medical report?</p>
                  <div className="ufm-modal-actions">
                    <button
                      className="ufm-cancel-btn"
                      onClick={() => setShowDeleteModal(false)}
                    >
                      No
                    </button>
                    <button
                      className="ufm-confirm-delete-btn"
                      onClick={handleDeleteConfirmation}
                    >
                      Yes, Delete
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Full Diagnosis Description Modal */}
            {showDescriptionModal && (
              <div className="ufm-description-modal-overlay">
                <div className="ufm-description-modal-content">
                  <h4>Diagnosis Description</h4>
                  <p>{fullDescription}</p>
                  <div className="ufm-modal-actions">
                    <button
                      className="ufm-cancel-btn"
                      onClick={() => setShowDescriptionModal(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserFileManager;
