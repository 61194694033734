// src/components/AddDiagnosisModal.js

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { addDiagnosis, clearAddDiagnosisStatus } from '../slices/patientSlice';
import '../CSS/AddDiagnosisModal.css';

const AddDiagnosisModal = ({ isOpen, onRequestClose, patientId }) => {
  const dispatch = useDispatch();
  const addDiagnosisStatus = useSelector((state) => state.patient.addDiagnosisStatus);
  const addDiagnosisError = useSelector((state) => state.patient.addDiagnosisError);

  const [diagnosisDescription, setDiagnosisDescription] = useState('');
  const [prescription, setPrescription] = useState(null);
  const [dragOver, setDragOver] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!diagnosisDescription || !prescription) {
      alert('Please provide a diagnosis description and a prescription file.');
      return;
    }

    const diagnosisData = {
      diagnosis_description: diagnosisDescription,
      prescription,
    };

    dispatch(addDiagnosis({ patientId, diagnosisData }));
  };

  const handleClose = () => {
    dispatch(clearAddDiagnosisStatus());
    onRequestClose();
    setDiagnosisDescription('');
    setPrescription(null);
  };

  useEffect(() => {
    if (addDiagnosisStatus === 'succeeded') {
      handleClose();
    }
  }, [addDiagnosisStatus]);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setPrescription(e.dataTransfer.files[0]);
    }
  };

  const handleFileChange = (e) => {
    setPrescription(e.target.files[0]);
  };

  const handleClickDropzone = () => {
    document.getElementById('prescription-file-input').click();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Add Diagnosis"
      className="add-diagnosis-modal"
      overlayClassName="add-diagnosis-overlay"
    >
      <h2>Add Diagnosis</h2>
      <form onSubmit={handleSubmit} className="add-diagnosis-form">
        <div className="form-group">
          <label htmlFor="diagnosisDescription">Diagnosis Description<span className="required">*</span></label>
          <textarea
            id="diagnosisDescription"
            value={diagnosisDescription}
            onChange={(e) => setDiagnosisDescription(e.target.value)}
            required
            rows="4"
          ></textarea>
        </div>

        <div 
          className={`form-group dropzone ${dragOver ? 'drag-over' : ''}`} 
          onDragOver={handleDragOver} 
          onDragLeave={handleDragLeave} 
          onDrop={handleDrop}
          onClick={handleClickDropzone}
          style={{ border: '2px dashed #aaa', padding: '20px', textAlign: 'center', cursor: 'pointer' }}
        >
          <label htmlFor="prescription">Prescription File<span className="required">*</span></label>
          <input
            type="file"
            id="prescription-file-input"
            accept=".pdf,.jpg,.png"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <div className="dropzone-area">
            {prescription 
              ? <span>{prescription.name}</span> 
              : <span>Drag & drop a file or click here to upload</span>}
          </div>
        </div>

        {addDiagnosisError && (
          <div className="error-message">
            {addDiagnosisError.message || 'Failed to add diagnosis.'}
          </div>
        )}

        <div className="form-actions">
          <button type="button" onClick={handleClose} className="cancel-button">
            Cancel
          </button>
          <button type="submit" className="submit-button" disabled={addDiagnosisStatus === 'loading'}>
            {addDiagnosisStatus === 'loading' ? 'Adding...' : 'Add Diagnosis'}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddDiagnosisModal;
