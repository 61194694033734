import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../slices/doctorSlice';
import '../CSS/DoctorSidebar.css';

const DoctorSidebar = ({ isOpen, onClose }) => {
  const [isAvailable, setIsAvailable] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleToggleChange = () => {
    setIsAvailable(!isAvailable);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/doctors/login');
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className='side-bar-overlay'>
        <button className="close-sidebar" onClick={onClose}>&times;</button>
        <div className='logo-box'>
          {/* If you want to keep the logo image, retain the <img> tag */}
          <img src='/static/icons/logo.webp' alt='Medbridge-access-logo' className="Medbridge-access-logo" />
         
        </div>

        <nav className="nav-items">
  <Link to="/doctors/doctordashboard" className="nav-item" onClick={onClose}>
    <i className="fa-solid fa-house nav-icon"></i>
    <span>Dashboard</span>
  </Link>
  
  <Link to="/doctors/personal-details" className="nav-item" onClick={onClose}>
    <i className="fa-solid fa-user nav-icon"></i>
    <span>Profile</span>
  </Link>

  <Link to="/doctors/chatpage" className="nav-item" onClick={onClose}>
    <i className="fa-solid fa-message nav-icon"></i>
    <span>Chat</span>
  </Link>

  <button onClick={handleLogout} className="nav-item logout-button">
    <i className="fa-solid fa-right-from-bracket nav-icon"></i> 
    <span>Logout</span>
  </button>
</nav>


        
      </div>
    </div>
  );
};

export default DoctorSidebar;
