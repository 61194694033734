import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { blogLogin } from '../slices/blogSlice';
import '../ScopedBootstrap.scss'; // Import Scoped Bootstrap SCSS

function BlogLoginFormComponent() {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.blog);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(blogLogin({ username, password }));
  };

  return (
    <div 
      className="bootstrap-scope"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        margin: 0,
        padding: 0,
        background: 'linear-gradient(135deg, #ff6ec4, #7873f5)',
      }}
    >
      <form 
        onSubmit={handleSubmit} 
        className="p-4 blog-login-form"
        style={{
          background: '#fff',
          borderRadius: '8px',
          boxShadow: '0 0 20px rgba(0,0,0,0.1)',
          textAlign: 'left'
        }}
      >
        <h2 
          className="mb-4 text-center" 
          style={{ fontWeight: 'bold', color: '#333' }}
        >
          Staff Blog Login
        </h2>
        {error && (
          <div className="alert alert-danger">
            {error.detail || 'Invalid credentials'}
          </div>
        )}
        <div className="form-group mb-3">
          <label htmlFor="username" style={{ fontWeight: 'bold' }}>Username</label>
          <input
            type="text"
            className="form-control"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            style={{ 
              padding: '10px',
              border: '1px solid #ccc', 
              borderRadius: '4px' 
            }}
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="password" style={{ fontWeight: 'bold' }}>Password</label>
          <input 
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            style={{ 
              padding: '10px', 
              border: '1px solid #ccc', 
              borderRadius: '4px' 
            }}
          />
        </div>
        <button 
          type="submit" 
          className="btn btn-danger w-100"
          style={{ 
            padding: '12px', 
            fontWeight: 'bold' 
          }}
          disabled={loading}
        >
          {loading ? 'Logging in...' : 'Login'}
        </button>
      </form>
    </div>
  );
}

export default BlogLoginFormComponent;
