// src/Components/AppointmentAndQuotePage.jsx
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bookAppointment, verifyPayment } from "../slices/appointmentSlice";
// import { requestQuote } from '../slices/quoteSlice';
import UserSidebar from "../Components/UserSidebar";
import "../CSS/AppointmentAndQuotePage.css";
import MobileHeader from "../Components/MobileHeader";

const AppointmentAndQuotePage = () => {
  const dispatch = useDispatch();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [isQuote, setIsQuote] = useState(false); // Toggle between Appointment and Quote forms

  // -------------------- Appointment Form States --------------------
  const [problem, setProblem] = useState("");
  const [loadingAppointment, setLoadingAppointment] = useState(false);
  const [isAppointmentSuccess, setIsAppointmentSuccess] = useState(false);

  // -------------------- Quote Form States --------------------
  const [details, setDetails] = useState("");
  const [loadingQuote, setLoadingQuote] = useState(false);
  const [isQuoteSuccess, setIsQuoteSuccess] = useState(false);

  // Get user information from the Redux store
  const userState = useSelector((state) => state.user.userData);
  const {
    patient: {
      user: {
        username = "",
        email = "",
        first_name = "",
        last_name = "",
        id: userId,
      } = {},
      country = "",
      dob = "",
      gender = "",
      phone = "",
      medical_history = [],
      medical_reports = [],
    } = {},
  } = userState || {};

  // Determine pricing based on country
  const isIndia = country.toLowerCase() === "india";
  const originalPrice = isIndia ? 2000 : 60; // INR for India, USD for international
  const discountedPrice = isIndia ? 1000 : 30;
  const currency = isIndia ? "INR" : "USD";

  // -------------------- Payment Handler --------------------
  const handlePayment = async (appointmentData) => {
    try {
      setLoadingAppointment(true); // Set loading to true

      // Book appointment via the backend
      const appointmentResponse = await dispatch(
        bookAppointment({ ...appointmentData, currency })
      ).unwrap();

      if (appointmentResponse.error) {
        alert("Error booking appointment");
        setLoadingAppointment(false);
        return;
      }

      const {
        razorpay_order_id,
        currency: responseCurrency,
        amount,
      } = appointmentResponse;

      const options = {
        key: "rzp_live_6viAxivNHxiCPI",
        amount: amount, // in the smallest currency unit
        currency: responseCurrency || "INR",
        name: "MedBridge Access",
        description: "Appointment Booking",
        image: "https://www.medbridgeaccess.com/static/icons/logo.webp",
        order_id: razorpay_order_id,
        prefill: {
          name: `${first_name} ${last_name}`,
          email: email,
          contact: phone,
        },
        notes: {
          address: country || "Clinic's Address",
        },
        theme: {
          color: "#840442",
        },
        handler: async function (response) {
          const paymentData = {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          };

          const verifyResponse = await dispatch(verifyPayment(paymentData));
          setLoadingAppointment(false);

          if (verifyResponse.error) {
            alert("Payment verification failed");
          } else {
            setIsAppointmentSuccess(true);
          }
        },
      };

      const rzp1 = new window.Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        alert(`Payment Failed: ${response.error.description}`);
        alert(`Error Code: ${response.error.code}`);
        alert(`Order ID: ${response.error.metadata.order_id}`);
        alert(`Payment ID: ${response.error.metadata.payment_id}`);
        setLoadingAppointment(false);
      });

      // Open the Razorpay modal
      rzp1.open();
    } catch (error) {
      console.error("Error during payment:", error);
      alert(
        "An error occurred while processing the payment. Please try again."
      );
      setLoadingAppointment(false);
    }
  };

  // -------------------- Appointment Submit --------------------
  const handleAppointmentSubmit = async (e) => {
    e.preventDefault();

    if (!problem) {
      alert("Please describe your medical issue.");
      return;
    }

    const appointmentData = {
      problem_description: problem,
      patient: userId,
      currency,
      amount: discountedPrice * (currency === "INR" ? 100 : 100),
    };

    await handlePayment(appointmentData);
  };

  // -------------------- Quote Submit --------------------
  const handleQuoteSubmit = async (e) => {
    e.preventDefault();

    if (!details) {
      alert("Please provide some details about the quote request.");
      return;
    }

    setLoadingQuote(true);
    try {
      const quoteData = {
        details_description: details,
        userId,
        email,
        phone,
      };

      // Simulate sending the quote request
      console.log("Quote request sent:", quoteData);

      alert("Quote request submitted successfully!");
      setIsQuoteSuccess(true);
    } catch (error) {
      console.error("Error submitting quote request:", error);
      alert(
        "An error occurred while submitting your request. Please try again."
      );
    } finally {
      setLoadingQuote(false);
    }
  };

  return (
    <div className="dashboard">
      <div className="dashboard-overlay">
        <MobileHeader toggleSidebar={toggleSidebar} />
        <div className="dashboard-box">
          <UserSidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
          <div
            className={`sidebar-overlay ${isSidebarOpen ? "active" : ""}`}
            onClick={() => setIsSidebarOpen(false)}
          ></div>

          {/* Begin redesigned layout */}
          <div className="page-container">
            {/* Left side (image and text) */}
            <div className="left-side">
              {/* You can replace this image URL to match your design */}
              <div className="image-section">
                <img src="/static/banner/happy-family.jpg" alt="Decorative" />
              </div>
              <div className="promo-text-section">
                <div className="promo-text-section-box">
                  <h3>Welcome Offer</h3>
                  <h1>
                    50% Off
                    <br />
                    for all online bookings
                  </h1>
                  <p>
                    On the occasion of launching our new venture all patients will recieve 50% Off on all  appointments
                  </p>
                </div>
              </div>
            </div>

            {/* Right side (forms and text) */}
            <div className="right-side">
              <div className="form-section">
                <div className="appointment-quote-page-header"></div>

                {!isQuote ? (
                  isAppointmentSuccess ? (
                    <div className="success-container">
                      <h2>Thank You for Booking with Us!</h2>
                      <p>
                        We're thrilled to assist you on your health journey. Our
                        team at MedBridge Access will reach out to you with the
                        details of your appointment within the next 24 hours. If
                        you have any further questions or need immediate
                        assistance, feel free to contact us. Wishing you good
                        health and a speedy recovery!
                      </p>
                      <button
                        className="btn"
                        onClick={() => setIsAppointmentSuccess(false)}
                      >
                        Book Another Appointment
                      </button>
                    </div>
                  ) : (
                    <div className="appointemnt-form-container">
                      <form onSubmit={handleAppointmentSubmit}>
                        <div class="paper-content">
                          <textarea
                            class="notebook-textarea"
                            onChange={(e) => setProblem(e.target.value)}
                            autoFocus
                            placeholder="Describe your medical condition in minimum 10 words"
                          ></textarea>
                        </div>

                        
                        <div className="appointment-btns">
                          <button
                            type="submit"
                            className="btn"
                            disabled={loadingAppointment}
                          >
                            {loadingAppointment
                              ? "Processing..."
                              : "Book Now & Pay"}
                          </button>
                          <div className="toggle-link">
                            <p>
                              <span>
                              Not sure?{" "}
                              </span>
                              <button
                                className="link-button"
                                onClick={() => setIsQuote(true)}
                              >
                                Request a Quote
                              </button>
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>
                  )
                ) : isQuoteSuccess ? (
                  <div className="success-container">
                    <h2>Quote Request Submitted!</h2>
                    <p>
                      Thank you for reaching out to us. Our team will get back
                      to you with a personalized quote shortly. If you have any
                      immediate questions, feel free to contact us directly.
                    </p>
                    <button
                      className="btn"
                      onClick={() => setIsQuoteSuccess(false)}
                    >
                      Request Another Quote
                    </button>
                  </div>
                ) : (
                  <div className="appointemnt-form-container">
                   
                    <form onSubmit={handleQuoteSubmit}>
                    <div class="paper-content">
                          <textarea
                            class="notebook-textarea"
                            autoFocus
                            onChange={(e) => setDetails(e.target.value)}
                            placeholder="Describe your requirements"
                            required
                          ></textarea>
                        </div>
                        <div className="appointment-btns">
                        <button
                        type="submit"
                        className="btn"
                        disabled={loadingQuote}
                      >
                        {loadingQuote ? "Submitting..." : "Request Quote"}
                      </button>
                        <div className="toggle-link">
                        
                      <p>
                        Prefer to book an appointment?{" "}
                        <button
                          className="link-button"
                          onClick={() => setIsQuote(false)}
                        >
                          Book Now
                        </button>
                      </p>
                    </div>
                    </div>
                     
                    </form>
                   
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* End redesigned layout */}
        </div>
      </div>
    </div>
  );
};

export default AppointmentAndQuotePage;
