import React, { useEffect, useRef, useState } from 'react';
import '../CSS/Form.css';

function Form() {
  const formRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    message: '',
  });
  const [submissionStatus, setSubmissionStatus] = useState({
    success: false,
    error: '',
  });

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (formRef.current) {
      observer.observe(formRef.current);
    }

    return () => {
      if (formRef.current) {
        observer.unobserve(formRef.current);
      }
    };
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/api/contact/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        setSubmissionStatus({ success: true, error: '' });
        // Optionally, reset the form
        setFormData({ name: '', email: '', number: '', message: '' });
      } else {
        const errorData = await response.json();
        setSubmissionStatus({ success: false, error: errorData.error || 'An error occurred.' });
      }
    } catch (error) {
      setSubmissionStatus({ success: false, error: 'An unexpected error occurred.' });
    }
  };

  return (
    <div className='form-container' id="contact">
      <div className="steps">
    <h2>How It Work ?</h2>
    <h3>The Amazing Steps Of Our Treatment</h3>
    <div className="step">
      <div className="step-number">1</div>
      <div className="step-description">
        <h4>Primary Medical Opinion</h4>
        <p> Get expert evaluations and accurate diagnoses from top healthcare professionals to guide your treatment decisions.</p>
      </div>
      </div>
    <div className="step">
      <div className="step-number">2</div>
      <div className="step-description">
        <h4>Second Opinion</h4>
        <p>Receive a thorough review and confirmation of your diagnosis and treatment plan from a second expert for added assurance.</p>
      </div>
    </div>
    <div className="step">
      <div className="step-number">3</div>
      <div className="step-description">
        <h4>Medical Tourism</h4>
        <p>Access world-class medical care abroad, tailored to your needs and budget, with full support and coordination services.</p>
      </div>
    </div>
      </div>
      <div
        ref={formRef}
        className={`form ${isVisible ? 'visible' : ''}`}
      >
        <h3>Fill The Form</h3>
        <h1>Get In Touch</h1>
        {submissionStatus.success && (
          <div className="thank-you-message">
            Thank you for contacting us! We will get back to you soon.
          </div>
        )}
        {submissionStatus.error && (
          <div className="error-message">
            {submissionStatus.error}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
          />
          <input
            type="text"
            name="number"
            placeholder="Your Number"
            value={formData.number}
            onChange={handleChange}
          />
          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
          <button type="submit">Send Message</button>
        </form>
      </div>
    </div>
  );
}

export default Form;
