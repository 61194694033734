import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BlogLoginFormComponent from '../Components/BlogLoginFormComponent';

function BlogLoginPage() {
  const { isAuthenticated, user } = useSelector(state => state.blog);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && user?.is_staff) {
      navigate('/blog/create');
    }
  }, [isAuthenticated, user, navigate]);

  return (
    <div className="container my-5">
      <BlogLoginFormComponent />
    </div>
  );
}

export default BlogLoginPage;
