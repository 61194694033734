import React from 'react';
import '../CSS/WhatsAppButton.css';

const WhatsAppButton = () => {
 
  const whatsappLink = `https://api.whatsapp.com/send?phone=917428557264&text=`;

  return (
    <a
      href={whatsappLink}
      className="whatsapp-button"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Chat with us on WhatsApp"
    >
      <i className="fab fa-whatsapp"></i>
      <span>Let's chat</span>
    </a>
  );
};

export default WhatsAppButton;
