import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { adminLogout } from "../slices/adminSlice";
import "../CSS/AdminDashboard.css";

const AdminNavbar = ({ sidebarOpen, setSidebarOpen }) => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(adminLogout());
    window.location.href = "/admin/login";
  };

  return (
    <div className={`appointment-dashboard-sidebar ${sidebarOpen ? "open" : ""}`}>
      {/* Hamburger for mobile screens */}
      <button
        className="admin-navbar-close"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
       <i class="fa-solid fa-x fa-2x"></i>
      </button>

      <div className="appointment-dashboard-logo">
        <div className="logo-box">
          <img
            src="/static/icons/logo.webp"
            alt="Medbridge-access-logo"
            className="Medbridge-access-logo"
          />
          <div className="logo">
            <h1>
              <span className="title-we">Med</span>
              <div className="title-2">
                <span>Bridge</span> <span>Access</span>
              </div>
            </h1>
          </div>
        </div>
      </div>

      <div className="appointment-dashboard-nav-items">
        <NavLink
          to="/admin/admin-dashboard"
          className="appointment-dashboard-nav-item"
          activeClassName="active"
        >
          <i className="fa-regular fa-calendar-days"></i>
          <span>Appointments</span>
        </NavLink>

        <NavLink
          to="/admin/patients"
          className="appointment-dashboard-nav-item"
          activeClassName="active"
        >
          <i className="fa-solid fa-bed"></i>
          <span>Patients</span>
        </NavLink>

        <NavLink
          to="/admin/doctors"
          className="appointment-dashboard-nav-item"
          activeClassName="active"
        >
          <i className="fa-solid fa-user-doctor"></i>
          <span>Doctors</span>
        </NavLink>

        <button onClick={handleLogout} className="appointment-dashboard-nav-item">
          <i className="fa-solid fa-arrow-right-from-bracket"></i>
          <span>Logout</span>
        </button>
      </div>
    </div>
  );
};

export default AdminNavbar;
