import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';
import 'react-quill/dist/quill.snow.css'; 
import { useNavigate } from 'react-router-dom';
import { blogLogout } from '../slices/blogSlice';
import '../ScopedBootstrap.scss'; // Import Scoped Bootstrap SCSS

function BlogPostForm({ onSubmit }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [coverImage, setCoverImage] = useState(null);
  const [keywords, setKeywords] = useState(['']);
  const [imagePreview, setImagePreview] = useState(null);

  const addKeywordField = () => {
    setKeywords([...keywords, '']);
  };

  const removeKeywordField = (index) => {
    setKeywords(keywords.filter((_, i) => i !== index));
  };

  const handleKeywordChange = (index, value) => {
    const newKeywords = [...keywords];
    newKeywords[index] = value;
    setKeywords(newKeywords);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    if (coverImage) {
      formData.append('cover_image', coverImage);
    }
    formData.append('keywords', JSON.stringify(keywords.filter(kw => kw.trim() !== '')));
    onSubmit(formData);
  };

  const handleLogout = () => {
    dispatch(blogLogout());
    navigate('/blog/login');
  };

  useEffect(() => {
    if (coverImage) {
      const preview = URL.createObjectURL(coverImage);
      setImagePreview(preview);
      return () => URL.revokeObjectURL(preview);
    } else {
      setImagePreview(null);
    }
  }, [coverImage]);

  return (
    <div className="bootstrap-scope position-relative" style={{ paddingTop: '50px', width: '100vw', minHeight: '100vh', background: '#f5f5f5' }}>
      {/* Logout Button at the top-right corner */}
      <button
        type="button"
        onClick={handleLogout}
        className="btn btn-link"
        style={{
          width:'100%',
          textAlign:'right',
          marginBottom:'20px',
          backgroundColor: 'transparent',
          border: 'none',
          color: '#a7185d',
          padding: '8px 30px',
          borderRadius: '4px',
          cursor: 'pointer',
          fontSize: '2rem',
        }}
      >
        <i className="fa-solid fa-arrow-right-from-bracket"></i>
      </button>

      <div className="container-fluid d-flex justify-content-center align-items-start">
        <form 
          onSubmit={handleFormSubmit} 
          className="p-4 blog-form" 
          style={{ 
            margin: '0 auto', 
            background: '#fff', 
            borderRadius: '8px', 
            boxShadow: '0 0 20px rgba(0,0,0,0.1)',
            textAlign: 'left'
          }}
        >
          <h2 className="mb-4 text-center">Create New Blog Post</h2>

          <div className="form-group mb-3">
            <label htmlFor="title" style={{ fontWeight: 'bold' }}>Title</label>
            <input 
              type="text" 
              className="form-control"
              id="title"
              value={title}
              onChange={e => setTitle(e.target.value)} 
              required 
              style={{
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '10px'
              }}
            />
          </div>

          <div className="form-group mb-3">
            <label style={{ fontWeight: 'bold' }}>Content</label>
            <ReactQuill 
              value={content} 
              onChange={setContent} 
              style={{ height: '300px', marginBottom: '50px' }}
            />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="coverImage" style={{ fontWeight: 'bold' }}>Cover Image</label>
            <input 
              type="file" 
              className="form-control" 
              id="coverImage"
              onChange={e => setCoverImage(e.target.files[0])} 
              style={{
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '10px'
              }}
            />
            {imagePreview && (
              <div className="mt-3 text-center">
                <img src={imagePreview} alt="Preview" style={{ maxWidth: '100%', height: 'auto', maxHeight:'400px',objectFit:'cover', borderRadius: '8px' }} />
              </div>
            )}
          </div>

          <div className="form-group mb-3">
            <label style={{ fontWeight: 'bold' }}>Keywords</label>
            {keywords.map((keyword, index) => (
              <div className="input-group mb-2" key={index}>
                <input 
                  type="text"
                  className="form-control"
                  placeholder={`Keyword ${index+1}`}
                  value={keyword}
                  onChange={(e) => handleKeywordChange(index, e.target.value)}
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    padding: '10px'
                  }}
                />
                <button 
                  type="button"
                  className="btn btn-danger"
                  onClick={() => removeKeywordField(index)}
                  disabled={keywords.length === 1}
                >
                  &times;
                </button>
              </div>
            ))}
            <button 
              type="button" 
              className="btn btn-danger mt-2"
              onClick={addKeywordField}
            >
              Add Another Keyword
            </button>
          </div>

          <button 
            type="submit" 
            className="btn btn-danger w-100" 
            style={{ padding: '12px' }}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default BlogPostForm;
