import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { blogCreatePost } from '../slices/blogSlice';
import { useNavigate } from 'react-router-dom';
import BlogPostForm from '../Components/BlogPostForm';
function BlogCreatePostPage() {
  const { user, isAuthenticated, createPostError, createPostLoading } = useSelector(state => state.blog);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // If not logged in or not staff, redirect to login
    if (!isAuthenticated || !user?.is_staff) {
      navigate('/login');
    }
  }, [isAuthenticated, user, navigate]);

  const handleSubmit = async (formData) => {
    if (!isAuthenticated || !user.is_staff) {
      return; // Just in case
    }
    try {
      await dispatch(blogCreatePost(formData)).unwrap();
      alert('Blog post created successfully!');
    } catch (err) {
      console.error(err);
      alert('Error creating blog post');
    }
  };

  return (
    <div className="container my-5">
      {createPostError && <div className="alert alert-danger">{createPostError}</div>}
      <BlogPostForm onSubmit={handleSubmit} />
      {createPostLoading && <div>Creating post...</div>}
    </div>
  );
}

export default BlogCreatePostPage;
