// src/components/PatientProfile.js

import React, { useState, useEffect, useRef } from "react";
import {
  FaHeart,
  FaHeartbeat,
  FaEllipsisV,
  FaPlus,
  FaFileAlt,
} from "react-icons/fa";
import AddDiagnosisModal from "./AddDiagnosisModal";
import Modal from "react-modal"; // Import React Modal
import "../CSS/PatientProfile.css";

const calculateAge = (dob) => {
  if (!dob) return "N/A";
  const birthDate = new Date(dob);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  return date.toLocaleDateString();
};

const PatientProfile = ({ selectedUser, onStartChat, appointment }) => {
  // Extract data from selectedUser
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  // Modal state variables
  const [isHealthMetricsModalOpen, setHealthMetricsModalOpen] = useState(false);
  const [isMedicalHistoryModalOpen, setMedicalHistoryModalOpen] =
    useState(false);
  const [isMedicalReportsModalOpen, setMedicalReportsModalOpen] =
    useState(false);
  const [isDiagnosesModalOpen, setDiagnosesModalOpen] = useState(false);

  // State for Add Diagnosis Modal
  const [isAddDiagnosisModalOpen, setIsAddDiagnosisModalOpen] = useState(false);

  // Handle modal open/close
  const openModal = (modalSetter) => () => modalSetter(true);
  const closeModal = (modalSetter) => () => modalSetter(false);

  const name =
    `${selectedUser?.user?.first_name || ""} ${
      selectedUser?.user?.last_name || ""
    }`.trim() || "Unknown";
  const gender = selectedUser?.gender || "Unknown";
  const dob = selectedUser?.dob || "Unknown";
  const medical_histories = selectedUser?.medical_histories || [];
  const medical_reports = selectedUser?.medical_reports || [];
  const vitals = selectedUser?.vitals || [];
  const diagnoses = selectedUser?.diagnoses || [];

  // Get the latest vitals
  const latestVitals = vitals.length > 0 ? vitals[vitals.length - 1] : {};
  const bloodPressure = latestVitals.blood_pressure || "N/A";
  const pulse = latestVitals.pulse_rate || "N/A";
  const oxygen= latestVitals.oxygen_level||"N/A";
  const height = latestVitals.height ? `${latestVitals.height} cm` : "N/A";
  const weight = latestVitals.weight ? `${latestVitals.weight} kg` : "N/A";
  const colors = [
    "ufm-report-icon-blue",
    "ufm-report-icon-green",
    "ufm-report-icon-orange",
  ];

  // Assume 'problem' is the latest medical history description
  const problem =
    medical_histories.length > 0
      ? medical_histories[medical_histories.length - 1].description
      : "N/A";

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    if (menuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);

  // Menu actions
  const handleViewDetails = () => {
    alert("View Details clicked");
    setMenuOpen(false);
  };

  const handleEditProfile = () => {
    alert("Edit Profile clicked");
    setMenuOpen(false);
  };

  const handleDeleteProfile = () => {
    alert("Delete Profile clicked");
    setMenuOpen(false);
  };

  return (
    <div className="patient-profile-container">
      {/* Profile Header */}
      <div className="profile-header">
        <div className="profile-info">
          <div className="profile-initial">{name.charAt(0).toUpperCase()}</div>
          <div className="patient-info-details">
            <h2>{name}</h2>
            <p>{gender}</p>
          </div>
        </div>
      </div>

      {/* Add Diagnosis Button */}
      <button
        className="action-icon"
        title="Add Diagnosis"
        style={{ cursor: "pointer"}}
        onClick={() => setIsAddDiagnosisModalOpen(true)}
      >
        Add Diagnosis & Prescription
      </button>

      {/* Demographics */}
      <div className="demographics"> 
  <div className="demo-row"> 
    <div className="demo-item"> 
      <div className="patient-report-icon"> 
        <img src="/static/icons/birthday.png" alt="Birthday icon" className="icon-image" /> 
      </div> 
      <span className="label">DOB</span> 
      <span className="value">{formatDate(dob)}</span> 
    </div> 
    <div className="demo-item"> 
      <div className="patient-report-icon"> 
        <img src="/static/icons/age-range.png" alt="Age icon" className="icon-image" /> 
      </div> 
      <span className="label">Age</span> 
      <span className="value">{calculateAge(dob)}</span> 
    </div> 
  </div> 
  <div className="demo-row"> 
    <div className="demo-item"> 
      <div className="patient-report-icon"> 
        <img src="/static/icons/weight.png" alt="Weight icon" className="icon-image" /> 
      </div> 
      <span className="label">Weight</span> 
      <span className="value">{weight}</span> 
    </div> 
    <div className="demo-item"> 
      <div className="patient-report-icon"> 
        <img src="/static/icons/height.png" alt="Height icon" className="icon-image" /> 
      </div> 
      <span className="label">Height</span> 
      <span className="value">{height}</span> 
    </div> 
  </div> 
</div>
      {/* Health Metrics */}
      <div className="patient-health-metrics">
        <div className="patient-health-metrics-header">
          <h2>Your Vitals</h2>
          {/* <button
            className="patient-see-all-button"
            onClick={openModal(setHealthMetricsModalOpen)}
          >
            See All
          </button> */}
        </div>

        <div className="patient-health-metrics-grid">
          <div className="patient-health-metrics-card">
            <div className="patient-health-metrics-info">
              <span className="patient-health-metrics-title">
                Blood Pressure
              </span>
              <span className="patient-health-metrics-value">
                {bloodPressure}
              </span>
            </div>
            <div className="patient-health-metrics-icon">
              <FaHeart />
            </div>
          </div>

          <div className="patient-health-metrics-card">
            <div className="patient-health-metrics-info">
              <span className="patient-health-metrics-title">Pulse</span>
              <span className="patient-health-metrics-value">{pulse} BPM</span>
            </div>
            <div className="patient-health-metrics-icon">
              <FaHeartbeat />
            </div>
          </div>

          <div className="patient-health-metrics-card">
            <div className="patient-health-metrics-info">
              <span className="patient-health-metrics-title">
                Oxygen Level
              </span>
              <span className="patient-health-metrics-value">
                {oxygen}
              </span>
            </div>
            <div className="patient-health-metrics-icon">
              <FaHeart />
            </div>
          </div>
        </div>
      </div>

      {/* Current Problem */}
      <div className="patient-notes">
        <div className="patient-notes-header">
          <h3>Current Problem</h3>
          <div className="patient-notes-actions">
            <FaEllipsisV
              className="patient-action-icon"
              onClick={toggleMenu}
              title="More Options"
              style={{ cursor: "pointer" }}
            />
            {menuOpen && (
              <div className="patient-menu-dropdown" ref={menuRef}>
                <button onClick={handleViewDetails}>View Details</button>
                <button onClick={handleEditProfile}>Edit Profile</button>
                <button onClick={handleDeleteProfile}>Delete Profile</button>
              </div>
            )}
          </div>
        </div>
        <div className="patient-notes-content">
          <p className="patient-notes-text">{problem}</p>
        </div>
      </div>

      {/* Medical History */}
      <div className="patient-medical-history">
        <div className="patient-history-header">
          <h2>Medical History</h2>
          {/* <button
            className="patient-see-all-button"
            onClick={openModal(setMedicalHistoryModalOpen)}
          >
            See All
          </button> */}
        </div>
        <div className="patient-history-content">
          {medical_histories.length > 0 ? (
            medical_histories.map((item, index) => (
              <div className="patient-history-item" key={index}>
                <p className="patient-history-date">
                  {formatDate(item.created_at)}
                </p>
                <h4 className="patient-history-title">{item.category}</h4>
                <p className="patient-history-description">
                  {item.description}
                </p>
              </div>
            ))
          ) : (
            <h4 className="patient-not-found-message">
              No medical history found.
            </h4>
          )}
        </div>
      </div>

      {/* Medical Reports */}
      <div className="patient-medical-reports">
        <div className="patient-reports-header">
          <h2>Medical Reports</h2>
          {/* <button
            className="patient-see-all-button"
            onClick={openModal(setMedicalReportsModalOpen)}
          >
            See All
          </button> */}
        </div>
        <div className="patient-reports-content">
          {medical_reports.length > 0 ? (
            medical_reports.map((report, index) => (
              <a
                key={index}
                href={report.file || "#"}
                target="_blank"
                rel="noopener noreferrer"
                className="patient-report-item"
              >
                <div className="patient-report-details">
                  <div className="patient-report-icon">
                    <FaFileAlt />
                  </div>
                  <p className="patient-report-name">
                    {report.file ? report.file.split("/").pop() : "Unknown"}
                  </p>
                  
                </div>
              </a>
            ))
          ) : (
            <h4 className="patient-not-found-message">
              No medical reports found.
            </h4>
          )}
        </div>
      </div>

      {/* Diagnoses Section
      <div className="patient-diagnoses-section">
        <div className="patient-diagnoses-header">
          <h2>Diagnoses</h2>
          <FaPlus
            className="patient-add-diagnosis-icon"
            title="Add Diagnosis"
            style={{ cursor: "pointer" }}
            onClick={openModal(setDiagnosesModalOpen)}
          />
        </div>
        <div className="patient-diagnoses-content">
          {diagnoses && diagnoses.length > 0 ? (
            diagnoses.map((diagnosis) => (
              <div key={diagnosis.id} className="patient-diagnosis-item">
                <p>
                  <strong>Description:</strong>{" "}
                  {diagnosis.diagnosis_description}
                </p>
                <p>
                  <strong>Appointment Time:</strong>{" "}
                  {new Date(diagnosis.appointment_time).toLocaleString()}
                </p>
                {diagnosis.prescription && (
                  <a
                    href={diagnosis.prescription}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Prescription
                  </a>
                )}
              </div>
            ))
          ) : (
            <p>No diagnoses available.</p>
          )}
        </div>
      </div> */}

      {/* Modals using React Modal */}
      {/* Health Metrics Modal */}
     

      {/* Add Diagnosis Modal */}
      <AddDiagnosisModal
        isOpen={isAddDiagnosisModalOpen}
        onRequestClose={() => setIsAddDiagnosisModalOpen(false)}
        patientId={selectedUser.id}
      />

      {/* Start Chat Button */}
      {/* {onStartChat && (
        <button className="start-chat-button" onClick={onStartChat}>
          Start Chat
        </button>
      )} */}
    </div>
  );
};

export default PatientProfile;
