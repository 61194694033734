import React from "react";
import "../CSS/PatientHeader.css";

const PatientHeader = () => {
  const currentDate = new Date().toLocaleDateString("en-US", {
    weekday: "long",
    month: "short",
    day: "numeric",
  });

  return (
    <div className="patient-header">
      <div className="appointments">
        <span className="dot"></span>
        <span className="line"></span>
        <span className="appointments-icon">
          <i className="fa-solid fa-calendar"></i>
        </span>
        <span className="line"></span>
        <span className="dot"></span>
        <span className="appointments-text">2 appointments scheduled</span>
      </div>
      <div className="search-bar">
        <i className="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          placeholder="Search medical records, doctors, or widgets..."
        />
      </div>
      <div className="current-date">
        <span>{currentDate}</span>
        <i className="fa-solid fa-bell fa-2x"></i>
      </div>
    </div>
  );
};

export default PatientHeader;
