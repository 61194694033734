import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Load token and user from localStorage if available
const storedAccessToken = localStorage.getItem('accessToken');
const storedUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

export const blogLogin = createAsyncThunk('blog/login', async ({username, password}, thunkAPI) => {
  try {
    const response = await axios.post('/api/blogs/login/', { username, password });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response ? error.response.data : 'Login failed');
  }
});

export const blogCreatePost = createAsyncThunk(
  'blog/createPost',
  async (formData, { getState, rejectWithValue }) => {
    const state = getState();
    const token = state.blog.accessToken; // Get token from the slice state
    if (!token) {
      return rejectWithValue('No access token available');
    }

    try {
      const response = await axios.post('/api/blogs/create/', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,

          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : 'Error creating blog post');
    }
  }
);

const blogSlice = createSlice({
  name: 'blog',
  initialState: {
    isAuthenticated: !!storedAccessToken,
    user: storedUser,
    accessToken: storedAccessToken,
    refreshToken: null,
    error: null,
    loading: false,
    createPostLoading: false,
    createPostError: null,
  },
  reducers: {
    blogLogout(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.error = null;
      state.createPostError = null;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
    },
  },
  extraReducers: builder => {
    builder
      // login cases
      .addCase(blogLogin.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(blogLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.user = action.payload.user;
        state.accessToken = action.payload.access;
        state.refreshToken = action.payload.refresh;
        // Store to localStorage
        localStorage.setItem('accessToken', action.payload.access);
        localStorage.setItem('user', JSON.stringify(action.payload.user));
      })
      .addCase(blogLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Login error';
      })

      // create post cases
      .addCase(blogCreatePost.pending, state => {
        state.createPostLoading = true;
        state.createPostError = null;
      })
      .addCase(blogCreatePost.fulfilled, (state, action) => {
        state.createPostLoading = false;
        // Post created successfully, no changes to state needed unless you want to store the post
      })
      .addCase(blogCreatePost.rejected, (state, action) => {
        state.createPostLoading = false;
        state.createPostError = action.payload || 'Error creating blog post';
      });
  }
});

export const { blogLogout } = blogSlice.actions;
export default blogSlice.reducer;
