// LoginModal.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  loginUser,
  requestPasswordReset,
  verifyResetOtp,
  resetPassword,
  // If you create resetUserState, import it here
} from '../slices/userSlice';
import '../CSS/LoginModal.css'; // Keep this if you need other styles from the external CSS file

function LoginModal({ isOpen, onClose }) {
  const [step, setStep] = useState(1); // 1: Login, 2: Enter Email, 3: Enter OTP, 4: Enter New Password

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetToken, setResetToken] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);

  // Reset state when modal opens (Optional but recommended)
  useEffect(() => {
    if (isOpen) {
      // If you create a resetUserState action, dispatch it here
      // dispatch(resetUserState());
      setStep(1);
      setEmail('');
      setPassword('');
      setResetToken('');
      setNewPassword('');
    }
  }, [isOpen, dispatch]);

  // Transition from step 2 to 3
  useEffect(() => {
    console.log('requestPasswordResetStatus:', userState.requestPasswordResetStatus, 'current step:', step);
    if (userState.requestPasswordResetStatus === 'succeeded' && step === 2) {
      setStep(3);
    }
  }, [userState.requestPasswordResetStatus, step]);

  // Transition from step 3 to 4
  useEffect(() => {
    if (userState.verifyResetOtpStatus === 'succeeded' && step === 3) {
      setStep(4);
    }
  }, [userState.verifyResetOtpStatus, step]);

  // Handle successful password reset
  useEffect(() => {
    if (userState.resetPasswordStatus === 'succeeded' && step === 4) {
      // Password reset successful, reset to login step
      setStep(1);
      setEmail('');
      setPassword('');
      setResetToken('');
      setNewPassword('');
      // Optionally, show a success message or auto-login the user
    }
  }, [userState.resetPasswordStatus, step]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (step === 1) {
      dispatch(loginUser({ email, password }))
        .unwrap()
        .then(() => {
          onClose(); // Close the modal on successful login
        })
        .catch((error) => {
          console.error('Login failed:', error);
        });
    } else if (step === 2) {
      dispatch(requestPasswordReset(email));
    } else if (step === 3) {
      dispatch(verifyResetOtp({ email, reset_token: resetToken }));
    } else if (step === 4) {
      dispatch(resetPassword({ email, reset_token: resetToken, new_password: newPassword }));
    }
  };

  if (!isOpen) {
    return null;
  }

  // Inline styles for modal-right section
  const modalRightStyle = { 
    width: '50%',
    backgroundImage: `url('/static/banner/login.webp')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    boxSizing: 'border-box',
    // ... existing styles ...
  };

  // Render error messages
  const renderErrorMessages = (error) => {
    if (!error) return null;

    if (typeof error === 'string') {
      return <p className="error-message">{error}</p>;
    } else if (typeof error === 'object') {
      return (
        <ul className="error-message">
          {Object.entries(error).map(([key, value]) => (
            <li key={key}>
              {key}: {Array.isArray(value) ? value.join(', ') : value.toString()}
            </li>
          ))}
        </ul>
      );
    }
    return null;
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content-login">
        <div className="modal-left">
          <div className="logo-login">
            <div className='logo-box'>
              <img src='/static/icons/logo.webp' alt='Medbridge-access-logo' className="Medbridge-access-logo" />
              <div className="logo">
                <h1>
                  <span className="title-we">Med</span>
                  <div className="title-2">
                    <span>Bridge</span> <span>Access</span>
                  </div>
                </h1>
              </div>
            </div>
          </div>
          <div className="login-form-modal">
            {step === 1 && (
              <>
                <h2>Welcome Back</h2>
                <p className="subheading">We’re glad to see you again!</p>
                {userState.loginUserError && renderErrorMessages(userState.loginUserError)}
                <form onSubmit={handleSubmit}>
                  <input
                    type="email"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <input
                    type="password"
                    placeholder="Your Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <a
                    href="#"
                    className="forgot-password"
                    onClick={(e) => {
                      e.preventDefault();
                      setStep(2);
                    }}
                  >
                    Forgot Password?
                  </a>
                  <button type="submit" disabled={userState.loginUserStatus === 'loading'}>
                    {userState.loginUserStatus === 'loading' ? 'Logging in...' : 'Log In'}
                  </button>
                </form>
              </>
            )}

            {step === 2 && (
              <>
                <h2>Reset Password</h2>
                <p className="subheading">Enter your registered email to receive a reset code.</p>
                {userState.requestPasswordResetError && renderErrorMessages(userState.requestPasswordResetError)}
                <form onSubmit={handleSubmit}>
                  <input
                    type="email"
                    placeholder="Your Registered Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <button
                    type="submit"
                    disabled={userState.requestPasswordResetStatus === 'loading'}
                  >
                    {userState.requestPasswordResetStatus === 'loading' ? 'Sending Code...' : 'Send Reset Code'}
                  </button>
                </form>
                <button
                  type="button"
                  className="back-button"
                  onClick={() => setStep(1)}
                >
                  Back to Login
                </button>
              </>
            )}

            {step === 3 && (
              <>
                <h2>Verify Reset Code</h2>
                <p className="subheading">Enter the OTP sent to your email.</p>
                {userState.verifyResetOtpError && renderErrorMessages(userState.verifyResetOtpError)}
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    placeholder="Reset Code"
                    value={resetToken}
                    onChange={(e) => setResetToken(e.target.value)}
                    required
                  />
                  <button
                    type="submit"
                    disabled={userState.verifyResetOtpStatus === 'loading'}
                  >
                    {userState.verifyResetOtpStatus === 'loading' ? 'Verifying...' : 'Verify Code'}
                  </button>
                </form>
                <button
                  type="button"
                  className="back-button"
                  onClick={() => setStep(2)}
                >
                  Back
                </button>
              </>
            )}

            {step === 4 && (
              <>
                <h2>Set New Password</h2>
                <p className="subheading">Enter your new password.</p>
                {userState.resetPasswordError && renderErrorMessages(userState.resetPasswordError)}
                <form onSubmit={handleSubmit}>
                  <input
                    type="password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                  <button
                    type="submit"
                    disabled={userState.resetPasswordStatus === 'loading'}
                  >
                    {userState.resetPasswordStatus === 'loading' ? 'Updating...' : 'Reset Password'}
                  </button>
                </form>
              </>
            )}
          </div>
        </div>
        <div className="modal-right" style={modalRightStyle}>
          <div className="quote-container">
            <p className="quote-text">
              "The best way to find yourself is to lose yourself in the service of others."
            </p>
            <p className="quote-author">- Mahatma Gandhi</p>
          </div>
        </div>
        <button className="close-button-login" onClick={onClose}>
          &times;
        </button>
      </div>
    </div>
  );
}

export default LoginModal;
